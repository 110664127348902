import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setToastText: ['toastText']
});

export const ToastTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type ToastState = {
  toastText: string;
};

const INITIAL_STATE: ToastState = {
  toastText: ''
};

// selector
export const valueSelector: (state: any) => ToastState = (state: any) =>
  state.toast;

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TOAST_TEXT]: (state, { toastText }) => ({
    ...state,
    toastText
  })
});
