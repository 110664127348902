import { EnoticeError, ErrorType } from './index';

export class PlacementError extends EnoticeError {
  errorType = `${ErrorType.PlacementError.type}01`;

  errorCode = 601;

  name = `${ErrorType.PlacementError.name}`;

  constructor() {
    super(
      'Placement Error: Error occured while placing the notice. Please try refreshing, and contact help@column.us if the problem persists.'
    );
    this.setErrorMessage();
  }
}
