export default {
  admin: {
    value: 1,
    canInvite: true,
    label: 'Admin',
    key: 'admin'
  },
  billing: {
    value: 2,
    canInvite: true,
    label: 'Billing',
    key: 'billing'
  },
  user: {
    value: 3,
    canInvite: true,
    label: 'User',
    key: 'user'
  },
  super: {
    value: 4,
    canInvite: false,
    label: 'super',
    key: 'super'
  }
};
