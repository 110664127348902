import { BROWSER, BROWSERS } from '../constants';

export const getLocationParams = () =>
  new URLSearchParams(window.location.search);

export const getHostname = () =>
  window.location.hostname.split('.').slice(-2)[0];

export const getRedirect = () =>
  getLocationParams().get('redirect') || '/?noredirect';

export const includeSearchParams = (url: string) =>
  `${url}${window.location.search}`;

const locationParamsToTuples = (searchParams: any) => {
  const result = [];
  for (const entry of searchParams) {
    const [key, value] = entry;
    result.push([key, value]);
  }
  return result;
};

export const appendToCurrentParams = (key: string, value: string) => {
  const params = new URLSearchParams(
    Object.fromEntries([
      ...locationParamsToTuples(getLocationParams()),
      [key, value]
    ])
  );

  return params;
};

export const updateHistoryNoRerender = (path: string) => {
  window.history.replaceState(null, document.title, path);
  let event;
  if (BROWSER === BROWSERS.ie) {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent('replacestate', true, true, {
      detail: 'replacestate'
    });
  } else {
    event = new CustomEvent('replacestate', { detail: 'replacestate' });
  }
  dispatchEvent(event);
};

export const getSubdomain = () => window.location.host.split('.')[0];

export const getSubdomainOnly = () => {
  const domains = window.location.host.split('.');
  if (domains.includes('web') || domains.includes('firebaseapp')) return null;
  if (domains.length > 2) return domains[0];
  return null;
};
