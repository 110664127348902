import Enum from './enums';
import {
  BillingStatusEnum,
  NoticeStatusEnum,
  RoleTypeEnum,
  NotificationTypeEnum,
  OccupationTypeEnum,
  OrganizationTypeEnum,
  StateEnum,
  TimezoneEnum,
  RateEnum,
  NoticeTypeEnum,
  ExportFormatTypeEnum,
  DayTypeEnum,
  AdTypeEnum,
  BankAccountsVerificationEnum,
  InvoiceStatusEnum,
  ELabelEnum,
  AnnouncementTypeEnum,
  SubdomainStatusEnum,
  MailStatusEnum,
  FrequencyEnum,
  TransactionTypeEnum,
  CurrencyTypeEnum,
  CountryEnum,
  DwollaPayoutStatusEnum,
  DwollaCustomerStatusEnum
} from './enum_extensions';

import Occupation from './OccupationType';
import BillingStatus from './BillingStatusType';
import Timezones from './Timezones';
import DayEnum from './Day';
import FrequencyData from './Frequency';
import GovernmentTypeData from './GovernmentType';
import LawTypeData from './LawType';
import MailStatusData from './MailStatus';
import NoticeStatusData from './NoticeStatusType';
import NoticeTypeData from './NoticeType';
import NotificationTypeData from './NotificationType';
import OrganizationTypeData from './OrganizationType';
import RateTypeData from './RateType';
import RoleTypeData from './RoleType';
import StateData from './State';
import CountryData from './Country';
import CourtHouseData from './CourtHouse';
import XMLReplacementTagTypeData from './XMLReplacementTagType';
import ExportFormatTypeDate from './ExportFormatType';
import CustomAffidavitData from './CustomAffidavit';
import AdTypeData from './AdTypes';
import BankAccountVerificationData from './BankAccountVerification';
import InvoiceStatusData from './InvoiceStatusType';
import ELabelsData from './Labels';
import AnnouncementTypeData from './AnnouncementType';
import SubdomainStatusTypes from './SubdomainStatus';
import TransactionTypeData from './TransactionType';
import Currency from './Currency';
import DwollaTransferStatusData from './DwollaTransferStatus';
import DwollaCustomerStatusData from './DwollaCustomerStatus';

export const BillingStatusType = new Enum(BillingStatus) as BillingStatusEnum;
export const TimezoneType = new Enum(Timezones) as TimezoneEnum;
export const AnnouncementType = new Enum(
  AnnouncementTypeData
) as AnnouncementTypeEnum;
export const Day = (new Enum(DayEnum) as any) as DayTypeEnum;
export const Frequency = new Enum(FrequencyData) as FrequencyEnum;
export const GovernmentType = new Enum(GovernmentTypeData);
export const LawType = new Enum(LawTypeData);
export const MailStatus = new Enum(MailStatusData) as MailStatusEnum;
export const NoticeStatusType = new Enum(NoticeStatusData) as NoticeStatusEnum;
export const NoticeType = new Enum(NoticeTypeData) as NoticeTypeEnum;
export const NotificationType = new Enum(
  NotificationTypeData
) as NotificationTypeEnum;
export const OccupationType = new Enum(Occupation) as OccupationTypeEnum;
export const OrganizationType = new Enum(
  OrganizationTypeData
) as OrganizationTypeEnum;
export const RateType = new Enum(RateTypeData) as RateEnum;
export const RoleType = new Enum(RoleTypeData) as RoleTypeEnum;
export const State = new Enum(StateData) as StateEnum;
export const CourtHouse = CourtHouseData;
export const XMLReplacementTagType = new Enum(XMLReplacementTagTypeData);
export const ExportFormatType = new Enum(
  ExportFormatTypeDate
) as ExportFormatTypeEnum;
export const CustomAffidavit = CustomAffidavitData;
export const AdType = new Enum(AdTypeData) as AdTypeEnum;
export const BankAccountVerification = new Enum(
  BankAccountVerificationData
) as BankAccountsVerificationEnum;

export const InvoiceStatus = new Enum(InvoiceStatusData) as InvoiceStatusEnum;
export const TransactionType = new Enum(
  TransactionTypeData
) as TransactionTypeEnum;
export const ELabels = new Enum(ELabelsData) as ELabelEnum;
export const SubdomainStatus = new Enum(
  SubdomainStatusTypes
) as SubdomainStatusEnum;

export const CurrencyType = new Enum(Currency) as CurrencyTypeEnum;

export const Country = new Enum(CountryData) as CountryEnum;

export const DwollaTransferStatus = new Enum(
  DwollaTransferStatusData
) as DwollaPayoutStatusEnum;

export const DwollaCustomerStatus = new Enum(
  DwollaCustomerStatusData
) as DwollaCustomerStatusEnum;
