import { fork } from 'redux-saga/effects';
import NoticeSaga from './NoticeSaga';
import ErrorSaga from './ErrorSaga';
import AuthSaga from './AuthSaga';
import SetupSaga from './SetupSaga';
import RoutingSaga from './RoutingSaga';
import PlacementSaga from './PlacementSaga';
import JoyRideSaga from './JoyrideSaga';
import ToastSaga from './ToastSaga';

export default function* root() {
  yield fork(SetupSaga);
  yield fork(NoticeSaga);
  yield fork(ErrorSaga);
  yield fork(AuthSaga);
  yield fork(RoutingSaga);
  yield fork(PlacementSaga);
  yield fork(JoyRideSaga);
  yield fork(ToastSaga);
}
