import { createReducer, createActions } from 'reduxsauce';
import { ESnapshotExists, ENoticeDraft, ENotice, ESnapshot } from 'lib/types';

export const SHOW_CURRENT_USERS_NOTICES = 'my-notices';
export const SHOW_ALL_NOTICES = 'all-notices';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setActiveNotices: ['activeNotices'],
  setArchivedNotices: ['archivedNotices'],
  setDrafts: ['drafts'],
  setActiveNotice: ['activeNotice'],
  setPage: ['noticeTablePage'],
  setNumRows: ['rowsPerNoticeTablePage'],
  setTab: ['tab'],
  updateNotices: [],
  regenerateAdProof: ['noticeDraft'],
  synchronizeActiveNotice: ['noticeData'],
  reset: [],
  setSearch: ['search'],
  setFilter: ['filter'],
  setFetching: ['fetching']
});

export const NoticeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type NoticeSearchStrings = {
  active: string;
  archived: string;
  draft: string;
};
export type NoticesState = {
  activeNotices: ESnapshotExists<ENotice>[];
  archivedNotices: ESnapshotExists<ENotice>[];
  drafts: ESnapshotExists<ENoticeDraft>[];
  activeNotice: ENotice;
  tab: number;
  noticeDraft: ESnapshot<ENoticeDraft>;
  rowsPerNoticeTablePage: number;
  noticeTablePage: number;
  search: NoticeSearchStrings;
  filter: string;
  fetching: boolean;
};
const INITIAL_STATE: NoticesState = {
  rowsPerNoticeTablePage: 5,
  noticeTablePage: 0,
  activeNotices: null,
  archivedNotices: null,
  drafts: null,
  activeNotice: null,
  tab: 0,
  noticeDraft: null,
  search: { active: '', archived: '', draft: '' },
  filter: SHOW_ALL_NOTICES,
  fetching: true
};

export const noticesSelector = (state: any) => state.notices as NoticesState;

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_NOTICES]: (
    state: NoticesState,
    { activeNotices }
  ): NoticesState => ({
    ...state,
    activeNotices
  }),

  [Types.SET_PAGE]: (
    state: NoticesState,
    { noticeTablePage }
  ): NoticesState => ({
    ...state,
    noticeTablePage
  }),

  [Types.SET_NUM_ROWS]: (
    state: NoticesState,
    { rowsPerNoticeTablePage }
  ): NoticesState => ({
    ...state,
    rowsPerNoticeTablePage
  }),

  [Types.SET_ARCHIVED_NOTICES]: (
    state: NoticesState,
    { archivedNotices }
  ): NoticesState => ({
    ...state,
    archivedNotices
  }),

  [Types.SET_DRAFTS]: (state: NoticesState, { drafts }): NoticesState => ({
    ...state,
    drafts
  }),

  [Types.SET_TAB]: (state: NoticesState, { tab }): NoticesState => ({
    ...state,
    tab
  }),

  [Types.SET_SEARCH]: (state: NoticesState, { search }): NoticesState => ({
    ...state,
    search
  }),

  [Types.SET_FILTER]: (state: NoticesState, { filter }): NoticesState => ({
    ...state,
    filter
  }),

  [Types.SET_FETCHING]: (state: NoticesState, { fetching }): NoticesState => ({
    ...state,
    fetching
  }),

  [Types.SET_ACTIVE_NOTICE]: (
    state: NoticesState,
    { activeNotice }
  ): NoticesState => ({
    ...state,
    activeNotice
  }),

  [Types.REGENERATE_AD_PROOF]: (
    state: NoticesState,
    { noticeDraft }
  ): NoticesState => ({
    ...state,
    noticeDraft
  }),

  [Types.RESET]: (state: NoticesState): NoticesState => ({
    ...state,
    activeNotices: null,
    archivedNotices: null,
    drafts: null,
    tab: 0,
    activeNotice: null
  })
});
