import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Typography, Theme } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import ErrorActions from '../redux/errors';
import { ENV, PROD, DEMO } from '../constants';

const mapDispatchToProps = (dispatch: any) => ({
  errorActions: bindActionCreators(ErrorActions, dispatch)
});

const styles = (theme: Theme) =>
  createStyles({
    loadingContainer: {
      width: '100%',
      height: '80vh'
    },
    message: {
      marginTop: theme.spacing(3)
    }
  });

type LoadingStateProps = {
  classes: Record<string, string>;
  message?: string;
  timeout?: number;
  errorActions: any;
};

const LoadingState: React.FC<LoadingStateProps> = ({
  classes,
  message,
  timeout = 30,
  errorActions
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const ERROR_TEXT = 'Operation timed out';

      errorActions.setError(ERROR_TEXT);

      console.error(ERROR_TEXT);

      if (ENV === DEMO || ENV === PROD) {
        Sentry.captureException(ERROR_TEXT);
      }
    }, timeout * 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      xs={12}
      className={classes.loadingContainer}
      item
    >
      <div
        className="flex justify-center align-center mt-8"
        style={{ width: '100%' }}
      >
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" />
      </div>
      {message && (
        <Typography className={classes.message}>{message}</Typography>
      )}
    </Grid>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(LoadingState));
