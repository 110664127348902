import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Button, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Chrome from '../images/chrome-icon.svg';
import { BROWSER, BROWSERS } from '../constants';

const mapStateToProps = (state: any) => ({
  location: state.router.location
});

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper
    },
    confirmButton: {
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: `${(theme.palette.primary as any)[600]} !important;`
      },
      maxWidth: '40%'
    },
    notInterested: {
      justifyContent: 'center',
      backgroundColor: theme.palette.grey[300],
      color: 'white',
      '&:hover': {
        backgroundColor: `${(theme.palette.grey as any)[400]} !important;`
      },
      maxWidth: '40%',
      marginRight: theme.spacing(1)
    },
    headingText: {
      color: theme.palette.grey[700],
      textTransform: 'uppercase'
    },
    heading: {
      fontWeight: 600
    },
    enoticeIcon: {
      maxWidth: 48,
      maxHeight: 48
    }
  });

const SESSION_VAR = 'browserSwitchPopperSeen';
const shouldDisplay = () =>
  (BROWSER === BROWSERS.ie || BROWSER === BROWSERS.edge) &&
  !window.sessionStorage.getItem(SESSION_VAR);

type PositionedPopperProps = {
  classes: any;
  theme: Theme;
  hideOnTheseRoutes: string[];
  location: Location;
};
const PositionedPopper = ({
  classes,
  theme,
  hideOnTheseRoutes,
  location
}: PositionedPopperProps) => {
  const [open, setOpen] = React.useState(shouldDisplay());

  useEffect(() => {
    for (let i = 0; i < hideOnTheseRoutes.length; i++) {
      if (location.pathname.includes(hideOnTheseRoutes[i]))
        return setOpen(false);
    }
    return setOpen(true);
  }, [location]);

  if (!open || !shouldDisplay()) return null;

  return (
    <Paper
      style={{
        padding: theme.spacing(2),
        position: 'fixed',
        top: theme.spacing(10),
        right: theme.spacing(2),
        zIndex: 20
      }}
    >
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/enotice-demo-8d99a.appspot.com/o/column-landing%2FColumn_Logo_Final-09.svg?alt=media&token=0b92098f-9471-4135-809e-64869779a6e5"
            height="48"
            className={classes.enoticeIcon}
          />
        </Grid>
        <Grid item style={{ textAlign: 'center' }}>
          <Typography variant="subtitle2" className={classes.heading}>
            Consider Switching to Chrome
          </Typography>
          <Box mt={1}>
            <Grid style={{ maxWidth: 300 }}>
              <Typography variant="subtitle2">
                Column uses advanced web technology that works best in an
                updated browser.
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Button
            className={classes.notInterested}
            onClick={() => {
              window.sessionStorage.setItem(SESSION_VAR, 'true');
              setOpen(false);
            }}
          >
            Not Now
          </Button>
          <Button
            className={classes.confirmButton}
            onClick={() => {
              window.sessionStorage.setItem(SESSION_VAR, 'true');
              window.open('https://www.google.com/chrome/');
              setOpen(false);
            }}
          >
            Get Chrome
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(PositionedPopper)
);
