export default {
  newspaper: {
    value: 1,
    label: 'Newspaper',
    key: 'newspaper',
    defaultOccupationKey: 'publishing'
  },
  government: {
    value: 2,
    label: 'Government Organization',
    key: 'government',
    defaultOccupationKey: 'government_official'
  },
  press_association: {
    value: 3,
    label: 'Press Association',
    key: 'press_association',
    defaultOccupationKey: 'press_association_manager'
  },
  law_firm: {
    value: 4,
    label: 'Law Firm',
    key: 'law_firm',
    defaultOccupationKey: 'lawyer'
  },
  holding_company: {
    value: 5,
    label: 'Holding Company',
    key: 'holding_company',
    defaultOccupationKey: 'publishing'
  },
  other_organization: {
    value: 6,
    label: 'Other Organization',
    key: 'other_organization',
    defaultOccupationKey: 'other_organization'
  }
};
