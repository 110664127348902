import moment from 'moment-timezone';
import { FirebaseTimestamp } from './types';

export const nDaysAgo = (n: number) => {
  const now = new Date();
  const pastDate = now.getDate() - n;
  now.setDate(pastDate);
  return now;
};

export const endOfDay = () => {
  const m = moment().endOf('day');
  m.hour(17);
  return m.utc().toDate();
};

export const dateTimeLikeToDate = (
  d: Date | FirebaseTimestamp
): Date | null => {
  if (!d) return null;
  if ((d as any)['.sv']) return null;
  return d ? ((d as any).toDate ? (d as any).toDate() : d) : null;
};

export const dateTimeLikeToTimestamp = (
  d: Date | FirebaseTimestamp
): { toDate: () => Date } | null => {
  if (!d) return null;
  if ((d as any).toDate) return d as FirebaseTimestamp;
  return { toDate: () => d as Date };
};
