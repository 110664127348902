import moment from 'moment';

export type TwentyFourHourTime = string;

export type Deadline = {
  dayEnum: number;
  publish: boolean;
  deadline: {
    dayEnum: number;
    time: TwentyFourHourTime;
  };
  weeks?: number;
  displayOffset?: number;
};

export const timeStringToDate = (timeString: Deadline['deadline']['time']) => {
  const date = new Date(Date.now());

  const h = timeString.split(':')[0];
  const m = timeString.split(':')[1];

  date.setHours(parseInt(h, 10), parseInt(m, 10));
  return date;
};

export const getDeadlineOverrideKeyFromDate = (date: Date): string =>
  `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

export const deadlineOverrideToDeadlineOverrideDate = (
  deadlineOverrideKey: string,
  deadline: Deadline
) => {
  const [month, day, year] = deadlineOverrideKey.split('/');

  const publishingDate = new Date(Number(year), Number(month), Number(day));
  const savedTime = timeStringToDate(deadline.deadline.time);

  let deadlineMoment = moment(publishingDate)
    .set('day', deadline.deadline.dayEnum - 1)
    .set('hour', savedTime.getHours())
    .set('minute', savedTime.getMinutes());
  if (deadlineMoment.isAfter(publishingDate)) {
    deadlineMoment = deadlineMoment.subtract(1, 'week');
  }
  if (deadline.weeks) {
    deadlineMoment = deadlineMoment.subtract(deadline.weeks, 'week');
  }

  return deadlineMoment.toDate();
};
