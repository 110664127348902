export const envs = {
  STAGING: 'STAGING',
  TEST: 'TEST',
  DEMO: 'DEMO',
  PROD: 'PROD',
  DEV: 'DEV'
};

export enum IndesignRoute {
  liner_ad_image = '/',
  display_ad_image = '/display/image',
  template_specs = '/template/specs',
  health = '/health',
  display_ad_marginalia = '/display/marginalia',
  paginate = '/paginate',
  paginate_rtf = '/paginate/rtf'
}

export const Collections = {
  announcements: 'announcements',
  subscriptions: 'subscriptions',
  publicNotices: 'publicNotices',
  userNotices: 'usernotices',
  userDrafts: 'userdrafts',
  users: 'users',
  organizations: 'organizations',
  affidavits: 'affidavits',
  invoices: 'invoices',
  notifications: 'notifications',
  emails: 'emails',
  mail: 'mail',
  courthouse: 'courthouse',
  invites: 'invites',
  adTemplates: 'adTemplates',
  rates: 'rates',
  notices: 'notices',
  transfers: 'transfers',
  previewNotices: 'previewNotices',
  emailConfirmations: 'emailConfirmations',
  sources: 'sources',
  donationProfiles: 'donationProfiles',
  donors: 'donors',
  donations: 'donations',
  cards: 'cards',
  donationPayouts: 'donationPayouts',
  cardInvoices: 'cardInvoices',
  cardTransactions: 'cardTransactions',
  joyrides: 'joyrides',
  events: 'events',
  customers: 'customers',
  customerOrganizations: 'customerOrganizations',
  survey: 'surveys',
  dwollaEvents: 'dwollaEvents'
};

export const Functions = {
  createCustomer: 'createCustomer',
  grantCustomClaim: 'grantCustomClaim',
  createPublisherDocsOnCall: 'createPublisherDocsOnCall',
  cloudConvertOnCall: 'cloudConvertOnCall',
  donate: 'donate',
  createBillingSession: 'createBillingSession'
};

export const EChars = {
  tab: `__enotice-tab-char`
};

let firebaseProject: string;
try {
  firebaseProject = require('../config.firebase').default.projectId;
} catch (err) {
  firebaseProject = process.env.GCLOUD_PROJECT!;
}
if (firebaseProject) console.log('using: ', firebaseProject);
else console.log('Cannot find local firebase project ID');

export const CLOUDINARY_BUCKET = 'dgqq2xsfd';

export const FIREBASE_PROJECT = firebaseProject;
export const PROJECT_LOCATION = 'us-central1';

export const PLAID_PUBLIC_KEY = '541c7f3939c359167f48904d2571a0';

export const DEFAULT_ACCEPT =
  '.docx,.doc,.csv,.xls,.xlsx,.txt,.rtf,.jpg,.jpeg,.png,.pdf,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/rtf,text/rtf,image/jpeg,image/png';

export const PRODUCTION_PROJECT = 'enotice-production';
export const STAGING_PROJECT = 'column-staging';
export const DEMO_PROJECT = 'enotice-demo-8d99a';

export const STRIPE = 'stipe';
export const PAYWAY = 'payway';
export const GATEWAYS = { STRIPE, PAYWAY };
