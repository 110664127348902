import Firebase from 'EnoticeFirebase';
import { store } from '../index';

class Api {
  baseString: string;

  getRestUrl(path: string) {
    return (Firebase.functions() as any)._url(`api/${path}`);
  }

  private async getToken() {
    const {
      auth: { userAuth }
    } = (store as any).getState();
    if (!userAuth) throw new Error('No userauth set');

    return await userAuth.getIdToken();
  }

  async get(path: string, params?: Record<string, any>) {
    const headers: Record<string, string> = {};
    try {
      const token = await this.getToken();
      headers.Authorization = `Bearer ${token}`;
    } catch (err) {}
    let apiUrl = this.getRestUrl(path);
    if (params) {
      apiUrl += `?${Object.keys(params)
        .map(k => `${k}=${String(params[k])}`)
        .join('&')}`;
    }

    const resp = await fetch(apiUrl, {
      headers
    });

    if (resp.status !== 200)
      throw new Error(`${resp.status} error: ${await resp.text()}`);

    return await resp.json();
  }

  async post(path: string, data?: Record<string, any>) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json'
    };
    try {
      const token = await this.getToken();
      headers.Authorization = `Bearer ${token}`;
    } catch (err) {}
    const url = this.getRestUrl(path);
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data || {}),
      headers
    });
    if (resp.status !== 200)
      throw new Error(`${resp.status} error: ${await resp.text()}`);

    return await resp.json();
  }
}

export default new Api();
