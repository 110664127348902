export default [
  {
    name: 'Greenwood County Courthouse',
    id: 35800722,
    name__1: 'Greenwood County Courthouse',
    address: '311 N Main',
    phone: '620-583-8153',
    fax: '620-583-6818',
    clerk_name: 'Tami Evenson',
    district: '13th',
    note: '',
    city: 'Eureka',
    zip: '67045'
  },
  {
    name: 'Geary County Courthouse',
    id: 35860617,
    name__1: 'Geary County Courthouse',
    address: '138 E 8th',
    phone: '785-762-5221',
    fax: '785-762-4420',
    clerk_name: 'Patty Aska',
    district: '8th',
    note: '',
    city: 'Junction City',
    zip: '66441'
  },
  {
    name: 'Allen County Courthouse',
    id: 35859091,
    name__1: 'Allen County Courthouse',
    address: '1 N Washington',
    phone: '620-365-1425',
    fax: '620-365-1429',
    clerk_name: 'Dina Morrison',
    district: '31st',
    note: '',
    city: 'Iola',
    zip: '66749'
  },
  {
    name: 'Labette County Courthouse',
    id: 35861583,
    name__1: 'Labette County Courthouse',
    address: '201 S Central',
    phone: '620-421-4120   620-795-4533',
    fax: '620-421-3633   620-795-3056',
    clerk_name: 'Terri Thurman',
    district: '11th',
    note:
      'Labette has two courthouses. https://www.kscourts.org/dstcts/11labeco.htm',
    city: 'Parsons',
    zip: '67357'
  },
  {
    name: 'Douglas County Courthouse',
    id: 35860223,
    name__1: 'Douglas County Courthouse',
    address: '111 E 11th',
    phone: '785-832-5256',
    fax: '785-832-5174',
    clerk_name: 'Douglas Hamilton',
    district: '7th',
    note: '',
    city: 'Lawrence',
    zip: '66044'
  },
  {
    name: 'Clark County Courthouse',
    id: 35859720,
    name__1: 'Clark County Courthouse',
    address: '913 Highland PO Box 790',
    phone: '620-635-2753',
    fax: '620-635-2155',
    clerk_name: 'Hilary Foster',
    district: '16th',
    note: '',
    city: 'Ashland',
    zip: '67831'
  },
  {
    name: 'Cherokee County Courthouse',
    id: 35859623,
    name__1: 'Cherokee County Courthouse',
    address: '110 W Maple PO Box 189',
    phone: '620-429-3880',
    fax: '620-429-1130',
    clerk_name: 'Terry Cizerle',
    district: '11th',
    note: '',
    city: 'Columbus',
    zip: '66725'
  },
  {
    name: 'Harvey County Courthouse',
    id: 35861061,
    name__1: 'Harvey County Courthouse',
    address: '8th and Main PO Box 665',
    phone: '316-284-6824',
    fax: '316-283-4601',
    clerk_name: 'Wilma J. Ratzlaff',
    district: '9th',
    note: '',
    city: 'Newton',
    zip: '67114'
  },
  {
    name: 'Decatur County Courthouse',
    id: 35860065,
    name__1: 'Decatur County Courthouse',
    address: '120 E Hall St PO Box 89',
    phone: '785-475-8107',
    fax: '785-475-8170',
    clerk_name: 'Rhonda Wildeman',
    district: '17th',
    note: '',
    city: 'Oberlin',
    zip: '67749'
  },
  {
    name: 'Pratt County Courthouse',
    id: 35865216,
    name__1: 'Pratt County Courthouse',
    address: '300 S Ninnescah PO Box 984',
    phone: '620-672-4100',
    fax: '620-672-2902',
    clerk_name: 'Crystal Gossett',
    district: '30th',
    note: '',
    city: 'Pratt',
    zip: '67124'
  },
  {
    name: 'Rawlins County Courthouse',
    id: 35865270,
    name__1: 'Rawlins County Courthouse',
    address: '607 Main #F',
    phone: '785-626-3465',
    fax: '785-626-3350',
    clerk_name: 'Wendy Holmdahl',
    district: '',
    note: '',
    city: 'Atwood',
    zip: '67730'
  },
  {
    name: 'Sheridan County Courthouse',
    id: 35866869,
    name__1: 'Sheridan County Courthouse',
    address: 'PO Box 753 925 9th Street',
    phone: '785-675-3451',
    fax: '785-675-2256',
    clerk_name: 'Rhonda White',
    district: '15th',
    note: '',
    city: 'Hoxie',
    zip: '67740'
  },
  {
    name: 'Osage County Courthouse',
    id: 35864944,
    name__1: 'Osage County Courthouse',
    address: '717 Topeka Ave PO Box 549',
    phone: '785-828-4713',
    fax: '785-828-4704',
    clerk_name: 'Charna Williams',
    district: '4th',
    note: '',
    city: 'Lyndon',
    zip: '66451'
  },
  {
    name: 'Pawnee County Courthouse',
    id: 35865090,
    name__1: 'Pawnee County Courthouse',
    address: '715 Broadway PO Box 270',
    phone: '620-285-6937',
    fax: '620-285-3665',
    clerk_name: 'Kay M. Schartz',
    district: '24th',
    note: '',
    city: 'Larned',
    zip: '67550'
  },
  {
    name: 'Reno County Courthouse',
    id: 35865318,
    name__1: 'Reno County Courthouse',
    address: '206 W First St 3rd Floor',
    phone: '620-694-2956',
    fax: '620-694-2958',
    clerk_name: 'Amanda Flores',
    district: '27th',
    note: '',
    city: 'Hutchinson',
    zip: '67501'
  },
  {
    name: 'Stafford County Courthouse',
    id: 35867093,
    name__1: 'Stafford County Courthouse',
    address: '209 N Broadway PO Box 365',
    phone: '620-549-3295',
    fax: '620-549-3298',
    clerk_name: 'Renee Salem',
    district: '20th',
    note: '',
    city: 'St John',
    zip: '67576'
  },
  {
    name: 'Meade County Courthouse',
    id: 35862121,
    name__1: 'Meade County Courthouse',
    address: '200 N Fowler PO Box 623',
    phone: '620-873-8750',
    fax: '620-873-8759',
    clerk_name: 'Decinda Heinz',
    district: '16th',
    note: '',
    city: 'Meade',
    zip: '67864'
  },
  {
    name: 'Lyon County Courthouse',
    id: 35861926,
    name__1: 'Lyon County Courthouse',
    address: '430 Commercial',
    phone: '620-341-3280',
    fax: '620-341-3497',
    clerk_name: 'Julie Cooper',
    district: '5th',
    note: '',
    city: 'Emporia',
    zip: '66801'
  },
  {
    name: 'Logan County Courthouse',
    id: 35866276,
    name__1: 'Logan County Courthouse',
    address: '710 W 2nd',
    phone: '785-671-3654',
    fax: '785-671-3517',
    clerk_name: 'Sonya Cooksey',
    district: '15th',
    note: '',
    city: 'Oakley',
    zip: '67748'
  },
  {
    name: 'Saline County Courthouse',
    id: 35866560,
    name__1: 'Saline County Courthouse',
    address: '300 W Ash PO Box 1760',
    phone: '785-309-5831',
    fax: '785-309-5845',
    clerk_name: 'Teresa Drane',
    district: '28th',
    note: '',
    city: 'Salina',
    zip: '67402'
  },
  {
    name: 'Cloud County Courthouse',
    id: 35859821,
    name__1: 'Cloud County Courthouse',
    address: '811 Washington Suite P',
    phone: '785-243-8124',
    fax: '785-243-8188',
    clerk_name: 'Kristi Benyshek',
    district: '12th',
    note: '',
    city: 'Concordia',
    zip: '66901'
  },
  {
    name: 'Lane County Courthouse',
    id: 35861654,
    name__1: 'Lane County Courthouse',
    address: '144 S Lane PO Box 188',
    phone: '620-397-2805',
    fax: '620-397-5526',
    clerk_name: 'Marlene Rupp',
    district: '24th',
    note: '',
    city: 'Dighton',
    zip: '67839'
  },
  {
    name: 'Anderson County Courthouse',
    id: 35859149,
    name__1: 'Anderson County Courthouse',
    address: '100 E 4th PO Box 305',
    phone: '785-448-6886',
    fax: '785-448-3230',
    clerk_name: 'Carla Skiles',
    district: '4th',
    note: '',
    city: 'Garnett',
    zip: '66032'
  },
  {
    name: 'Grant County Courthouse',
    id: 35860763,
    name__1: 'Grant County Courthouse',
    address: '108 S Glenn',
    phone: '620-356-1526',
    fax: '620-353-2131',
    clerk_name: 'Reid Borchetta',
    district: '26th',
    note: '',
    city: 'Ulysses',
    zip: '67880'
  },
  {
    name: 'Greeley County Courthouse',
    id: 35860864,
    name__1: 'Greeley County Courthouse',
    address: '616 2nd Ave PO Box 516',
    phone: '620-376-4292',
    fax: '620-376-2351',
    clerk_name: 'Tammi Hazel',
    district: '25th',
    note: '',
    city: 'Tribune',
    zip: '67879'
  },
  {
    name: 'Coffey County Courthouse',
    id: 35859867,
    name__1: 'Coffey County Courthouse',
    address: '110 S 6th St, Ste 102',
    phone: '620-364-8628',
    fax: '620-364-8535',
    clerk_name: 'Debbie Poire',
    district: '4th',
    note: '',
    city: 'Burlington',
    zip: '66839'
  },
  {
    name: 'Barton County Courthouse',
    id: 35859306,
    name__1: 'Barton County Courthouse',
    address: '1400 Main Room 306',
    phone: '620-793-1856',
    fax: '620-793-1860',
    clerk_name: 'Martha Rivas',
    district: '20th',
    note: '',
    city: 'Great Bend',
    zip: '67530'
  },
  {
    name: 'Butler County Courthouse',
    id: 35859460,
    name__1: 'Butler County Courthouse',
    address: '201 W Pine Suite 101',
    phone: '316-322-4370',
    fax: '316-321-9486',
    clerk_name: 'Janell Jessup',
    district: '13th',
    note: '',
    city: 'El Dorado',
    zip: '67042'
  },
  {
    name: 'Kearny County Courthouse',
    id: 35861420,
    name__1: 'Kearny County Courthouse',
    address: '304 N Main PO Box 64',
    phone: '620-271-6254',
    fax: '620-355-7462',
    clerk_name: 'Cherie Rutherford',
    district: '13th',
    note: '',
    city: 'Lakin',
    zip: '67860'
  },
  {
    name: 'Elk County Courthouse',
    id: 35860317,
    name__1: 'Elk County Courthouse',
    address: '127 N Pine PO Box 306',
    phone: '620-374-2370',
    fax: '620-374-3531',
    clerk_name: 'Erin Meader',
    district: '13th',
    note: '',
    city: 'Howard',
    zip: '67349'
  },
  {
    name: 'Jewell County Courthouse',
    id: 35866256,
    name__1: 'Jewell County Courthouse',
    address: '307 N Commercial',
    phone: '785-378-4030',
    fax: '785-378-4035',
    clerk_name: 'Dixie L Dethloff',
    district: '12th',
    note: '',
    city: 'Mankato',
    zip: '66956'
  },
  {
    name: 'Marion County Courthouse',
    id: 35861978,
    name__1: 'Marion County Courthouse',
    address: '200 S 3rd Suite 201',
    phone: '620-382-2104',
    fax: '620-382-2259',
    clerk_name: 'Jan Helmer',
    district: '8th',
    note: '',
    city: 'Marion',
    zip: '66861'
  },
  {
    name: 'Miami County Courthouse',
    id: 35862174,
    name__1: 'Miami County Courthouse',
    address: '120 S Pearl',
    phone: '913-294-3326',
    fax: '913-294-2535',
    clerk_name: 'Stephanie Gerken',
    district: '6th',
    note: '',
    city: 'Paola',
    zip: '66071'
  },
  {
    name: 'Shawnee County Courthouse',
    id: 35866772,
    name__1: 'Shawnee County Courthouse',
    address: '200 SE 7th Street Suite 209',
    phone: '785-251-6700',
    fax: '785-251-4908',
    clerk_name: 'Angela M. Callahan',
    district: '3rd',
    note: '',
    city: 'Topeka',
    zip: '66603'
  },
  {
    name: 'Neosho County Courthouse',
    id: 35864647,
    name__1: 'Neosho County Courthouse',
    address: '102 S Lincoln PO Box 889',
    phone: '620-431-5700   620-244-3831',
    fax: '620-431-5710   620-244-3830',
    clerk_name: 'Angela Walters',
    district: '31st',
    note:
      'Neosho has two courthouses. http://www.31stjudicialdistrict.org/11122.html',
    city: 'Chanute',
    zip: '66720'
  },
  {
    name: 'Stevens County Courthouse',
    id: 35867224,
    name__1: 'Stevens County Courthouse',
    address: '200 E 6th',
    phone: '620-544-2484',
    fax: '620-544-2528',
    clerk_name: 'Kelsee Burnett',
    district: '26th',
    note: '',
    city: 'Hugoton',
    zip: '67951'
  },
  {
    name: 'McPherson County Courthouse',
    id: 35862081,
    name__1: 'McPherson County Courthouse',
    address: '117 N Maple St PO Box 1106',
    phone: '620-241-3422',
    fax: '620-241-1372',
    clerk_name: 'Cindy Teter',
    district: '9th',
    note: '',
    city: 'McPherson',
    zip: '67460'
  },
  {
    name: 'Sumner County Courthouse',
    id: 35867277,
    name__1: 'Sumner County Courthouse',
    address: '501 N Washington PO Box 399',
    phone: '620-326-5936',
    fax: '620-326-5365',
    clerk_name: 'Wanda Keith',
    district: '30th',
    note: '',
    city: 'Wellington',
    zip: '67152'
  },
  {
    name: 'Leavenworth County Courthouse',
    id: 35861706,
    name__1: 'Leavenworth County Courthouse',
    address: '601 S 3rd St Suite 3051',
    phone: '913-684-0700',
    fax: '913-684-0492',
    clerk_name: 'Julie Clemens',
    district: '1st',
    note: '',
    city: 'Leavenworth',
    zip: '66048'
  },
  {
    name: 'Ottawa County Courthouse',
    id: 35865038,
    name__1: 'Ottawa County Courthouse',
    address: '307 N Concord St Ste 110',
    phone: '785-392-2917',
    fax: '785-392-3626',
    clerk_name: 'Erin Kearn',
    district: '28th',
    note: '',
    city: 'Minneapolis',
    zip: '67467'
  },
  {
    name: 'Republic County Courthouse',
    id: 35865372,
    name__1: 'Republic County Courthouse',
    address: '1815 M Street PO Box 8',
    phone: '785-527-7234',
    fax: '785-527-5029',
    clerk_name: 'Kristen L. Kling',
    district: '12th',
    note: '',
    city: 'Belleville',
    zip: '66935'
  },
  {
    name: 'Edwards County Courthouse',
    id: 35860274,
    name__1: 'Edwards County Courthouse',
    address: '312 Massachusetts PO Box 232',
    phone: '620-659-2442',
    fax: '620-659-2998',
    clerk_name: 'Linda Atteberry',
    district: '24th',
    note: '',
    city: 'Kinsley',
    zip: '67547'
  },
  {
    name: 'Barber County Courthouse',
    id: 35859257,
    name__1: 'Barber County Courthouse',
    address: '118 E Washington',
    phone: '620-886-5639',
    fax: '620-886-5854',
    clerk_name: 'Ann McNett',
    district: '30th',
    note: '',
    city: 'Medicine Lodge',
    zip: '67104'
  },
  {
    name: 'Doniphan County Courthouse',
    id: 35860162,
    name__1: 'Doniphan County Courthouse',
    address: '120 E Chestnut PO Box 295',
    phone: '785-985-3582',
    fax: '785-985-2402',
    clerk_name: 'Michelle Smith',
    district: '22nd',
    note: '',
    city: 'Troy',
    zip: '66087'
  },
  {
    name: 'Norton County Courthouse',
    id: 35864853,
    name__1: 'Norton County Courthouse',
    address: '101 S Kansas PO Box 70',
    phone: '785-877-5720',
    fax: '785-877-5722',
    clerk_name: 'Janelle Morel',
    district: '17th',
    note: '',
    city: 'Norton',
    zip: '67654'
  },
  {
    name: 'Wallace County Courthouse',
    id: 35867491,
    name__1: 'Wallace County Courthouse',
    address: 'PO Box 8 313 Main',
    phone: '785-852-4289',
    fax: '785-852-4271',
    clerk_name: 'Rosella Ryser',
    district: '15th',
    note: '',
    city: 'Sharon Springs',
    zip: '67758'
  },
  {
    name: 'Scott County Courthouse',
    id: 35866622,
    name__1: 'Scott County Courthouse',
    address: '303 Court',
    phone: '620-872-7208',
    fax: '620-872-3683',
    clerk_name: 'Sandra Eitel',
    district: '25th',
    note: '',
    city: 'St Scott City',
    zip: '67871'
  },
  {
    name: 'Wilson County Courthouse',
    id: 35867688,
    name__1: 'Wilson County Courthouse',
    address: '615 Madison Rm 214',
    phone: '620-378-4533',
    fax: '620-378-4531',
    clerk_name: 'Janel M. Downey',
    district: '31st',
    note: '',
    city: 'Fredonia',
    zip: '66736'
  },
  {
    name: 'Hodgeman County Courthouse',
    id: 35861145,
    name__1: 'Hodgeman County Courthouse',
    address: '500 Main PO Box 187',
    phone: '620-357-6522',
    fax: '620-357-6216',
    clerk_name: 'Dana Hoss',
    district: '24th',
    note: '',
    city: 'Jetmore',
    zip: '67854'
  },
  {
    name: 'Graham County Courthouse',
    id: 35865864,
    name__1: 'Graham County Courthouse',
    address: '410 N Pomeroy',
    phone: '785-421-3458',
    fax: '785-421-5463',
    clerk_name: 'Tiffany Breinig',
    district: '17th',
    note: '',
    city: 'Hill City',
    zip: '67642'
  },
  {
    name: 'Chase County Courthouse',
    id: 35859515,
    name__1: 'Chase County Courthouse',
    address: '300 Pearl PO Box 529',
    phone: '620-273-6319',
    fax: '620-273-6890',
    clerk_name: 'Barbara Davis',
    district: '5th',
    note: '',
    city: 'Cottonwood Falls',
    zip: '66845'
  },
  {
    name: 'Cheyenne County Courthouse',
    id: 35859672,
    name__1: 'Cheyenne County Courthouse',
    address: 'PO Box 646 212 E Washington',
    phone: '785-332-8850',
    fax: '785-332-8851',
    clerk_name: 'Natalie Stahlecker',
    district: '15th',
    note: '',
    city: 'St Francis',
    zip: '67756'
  },
  {
    name: 'Ford County Courthouse',
    id: 35860513,
    name__1: 'Ford County Courthouse',
    address: '101 W Spruce PO Box 197',
    phone: '620-227-4609',
    fax: '620-227-6799',
    clerk_name: 'Rhonda Whitney',
    district: '16th',
    note: '',
    city: 'Dodge City',
    zip: '67801'
  },
  {
    name: 'Woodson County Courthouse',
    id: 35867751,
    name__1: 'Woodson County Courthouse',
    address: '105 W Rutledge St #105',
    phone: '620-625-8610',
    fax: '620-625-8674',
    clerk_name: 'Lisa Page',
    district: '31st',
    note: '',
    city: 'Yates Center',
    zip: '66783'
  },
  {
    name: 'Kiowa County Courthouse',
    id: 35861530,
    name__1: 'Kiowa County Courthouse',
    address: '211 E Florida',
    phone: '620-723-3317',
    fax: '620-723-2970',
    clerk_name: 'Debra Schmidt',
    district: '16th',
    note: '',
    city: 'Greensburg',
    zip: '67054'
  },
  {
    name: 'Finney County Courthouse',
    id: 35860449,
    name__1: 'Finney County Courthouse',
    address: '425 N 8th St',
    phone: '620-271-6120',
    fax: '620-271-6140',
    clerk_name: 'Christine Blake',
    district: '25th',
    note: '',
    city: 'Garden City',
    zip: '67846'
  },
  {
    name: 'Ness County Courthouse',
    id: 35864800,
    name__1: 'Ness County Courthouse',
    address: '100 S Kansas PO Box 445',
    phone: '785-798-3693',
    fax: '785-798-3348',
    clerk_name: 'Joby Henning',
    district: '24th',
    note: '',
    city: 'Ness City',
    zip: '67560'
  },
  {
    name: 'Washington County Courthouse',
    id: 35867565,
    name__1: 'Washington County Courthouse',
    address: '214 C Street',
    phone: '785-325-2381',
    fax: '785-325-2557',
    clerk_name: 'Aaron Debes',
    district: '12th',
    note: '',
    city: 'Washington',
    zip: '66968'
  },
  {
    name: 'Russell County Courthouse',
    id: 35865604,
    name__1: 'Russell County Courthouse',
    address: '401 N Main St PO Box 876',
    phone: '785-483-5641',
    fax: '785-483-2448',
    clerk_name: 'Laura Seirer',
    district: '20th',
    note: '',
    city: 'Russell',
    zip: '67665'
  },
  {
    name: 'Morris County Courthouse',
    id: 35864489,
    name__1: 'Morris County Courthouse',
    address: '501 W Main',
    phone: '620-767-6838',
    fax: '620-767-6488',
    clerk_name: 'Kathleen Rohloff',
    district: '8th',
    note: '',
    city: 'Council Grove',
    zip: '66846'
  },
  {
    name: 'Rice County Courthouse',
    id: 35865425,
    name__1: 'Rice County Courthouse',
    address: '101 W Commercial St 3rd Floor',
    phone: '620-257-2383',
    fax: '620-257-3826',
    clerk_name: 'Jane Hrabik',
    district: '20th',
    note: '',
    city: 'Lyons',
    zip: '67554'
  },
  {
    name: 'Sedgwick County Courthouse',
    id: 35866671,
    name__1: 'Sedgwick County Courthouse',
    address: '525 N Main',
    phone: '316-660-5801',
    fax: '316-660-5784',
    clerk_name: 'Bernie Lumbreras',
    district: '18th',
    note: '',
    city: 'Wichita',
    zip: '67203'
  },
  {
    name: 'Sedgwick County Dist. Court - Probate',
    id: 35866273,
    name__1: 'Sedgwick County Dist. Court - Probate',
    address: '1900 E Morris St',
    phone: '316-660-5900',
    fax: '316-941-5357',
    clerk_name: 'Bernie Lumbreras',
    district: '18th',
    note: '',
    city: 'Wichita',
    zip: '67211'
  },
  {
    name: 'Montgomery County Courthouse',
    id: 35864439,
    name__1: 'Montgomery County Courthouse',
    address: '300 E Main St Suite 201',
    phone: '620-330-1070   620-251-1060',
    fax: '620-331-6120   620-251-2734',
    clerk_name: 'Rebecca J. Dye',
    district: '14th',
    note: 'This county has two courthouses. https://www.ks14jd.com/',
    city: 'Independence',
    zip: '67301'
  },
  {
    name: 'Rush County Courthouse',
    id: 35865627,
    name__1: 'Rush County Courthouse',
    address: '715 Elm PO Box 387',
    phone: '785-222-2718',
    fax: '785-222-2748',
    clerk_name: 'Pamela Davis',
    district: '24th',
    note: '',
    city: 'LaCrosse',
    zip: '67548'
  },
  {
    name: 'Osborne County Courthouse',
    id: 35865829,
    name__1: 'Osborne County Courthouse',
    address: '423 W Main PO Box 160',
    phone: '785-282-5140',
    fax: '785-282-5145',
    clerk_name: 'Cathi Holt',
    district: '17th',
    note: '',
    city: 'Osborne',
    zip: '67473'
  },
  {
    name: 'Smith County Courthouse',
    id: 35867008,
    name__1: 'Smith County Courthouse',
    address: '218 S Grant PO Box 273',
    phone: '785-346-5911',
    fax: '785-346-5992',
    clerk_name: 'Michelle LeiVan',
    district: '17th',
    note: '',
    city: 'Smith Center',
    zip: '66967'
  },
  {
    name: 'Crawford County Courthouse',
    id: 35860020,
    name__1: 'Crawford County Courthouse',
    address: '602 N Locust',
    phone: '620-231-0380  620-724-6212',
    fax: '620-231-0316   620-724-4987',
    clerk_name: 'Debra L. Russell',
    district: '11th',
    note:
      'This county has two courthouses. https://www.crawfordcountykansas.org/county-courthouse.html',
    city: 'Pittsburg',
    zip: '66762'
  },
  {
    name: 'Cowley County Courthouse',
    id: 35859971,
    name__1: 'Cowley County Courthouse',
    address: '311 E 9th PO Box 472',
    phone: '620-221-5480   620-441-4520',
    fax: '620-221-1097  620-442-7213',
    clerk_name: 'Cheryl Wilson',
    district: '19th',
    note: 'This County has two courthouses. http://www.cowleycounty.org/court',
    city: 'Winfield',
    zip: '67156'
  },
  {
    name: 'Wyandotte County Courthouse',
    id: 35867819,
    name__1: 'Wyandotte County Courthouse',
    address: '710 N 7th St',
    phone: '(913) 573-5000',
    fax: 'no fax',
    clerk_name: 'Kristi L. Hill',
    district: '29th',
    note: '',
    city: 'Kansas City',
    zip: '66101'
  },
  {
    name: 'Comanche County Courthouse',
    id: 35859918,
    name__1: 'Comanche County Courthouse',
    address: '201 S New York PO Box 722',
    phone: '620-582-2182',
    fax: '620-582-2603',
    clerk_name: 'Penny Wells',
    district: '16th',
    note: '',
    city: 'Coldwater',
    zip: '67029'
  },
  {
    name: 'Phillips County Courthouse',
    id: 35865698,
    name__1: 'Phillips County Courthouse',
    address: '301 State St PO Box 564',
    phone: '785-543-6830',
    fax: '785-543-6832',
    clerk_name: 'Debra Grammon',
    district: '17th',
    note: '',
    city: 'Phillipsburg',
    zip: '67661'
  },
  {
    name: 'Thomas County Courthouse',
    id: 35867336,
    name__1: 'Thomas County Courthouse',
    address: 'PO Box 805 300 N Court',
    phone: '785-460-4540',
    fax: '785-460-2291',
    clerk_name: 'Kim Schwarz',
    district: '15th',
    note: '',
    city: 'Colby',
    zip: '67701'
  },
  {
    name: 'Lincoln County Courthouse',
    id: 35861751,
    name__1: 'Lincoln County Courthouse',
    address: '216 E Lincoln Avenue',
    phone: '785-524-4057',
    fax: '785-524-3204',
    clerk_name: 'Taylor Lebien',
    district: '12th',
    note: '',
    city: 'Lincoln',
    zip: '67455'
  },
  {
    name: 'Harper County Courthouse',
    id: 35861001,
    name__1: 'Harper County Courthouse',
    address: '201 N Jennings PO Box 467',
    phone: '620-842-3721',
    fax: '620-842-6025',
    clerk_name: 'Rachel Denton',
    district: '30th',
    note: '',
    city: 'Anthony',
    zip: '67003'
  },
  {
    name: 'Brown County Courthouse',
    id: 35859399,
    name__1: 'Brown County Courthouse',
    address: '601 Oregon PO Box 417',
    phone: '785-742-7481',
    fax: '785-742-3506',
    clerk_name: 'Joy Moore',
    district: '22nd',
    note: '',
    city: 'Hiawatha',
    zip: '66434'
  },
  {
    name: 'Gray County Courthouse',
    id: 35860817,
    name__1: 'Gray County Courthouse',
    address: '300 S Main PO Box 487',
    phone: '620-855-3812',
    fax: '620-855-7037',
    clerk_name: 'Angela Bowlin',
    district: '16th',
    note: '',
    city: 'Cimarron',
    zip: '67835'
  },
  {
    name: 'Franklin County Courthouse',
    id: 35860566,
    name__1: 'Franklin County Courthouse',
    address: '301 S Main PO Box 637',
    phone: '785-242-6000',
    fax: '785-242-5970',
    clerk_name: 'Linda S. Meier',
    district: '4th',
    note: '',
    city: 'Ottawa',
    zip: '66067'
  },
  {
    name: 'Kingman County Courthouse',
    id: 35861473,
    name__1: 'Kingman County Courthouse',
    address: '130 N Spruce PO Box 495',
    phone: '620-532-5151',
    fax: '620-532-2952',
    clerk_name: 'Staci L. Jackson',
    district: '30th',
    note: '',
    city: 'Kingman',
    zip: '67068'
  },
  {
    name: 'Jefferson County Courthouse',
    id: 35861255,
    name__1: 'Jefferson County Courthouse',
    address: '300 Jefferson PO Box 327',
    phone: '785-863-2461',
    fax: '785-863-2369',
    clerk_name: 'Connie Valenta',
    district: '2nd',
    note: '',
    city: 'Oskaloosa',
    zip: '66066'
  },
  {
    name: 'Rooks County Courthouse',
    id: 35865644,
    name__1: 'Rooks County Courthouse',
    address: '115 N Walnut PO Box 532',
    phone: '785-425-6718',
    fax: '785-425-6568',
    clerk_name: 'Connie Stithem',
    district: '23rd',
    note: '',
    city: 'Stockton',
    zip: '67669'
  },
  {
    name: 'Stanton County Courthouse',
    id: 35867152,
    name__1: 'Stanton County Courthouse',
    address: '201 N Main Box 913',
    phone: '620-492-2180',
    fax: '620-492-6410',
    clerk_name: 'Bonnie Parks',
    district: '26th',
    note: '',
    city: 'Johnson',
    zip: '67855'
  },
  {
    name: 'Mitchell County Courthouse',
    id: 35866355,
    name__1: 'Mitchell County Courthouse',
    address: '115 S Hersey',
    phone: '785-738-3753',
    fax: '785-738-4101',
    clerk_name: 'Pam Thiessen',
    district: '12th',
    note: '',
    city: 'Beloit',
    zip: '67420'
  },
  {
    name: 'Trego County Courthouse',
    id: 35867400,
    name__1: 'Trego County Courthouse',
    address: '216 N Main',
    phone: '785-743-2148',
    fax: '785-743-2726',
    clerk_name: 'Tiffany Gillespie',
    district: '23rd',
    note: '',
    city: 'WaKeeney',
    zip: '67672'
  },
  {
    name: 'Marshall County Courthouse',
    id: 35862026,
    name__1: 'Marshall County Courthouse',
    address: '1201 Broadway PO Box 149',
    phone: '785-562-5301',
    fax: '785-562-2458',
    clerk_name: 'Nancy Koch',
    district: '22nd',
    note: '',
    city: 'Marysville',
    zip: '66508'
  },
  {
    name: 'Clay County Courthouse',
    id: 35859769,
    name__1: 'Clay County Courthouse',
    address: '712 5th St Ste 204',
    phone: '785-632-3443',
    fax: '785-632-2651',
    clerk_name: 'Melissa Stellner',
    district: '21st',
    note: '',
    city: 'Clay Center',
    zip: '67432'
  },
  {
    name: 'Ellsworth County Courthouse',
    id: 35860400,
    name__1: 'Ellsworth County Courthouse',
    address: '210 N Kansas Ave',
    phone: '785-472-4052',
    fax: '785-472-5712',
    clerk_name: 'Peggy Svaty',
    district: '20th',
    note: '',
    city: 'Ellsworth',
    zip: '67439'
  },
  {
    name: 'Hamilton County Courthouse',
    id: 35860964,
    name__1: 'Hamilton County Courthouse',
    address: '219 N Main PO Box 745',
    phone: '620-384-5159',
    fax: '620-384-7806',
    clerk_name: 'Glenda Cheatum',
    district: '25th',
    note: '',
    city: 'Syracuse',
    zip: '67878'
  },
  {
    name: 'Jackson County Courthouse',
    id: 35866227,
    name__1: 'Jackson County Courthouse',
    address: '400 New York Room 311',
    phone: '785-364-2191',
    fax: '785-364-3804',
    clerk_name: 'Jennifer Strathman',
    district: '2nd',
    note: '',
    city: 'Holton',
    zip: '66436'
  },
  {
    name: 'Johnson County Courthouse',
    id: 35861368,
    name__1: 'Johnson County Courthouse',
    address: '100 N Kansas Ave',
    phone: '913-715-3500',
    fax: '913-715-3401',
    clerk_name: 'Jennifer Leach',
    district: '10th',
    note: '',
    city: 'Olathe',
    zip: '66061'
  },
  {
    name: 'Bourbon County Courthouse',
    id: 35859350,
    name__1: 'Bourbon County Courthouse',
    address: '210 S National PO Box 868',
    phone: '620-223-0780',
    fax: '620-223-5303',
    clerk_name: 'Rhonda Cole',
    district: '6th',
    note: '',
    city: 'Fort Scott',
    zip: '66701'
  },
  {
    name: 'Chautauqua County Courthouse',
    id: 35859568,
    name__1: 'Chautauqua County Courthouse',
    address: '215 N Chautauqua St Box 306',
    phone: '620-725-5870',
    fax: '620-725-3027',
    clerk_name: 'Cynthia Weaver',
    district: '',
    note: '',
    city: 'Sedan',
    zip: '67361'
  },
  {
    name: 'Atchsion County Courthouse',
    id: 35859201,
    name__1: 'Atchsion County Courthouse',
    address: '423 N 5th Street PO Box 408',
    phone: '913-804-6066',
    fax: '913-367-1171',
    clerk_name: 'Donna Oswald',
    district: '',
    note: '',
    city: 'Atchison',
    zip: '66002'
  },
  {
    name: 'Morton County Courthouse',
    id: 35864529,
    name__1: 'Morton County Courthouse',
    address: '1025 Morton PO Box 825',
    phone: '620-697-2563',
    fax: '620-697-4289',
    clerk_name: 'Matti L. Rich',
    district: '26th',
    note: '',
    city: 'Elkhart',
    zip: '67950'
  },
  {
    name: 'Wabaunsee County Courthouse',
    id: 35863188,
    name__1: 'Wabaunsee County Courthouse',
    address: '215 Kansas',
    phone: '785-765-2406',
    fax: '785-765-2487',
    clerk_name: 'Angie Anderson',
    district: '2nd',
    note: '',
    city: 'Alma',
    zip: '66401'
  },
  {
    name: 'Sherman County Courthouse',
    id: 35866958,
    name__1: 'Sherman County Courthouse',
    address: '813 Broadway Room 201',
    phone: '785-890-4850',
    fax: '785-890-4858',
    clerk_name: 'Jacki Waters',
    district: '',
    note: '',
    city: 'Goodland',
    zip: '67735'
  },
  {
    name: 'Nemaha County Courthouse',
    id: 35864602,
    name__1: 'Nemaha County Courthouse',
    address: '607 Nemaha PO Box 213',
    phone: '785-336-2146',
    fax: '785-336-6450',
    clerk_name: 'Amy M. Entrikin',
    district: '22nd',
    note: '',
    city: 'Seneca',
    zip: '66538'
  },
  {
    name: 'Linn County Courthouse',
    id: 35861818,
    name__1: 'Linn County Courthouse',
    address: '318 Chestnut PO Box 350',
    phone: '913-795-2660',
    fax: '913-795-2004',
    clerk_name: 'Lori Simmons',
    district: '6th',
    note: '',
    city: 'Mound City',
    zip: '66056'
  },
  {
    name: 'Seward County Courthouse',
    id: 35866731,
    name__1: 'Seward County Courthouse',
    address: '415 N Washington Ste 103',
    phone: '620-626-3375',
    fax: '620-626-3302',
    clerk_name: 'Koleen Nosekabel',
    district: '26th',
    note: '',
    city: 'Liberal',
    zip: '67901'
  },
  {
    name: 'Dickinson County Courthouse',
    id: 35860111,
    name__1: 'Dickinson County Courthouse',
    address: '109 E 1st PO Box 127',
    phone: '785-263-3142',
    fax: '785-263-4407',
    clerk_name: 'Cindy MacDonald',
    district: '8th',
    note: '',
    city: 'Abilene',
    zip: '67410'
  },
  {
    name: 'Haskell County Courthouse',
    id: 35861098,
    name__1: 'Haskell County Courthouse',
    address: '300 S Inman PO Box 146',
    phone: '620-675-2671',
    fax: '620-675-8599',
    clerk_name: 'Donna Odneal',
    district: '26th',
    note: '',
    city: 'Sublette',
    zip: '67877'
  },
  {
    name: 'Gove County Courthouse',
    id: 35860658,
    name__1: 'Gove County Courthouse',
    address: '420 Broad St PO Box 97',
    phone: '785-938-2310',
    fax: '785-938-2312',
    clerk_name: 'Teresa Lewis',
    district: '23rd',
    note: '',
    city: 'Gove',
    zip: '67736'
  },
  {
    name: 'Pottawatomie County Courthouse',
    id: 44545285,
    name__1: 'Pottawatomie County Courthouse',
    address: '106 Main Street PO Box 129',
    phone: '785-457-3392',
    fax: '785-457-2107',
    clerk_name: 'Sara Helget',
    district: '2nd',
    note: '',
    city: 'Westmoreland',
    zip: '66549'
  },
  {
    name: 'Wichita County Courthouse',
    id: 44545281,
    name__1: 'Wichita County Courthouse',
    address: '206 S 4th PO Box 968',
    phone: '620-260-2560',
    fax: '620-375-2999',
    clerk_name: 'Deborah Mitchell',
    district: '25th',
    note: '',
    city: 'Leoti',
    zip: '67861'
  },
  {
    name: 'Ellis County Courthouse',
    id: 44545307,
    name__1: 'Ellis County Courthouse',
    address: '1204 Fort St PO Box 8',
    phone: '785-628-9415',
    fax: '785-628-8415',
    clerk_name: 'vacant',
    district: '23rd',
    note: '',
    city: 'Hays',
    zip: '67601'
  },
  {
    name: 'Riley County Courthouse',
    id: 44545284,
    name__1: 'Riley County Courthouse',
    address: '100 Courthouse Plaza',
    phone: '785-537-6364',
    fax: '785-537-6382',
    clerk_name: 'Katherine Oliver',
    district: '21st',
    note: '',
    city: 'Manhattan',
    zip: '66502'
  }
];
