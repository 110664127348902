import { EOrganization, ESnapshotExists, ESnapshot, ENotice } from '../types';
import { State, OrganizationType, NoticeType } from '../enums';
import { CustomNoticeFilingType } from '../types/organization';
import Country from '../enums/Country';

export const publisherReadyToUpload = (org: ESnapshotExists<EOrganization>) =>
  !org.data().disabled &&
  org.data().name &&
  org.data().address &&
  org.data().phone &&
  org.data().city &&
  org.data().county &&
  org.data().state &&
  org.data().adTemplate &&
  org.data().deadlines &&
  (org.data().affidavitDisabled ||
    (org.data().signatoryName &&
      org.data().signatoryRole &&
      org.data().publicationFrequency));

export const getNoticeTypeData = (
  noticeType: number,
  org: ESnapshot<EOrganization> | ESnapshotExists<EOrganization>
) => {
  const selectedNoticeType =
    org
      .data()!
      .allowedNotices?.find(
        allowedNotice => allowedNotice.value === parseInt(noticeType as any, 10)
      ) || NoticeType.by_value(noticeType);

  if (!selectedNoticeType) {
    console.error(
      `Unable to find typeform value ${noticeType} for organization ${org.id}`
    );
    return (NoticeType.custom as unknown) as CustomNoticeFilingType;
  }
  return selectedNoticeType as CustomNoticeFilingType;
};

export const getNoticeTypeStr = (
  noticeType: number | null,
  org: ESnapshot<EOrganization> | ESnapshotExists<EOrganization>
) => {
  if (noticeType) return getNoticeTypeData(noticeType, org).label as string;
  return 'Public';
};

export const getNoticeTypeDataFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaper = await notice.data().newspaper.get();
  return getNoticeTypeData(
    notice.data().noticeType,
    newspaper
  ) as CustomNoticeFilingType;
};

export const isBattlebornSync = (
  org: ESnapshotExists<EOrganization>,
  parent: ESnapshotExists<EOrganization> | null = null
) => {
  const { name } = org.data();
  if (
    name.toLowerCase().includes('sparks') ||
    name.toLowerCase().includes('mineral county') ||
    name.toLowerCase().includes('mesquite local') ||
    name.toLowerCase().includes('eureka sentinel') ||
    name.toLowerCase().includes('ely')
  )
    return true;

  if (
    parent
      ?.data()
      ?.name.toLowerCase()
      .includes('battle born')
  ) {
    return true;
  }

  return false;
};

// Organization matchers
export const isBattleborn = async (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  const { parent } = org.data();
  let p;
  if (parent) p = (await parent.get()) as ESnapshotExists<EOrganization>;
  return isBattlebornSync(org, p);
};

export const isMontroseOrDelta = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return (
    org.data().xmlExport &&
    (org.data().xmlExport?.format === 'VD_MONTROSE' ||
      org.data().xmlExport?.format === 'VD_DELTA')
  );
};

export const isGreenValleyOrSahuarita = (
  org: ESnapshotExists<EOrganization>
) => {
  if (!org) return;
  return (
    org.data().xmlExport &&
    (org.data().xmlExport?.format === 'VD_GREEN_VALLEY' ||
      org.data().xmlExport?.format === 'VD_SAHUARITA')
  );
};

export const isEasternArizonaOrCopperEra = (
  org: ESnapshotExists<EOrganization>
) => {
  if (!org) return;
  return (
    org.data().xmlExport &&
    (org.data().xmlExport?.format === 'VD_EASTERN_ARIZONA' ||
      org.data().xmlExport?.format === 'VD_COPPER_ERA')
  );
};

export const isNogales = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return org.data().xmlExport && org.data().xmlExport?.format === 'VD_NOGALES';
};

export const isSierraVista = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return org.data().xmlExport && org.data().xmlExport?.format === 'VD_HERALD';
};

export const isDailyTerritorial = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return (
    org.data().xmlExport &&
    org.data().xmlExport?.format === 'VD_DAILY_TERRITORIAL'
  );
};

export const isNogalesOrDailyTerritorial = (
  org: ESnapshotExists<EOrganization>
) => {
  if (!org) return;
  return isNogales(org) || isDailyTerritorial(org);
};

export const isSantaMaria = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return (
    org.data().xmlExport && org.data().xmlExport?.format === 'VD_SANTA_MARIA'
  );
};

export const isLompocRecord = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return org.data().xmlExport && org.data().xmlExport?.format === 'VD_LOMPOC';
};

export const isSantaYnez = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return (
    org.data().xmlExport && org.data().xmlExport?.format === 'VD_SANTA_YNEZ'
  );
};

export const isSantaMariaGroup = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return isSantaMaria(org) || isSantaYnez(org) || isLompocRecord(org);
};

export const isMatSU = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return org.data().xmlExport && org.data().xmlExport?.format === 'VD_MAT_SU';
};

export const isWick = (org: ESnapshotExists<EOrganization>) => {
  if (!org) return;
  return (
    isMontroseOrDelta(org) ||
    isGreenValleyOrSahuarita(org) ||
    isEasternArizonaOrCopperEra(org) ||
    isNogalesOrDailyTerritorial(org) ||
    isSierraVista(org) ||
    isMatSU(org)
  );
};

export const isOrganizationSetForRPA = (
  org: ESnapshotExists<EOrganization>
) => {
  if (!org) return;
  return org.data().xmlExport && org.data().xmlExport?.format.startsWith('VD_');
};

// Orgs that only run donations
export const isDonationOrg = (org: ESnapshot<EOrganization>) =>
  org &&
  org.data()?.organizationType === OrganizationType.newspaper.value &&
  State.by_value(org.data()?.state).run_donations_by_default;

// Orgs that can run donations and public notices
export const canRunDonations = (org: ESnapshot<EOrganization>) =>
  org &&
  org.data()?.organizationType === OrganizationType.newspaper.value &&
  State.by_value(org.data()?.state).can_run_donations;

export const getCSVPreview = (noticeSnap: ESnapshotExists<ENotice>) => {
  const { confirmedHtml, text } = noticeSnap.data();
  try {
    return confirmedHtml
      ? confirmedHtml
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/,/g, '')
          .replace(/\n/g, ' ')
          .replace(/&nbsp;/g, '')
          .slice(0, 100)
          .trim()
      : text
      ? text
          .replace(/,/g, '')
          .replace(/\n/g, ' ')
          .slice(0, 100)
          .trim()
      : null;
  } catch (err) {
    console.error(err.toString());
    return '';
  }
};

export const getMemoText = (
  customMemo = '',
  referenceId?: string,
  publisherId?: string,
  isReceipt?: boolean,
  country?: number
) => {
  const customHeading = '=== Notes ===';
  const boilerplateHeading = '=== How to pay this invoice ===';
  const noticeName = referenceId ? `Notice Name: ${referenceId}\n` : '';
  const publisher = publisherId ? `Order Number: ${publisherId}\n` : '';
  const customIds = `${noticeName}${publisher}`;
  const check = country === Country.GBR.value ? 'cheque' : 'check';
  const boilerplate = `We accept ACH bank transfers, debit/ credit cards, and ${check}s. If you would like to pay by ${check}, please make it out to `;

  let memo = '';
  memo += `${customHeading}\n${customMemo}\n${customIds}\n`;
  if (!isReceipt) memo += `${boilerplateHeading}\n${boilerplate}`;
  return memo;
};
