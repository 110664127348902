export default {
  USA: {
    value: 1,
    label: 'United States of America',
    label_abbrev: 'USA',
    iso: 'US',
    key: 'USA'
  },
  GBR: {
    value: 2,
    label: 'United Kingdom',
    label_abbrev: 'UK',
    iso: 'GB',
    key: 'GBR'
  }
};
