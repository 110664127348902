export enum FileType {
  WORD_DOC = 'word-doc',
  PDF = 'pdf',
  CSV = 'csv',
  TEXT = 'text',
  EXCEL = 'excel',
  TIF = 'tif',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  RTF = 'rtf',
  IDML = 'idml',
  HTML = 'html'
}

export enum Extensions {
  pdf = FileType.PDF,
  csv = FileType.CSV,
  xlsx = FileType.EXCEL,
  xls = FileType.EXCEL,
  doc = FileType.WORD_DOC,
  docx = FileType.WORD_DOC,
  txt = FileType.TEXT,
  tif = FileType.TIF,
  png = FileType.PNG,
  jpg = FileType.JPG,
  jpeg = FileType.JPEG,
  rtf = FileType.RTF
}
