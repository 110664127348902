import { call, take, select, put, takeLatest } from 'redux-saga/effects';

import { routerSelector } from 'redux/router';
import { authSelector, AuthTypes } from 'redux/auth';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import { OrganizationType, OccupationType, State } from 'lib/enums';

import pathToRegex from 'path-to-regexp';
import { organizationIsSetup } from 'routes/redirects';
import {
  includeSearchParams,
  getSubdomain,
  getHostname,
  getSubdomainOnly
} from 'utils/urls';
import { ESnapshot, EOrganization, EInvite } from 'lib/types';
import { PUBLISHER_CAN_REGISTER } from '../constants';

function* getLandingPage() {
  const auth = yield select(authSelector);
  let { user } = auth;
  const { userAuth } = auth;

  if (userAuth && !user) {
    user = (yield take(AuthTypes.SET_USER)).user;
  }
  const organization: ESnapshot<EOrganization> =
    user &&
    user.data() &&
    user.data().organization &&
    (yield call([user.data().organization, user.data().organization.get]));

  if (user) {
    if (
      organization?.data()?.organizationType ===
      OrganizationType.newspaper.value
    ) {
      if (State.by_value(organization.data().state).run_donations_by_default)
        return '/donation-campaign/';
    }

    const userData = user.data();

    const activeOrg =
      userData?.activeOrganization &&
      (yield call([
        userData.activeOrganization,
        userData.activeOrganization.get
      ]));

    return organization &&
      activeOrg?.data()?.organizationType ===
        OrganizationType.holding_company.value &&
      activeOrg?.data()?.reportUrl
      ? '/reports/'
      : '/notices/';
  }

  const subdomain = getSubdomain();

  if (['florida'].indexOf(subdomain) !== -1) {
    return '/landing/';
  }

  if ((State as any)[subdomain]) {
    return '/search/';
  }
  if (['wapo-production'].indexOf(subdomain) !== -1) {
    return '/search/';
  }

  const hostname = getHostname();
  if (['publicnoticecolorado', 'washingtonpost'].indexOf(hostname) !== -1)
    return '/search/';

  if (['localhost:3000', 'app', 'www', 'demo', 'staging'].includes(subdomain))
    return '/landing/';

  return '/login/';
}

export function* getRedirect() {
  const r = yield select(routerSelector);
  return decodeURIComponent(r.location.query.redirect || '/');
}

function* checkNeedToRedirect() {
  const { user } = yield select(authSelector);
  const { location } = yield select(routerSelector);
  if (!user) return false;

  if (location.pathname.includes('/landing')) return false;

  const userData = user.data();
  const invite: ESnapshot<EInvite> =
    userData?.invite && (yield call([userData.invite, userData.invite.get]));
  const redirectUrl = sessionStorage?.getItem('placementRedirectUrl');
  /*
    Restricting user to go to the welcome screen to select from newspaper/advertiser, while placement as anonymous user
  */
  if (
    userData &&
    redirectUrl &&
    location.pathname.includes('/register/welcome')
  ) {
    yield put(push('/register/occupations'));
    return true;
  }
  /*
    Redirect user to occupation if user has start registration as individual and not completed
  */
  if (
    userData?.occupation === OccupationType.individual.value &&
    !userData.postRegistrationComplete &&
    !location.pathname.includes('/register/organization') &&
    !userData.state
  ) {
    if (location.pathname.includes('/register/')) return false;
    yield put(push('/register/organization'));
    return true;
  }

  /*
    Redirecting user to occupations if a user is already selecting an occupation after initial registration step
  */
  if (
    userData?.occupation &&
    (location.pathname === '/register/' || location.pathname === '/register')
  ) {
    yield put(push('/register/organization'));
    return true;
  }

  if (
    userData &&
    !userData.occupation &&
    (location.pathname === '/register/' || location.pathname === '/register')
  ) {
    yield put(push('/register/welcome'));
    return true;
  }

  if (userData.emailNeedsConfirm) {
    if (location.pathname.includes('/register/confirm/')) return false;
    yield put(push('/register/confirm/'));
    return true;
  }

  if (
    !userData.occupation &&
    !PUBLISHER_CAN_REGISTER &&
    location.pathname.includes('/register/') &&
    !location.pathname.includes('/register/occupations')
  ) {
    yield put(push(includeSearchParams('/register/occupations')));
    return true;
  }

  if (
    !userData.occupation &&
    location.pathname.includes('/register/occupations')
  ) {
    if (location.pathname.includes('/register/')) return false;
    yield put(push(includeSearchParams('/register/occupations')));
    return true;
  }

  if (!userData.occupation) {
    if (location.pathname.includes('/register/')) return false;
    yield put(push(includeSearchParams('/register/welcome')));
    return true;
  }
  const needsToCompleteOrgSetupStepOne =
    userData.occupation !== OccupationType.individual.value &&
    !userData.organization;

  if (needsToCompleteOrgSetupStepOne) {
    if (location.pathname.includes('/register/')) return false;
    yield put(push('/register/organization'));
    return true;
  }

  /*
    User has been created through the invitation flow, but has not yet been formally linked to an organization
    And do not need to redirect if an existing Column user has been invited
    */
  if (userData.invite) {
    if (invite?.data()?.user) return false;
    if (location.pathname.includes('/invites/confirm-organization/'))
      return false;
    yield put(push(includeSearchParams('/invites/confirm-organization/')));
    return true;
  }

  const activeOrg =
    userData.activeOrganization &&
    (yield call([
      userData.activeOrganization,
      userData.activeOrganization.get
    ]));

  if (!activeOrg) return false;
  if (
    organizationIsSetup(userData, activeOrg.data()) &&
    (activeOrg.data().organizationType === OrganizationType.government.value ||
      activeOrg.data().organizationType === OrganizationType.law_firm.value ||
      activeOrg.data().organizationType ===
        OrganizationType.other_organization.value) &&
    activeOrg.data().postRegistrationComplete &&
    redirectUrl
  ) {
    if (location.pathname.includes('/post-registration')) return false;
    yield put(push(`/?redirect=${encodeURIComponent(redirectUrl)}`));
    sessionStorage.clear();
    return true;
  }

  if (
    organizationIsSetup(userData, activeOrg.data()) &&
    (activeOrg.data().organizationType === OrganizationType.government.value ||
      activeOrg.data().organizationType === OrganizationType.law_firm.value ||
      activeOrg.data().organizationType ===
        OrganizationType.other_organization.value ||
      activeOrg.data().organizationType === OrganizationType.newspaper.value) &&
    !activeOrg.data().postRegistrationComplete
  ) {
    if (location.pathname.includes('/post-registration')) return false;
    yield put(
      push(
        `/register/organization/post-registration?redirect=${encodeURIComponent(
          location.pathname + location.search
        )}`
      )
    );
    return true;
  }
  if (!organizationIsSetup(userData, activeOrg.data())) {
    if (location.pathname.includes('/register/organization')) return false;
    yield put(push(`/register/organization${activeOrg ? '?step=3' : ''}`));
    return true;
  }

  const alreadyRedirected = [
    '/invites/confirm-organization',
    '/register/organization',
    '/register/organization/post-registration',
    '/new-feature-onboard/advertising-deadlines'
  ].find(path => location.pathname.includes(path));
  if (alreadyRedirected) return false;
}

function* redirect(action: any) {
  const { user } = yield select(authSelector);

  if (!user) yield take([AuthTypes.SET_USER, AuthTypes.END_AUTH]);

  function* restrict() {
    const { pathname } = action.payload.location;
    const { userAuth } = yield select(authSelector);
    if (!userAuth)
      yield put(push(`/login/?redirect=${encodeURIComponent(pathname)}`));
  }

  if (yield call(checkNeedToRedirect)) return;
  const { pathname } = action.payload.location;

  const test = (path: string) => {
    return pathToRegex(path).test(pathname);
  };

  switch (true) {
    case test('/'): {
      const landingPage = yield call(getLandingPage);
      yield put(push(includeSearchParams(landingPage)));
      break;
    }

    // routes restricted for logged in users
    case test('/login/'): {
      const { userAuth } = yield select(authSelector);

      if (userAuth) {
        const redirect = yield call(getRedirect);
        yield put(push(redirect));
      }
      break;
    }

    // unrestricted routes
    case test('/search/'):
    case test('/register/'):
    case test('/landing/'):
    case test('/place/:id?'):
    case test('/rfps/:id'):
    case test('/foreclosures/:id'):
    case test('/form/:noticeType/:noticeId'):
    case test('/form/newspaper/:noticeId'):
    case test('/association/'):
    case test('/file/by-type/:type'):
    case test('/file/:id/:noticeSlug?'):
    case test('/email-action'):
    case test('/invites/:id'):
    case test('/invoices/:id/pay'):
    case test('/reset-password/'):
    case test('/public-notice/:id'): {
      break;
    }

    // restricted routes
    case test('/logout/'):
    case test('/register/welcome/'):
    case test('/register/occupations/'):
    case test('/register/organization/'):
    case test('/register/organization/post-registration/'):
    case test('/register/confirm/'):
    case test('/reports/'):
    case test('/cards/'):
    case test('/payments/'):
    case test('/temporary'):
    case test('/place_press/:bulkId?'):
    case test('/bulk/:bulkId'):
    case test('/settings/'):
    case test('/settings/organization/'):
    case test('/:path(notice|publish)/:id/'):
    case test('/:path(notice|publish)/:id/invoice/create'):
    case test('/:path(notice|publish)/:id/invoice/review'):
    case test('/:path(notice|publish)/:id/invoice/create-bulk'):
    case test('/invites/confirm-organization/'):
    case test('/error/:code'):
    case test('/cards/invoices/:id/pay'):
    case test('/donation-campaign'):
    case test('/notices/'): {
      yield call(restrict);
      break;
    }
    default: {
      yield put(push('/'));
    }
  }
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, redirect);
}
