export default {
  payment: {
    value: 1,
    label: 'Payment',
    name: 'Payment',
    key: 'payment'
  },
  dividend: {
    value: 2,
    label: 'Dividend',
    name: 'Dividend',
    key: 'dividend'
  },
  processing_fee_deduction: {
    value: 3,
    label: 'Processing Fee',
    name: 'Processing Fee',
    key: 'processing_fee_deduction'
  },
  refund: {
    value: 4,
    label: 'Refund',
    name: 'Refund',
    key: 'refund'
  },
  feeRefund: {
    value: 5,
    label: 'Refund',
    name: 'Refund',
    key: 'feeRefund'
  }
};
