import React, { Component, useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import preval from 'preval.macro';

import { STRIPE_VARS } from './constants';
import Router from './Router';
import EnoticeTheme from './Theme';
import Header from './components/Header';
import JoyRide from './components/joyrides/JoyRide';

import './App.css';

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;

type AppProps = {
  store: any;
};

type AppState = {
  stripe: any;
};

const App = ({ store }: AppProps) => {
  const [stripe, setStripe] = useState(null);

  const setStripeAccount = () => {
    if ((window as any).Stripe) {
      setStripe((window as any).Stripe(STRIPE_VARS.key));
    } else {
      const script = document.querySelector('#stripe-js');
      if (script) {
        script.addEventListener('load', () => {
          setStripe((window as any).Stripe(STRIPE_VARS.key));
        });
      } else {
        console.log('Stripe not loaded correctly');
      }
    }
  };

  useEffect(() => {
    setStripeAccount();
  }, []);

  return (
    <div className="App">
      <StripeProvider stripe={stripe}>
        <Provider store={store}>
          <EnoticeTheme>
            <Router />
          </EnoticeTheme>
          <Header />
          <JoyRide />
        </Provider>
      </StripeProvider>
    </div>
  );
};

export default App;
