import {
  all,
  take,
  takeEvery,
  call,
  put,
  select,
  race,
  fork,
  takeLatest,
  delay
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { RoleType } from 'lib/enums';
import {
  ESnapshot,
  EOrganization,
  ERef,
  EUser,
  EAnnouncement,
  FirebaseQuerySnapshot,
  FirebaseUser
} from 'lib/types';
import { push } from 'connected-react-router';
import api from 'api';
import AuthActions, { AuthTypes, authSelector } from '../redux/auth';
import ErrorActions from '../redux/errors';
import Firebase, { Collections } from '../EnoticeFirebase';
import { getLocationParams, getSubdomain, getHostname } from '../utils/urls';
import { ENV, PROD, DEMO } from '../constants';
import { getRedirect } from './RoutingSaga';

/**
 *
 * @param {snapshot} org Organization to watch
 * Firebase snapshot to watch for updates on
 */
function* watchActiveOrg(org: ESnapshot<EOrganization>) {
  if (!org) return;

  const orgChannel = eventChannel(emitter => org.ref.onSnapshot(emitter));
  // ignore the first update
  yield take(orgChannel);

  yield takeEvery(orgChannel, function* f(org: ESnapshot<EOrganization>) {
    const { user, organization } = yield select(authSelector);
    const { allowedOrganizations } = user.data();

    const childOrgsQuery = Firebase.firestore()
      .collection(Collections.organizations)
      .where('parent', '==', organization.ref);
    const childOrgSnapshot = yield call([childOrgsQuery, childOrgsQuery.get]);
    const allPotentialOrgs = [organization].concat(childOrgSnapshot.docs);

    const availableOrgs = allPotentialOrgs.filter(
      org =>
        !allowedOrganizations ||
        allowedOrganizations
          .map((o: ESnapshot<EOrganization>) => o.id)
          .includes(org.id)
    );
    yield put(AuthActions.setAvailableOrganizations(availableOrgs));
    yield put(AuthActions.setActiveOrganization(org));
  });

  yield take(AuthTypes.LOGOUT_SUCCESS);
  orgChannel.close();
}

function* listenActiveOrg() {
  while (true) {
    const auth = yield select(authSelector);
    yield race([
      call(watchActiveOrg, auth.activeOrganization),
      take(AuthTypes.SET_ACTIVE_ORGANIZATION)
    ]);
  }
}

function* fetchUser(userRef: ERef<EUser>) {
  for (let i = 0; i < 5; i++) {
    try {
      const userSnap = yield call([userRef, userRef.get]);
      if (!userSnap.exists) throw new Error('User not found');
      return userSnap;
    } catch (err) {
      if (i < 4) {
        yield delay(2000);
      }
    }
  }
  yield put(ErrorActions.setError('Unable to fetch user'));
  throw new Error('User fetch failed');
}

function* watchActiveUser(userSnapshot: ESnapshot<EUser>) {
  const userChannel = eventChannel(emitter =>
    userSnapshot.ref.onSnapshot(emitter)
  );
  yield takeEvery(userChannel, function* f(user: any) {
    yield put(AuthActions.setUser(user));
  });

  yield take(AuthTypes.LOGOUT_SUCCESS);
  userChannel.close();
}

function* setUserParameters(user: FirebaseUser) {
  try {
    if (!user) {
      return;
    }
    yield put(AuthActions.setUserAuth(user));
    const userRef = Firebase.firestore()
      .collection(Collections.users)
      .doc(user.uid) as ERef<EUser>;

    const userdataSnapshot = yield call(fetchUser, userRef);

    userdataSnapshot.ref.update({
      lastSignInTime: new Date(Date.now())
    });
    yield fork(watchActiveUser, userdataSnapshot);
    const userdata = userdataSnapshot.data() as EUser;
    if (!userdata.organization) {
      yield put(AuthActions.setActiveOrganization(null));
      return;
    }

    const organizationSnapshot = yield call([
      userdata.organization,
      userdata.organization.get
    ]);
    const activeOrgQuery = getLocationParams().get('activeOrg');
    let orgFromQuery;
    if (activeOrgQuery) {
      const ref = Firebase.firestore()
        .collection(Collections.organizations)
        .doc(activeOrgQuery);
      orgFromQuery = yield call([ref, ref.get]);

      const userCanAccessThisOrg =
        (orgFromQuery.exists && userdata.organization.id === orgFromQuery.id) ||
        (orgFromQuery.data().parent &&
          orgFromQuery.data().parent.id === userdata.organization.id);
      const isDefaultOrg = orgFromQuery.data().id === userdata.organization.id;
      if (!userCanAccessThisOrg || isDefaultOrg) orgFromQuery = null;
    }
    let orgFromUserdata;
    if (userdata.activeOrganization) {
      orgFromUserdata = yield call([
        userdata.activeOrganization,
        userdata.activeOrganization.get
      ]);
    } else {
      orgFromUserdata = null;
    }

    yield put(AuthActions.setOrganization(organizationSnapshot));
    yield put(
      AuthActions.setActiveOrganization(
        orgFromQuery || orgFromUserdata || organizationSnapshot
      )
    );

    const childOrgsQuery = Firebase.firestore()
      .collection(Collections.organizations)
      .where('parent', '==', userdata.organization);
    const childOrgSnapshot = yield call([childOrgsQuery, childOrgsQuery.get]);
    const allPotentialOrgs = [organizationSnapshot].concat(
      childOrgSnapshot.docs
    );

    const availableOrgs = allPotentialOrgs.filter(org =>
      userdata.allowedOrganizations
        ? userdata.allowedOrganizations.map(o => o.id).includes(org.id)
        : true
    );
    yield put(AuthActions.setAvailableOrganizations(availableOrgs));

    yield fork(listenActiveOrg);

    // update the claims on the user auth object in the background
    if (userdata.organization) {
      yield call([api, api.post], 'users/grant-claim');
      user.getIdToken(true);
    }
  } catch (err) {
    console.error(err);
  } finally {
    const { user, userAuth } = yield select(authSelector);

    if (userAuth && !user) yield take(AuthTypes.SET_USER);

    yield put(AuthActions.endAuth());
  }
}

function* register() {
  yield call(setUserParameters, Firebase.auth().currentUser);
}

function* loginTokenFlow({ token }: { token: any }) {
  yield put(AuthActions.startAuth());
  yield call([Firebase.auth(), Firebase.auth().signInWithCustomToken], token);
  yield put(push(yield getRedirect()));
}

function* loginFlow({ email, password }: { password: string; email: string }) {
  try {
    yield put(AuthActions.startAuth());

    yield call(
      [Firebase.auth(), Firebase.auth().signInWithEmailAndPassword],
      email,
      password
    );

    const placementRedirectUrl = sessionStorage?.getItem(
      'placementRedirectUrl'
    );
    if (placementRedirectUrl)
      yield put(push(`/?redirect=${encodeURIComponent(placementRedirectUrl)}`));
    yield put(push(yield getRedirect()));

    yield call([api, api.post], 'notifications/slack', {
      message: `New login from ${email}`
    });
  } catch (err) {
    yield put(AuthActions.setAuthError(err.message));
  }
}

function* listenOnAuth() {
  const shouldDisplayChat = ENV === PROD || ENV === DEMO;
  const authChannel = eventChannel(emitter =>
    Firebase.auth().onAuthStateChanged(authState => {
      if (authState) {
        emitter(authState);

        if (ENV === PROD || ENV === DEMO) {
          Sentry.configureScope(scope =>
            scope.setUser({
              email: authState.email
            })
          );
        }

        if (shouldDisplayChat) {
          (window as any).Beacon('identify', {
            name: authState.displayName,
            email: authState.email,
            PhoneNumber: authState.phoneNumber
          });
          (window as any).Beacon('prefill', {
            name: authState.displayName,
            email: authState.email
          });
        }

        LogRocket.identify(authState.uid, {
          name: authState.displayName,
          email: authState.email,
          enviornment: ENV
        });
      } else {
        emitter(false);
      }
    })
  );
  yield takeEvery(authChannel, setUserParameters);
}

function* logoutFlow() {
  yield put(AuthActions.startAuth());
  yield call([Firebase.auth(), Firebase.auth().signOut]);
  yield put(AuthActions.logoutSuccess());
  yield put(push('/'));
}

/**
 * Sets the organization context from the current subdomain or hostname
 * or, if one exists, from the current active organization
 */
const getContextKey = () => {
  const hostname = getHostname();
  if (['publicnoticecolorado'].indexOf(hostname) !== -1) return hostname;
  return getSubdomain();
};

function* getOrgContext() {
  const contextKey = getContextKey();
  const orgQuery = Firebase.firestore()
    .collection(Collections.organizations)
    .where('subdomain', '==', contextKey);

  const childOrgSnapshot = yield call([orgQuery, orgQuery.get]);

  if (childOrgSnapshot.docs.length) {
    const orgContext = childOrgSnapshot.docs[0];
    yield put(AuthActions.setOrgContext(orgContext));
  } else {
    const { activeOrganization } = yield take(
      AuthTypes.SET_ACTIVE_ORGANIZATION
    );
    if (activeOrganization) {
      yield put(AuthActions.setOrgContext(activeOrganization));
    }
  }
}

function* updateActiveOrganization({
  activeOrganization
}: {
  activeOrganization: ESnapshot<EOrganization>;
}) {
  try {
    if (activeOrganization) {
      const { user } = yield select(authSelector);
      user.ref.update({
        activeOrganization: activeOrganization.ref
      });
      yield call(getOrgContext);
    }
  } catch (err) {
    console.error(err);
  }
}

function* updatePermissions() {
  const { activeOrganization, user } = yield select(authSelector);
  if (!activeOrganization || !user) return;
  const updatedPermissions = {
    billing: user.data().role === RoleType.billing.value,
    admin:
      user.data().role === RoleType.admin.value ||
      user.id === activeOrganization.data().createdBy,
    user: user.data().role === RoleType.user.value,
    super: user.data().role === RoleType.super.value
  };

  yield put(AuthActions.setPermissions(updatedPermissions));
}

/* Announcement viewers can be specified by:
   list of users || occupation || state
*/
export const extractRelevantAnnouncements = async (
  user: ESnapshot<EUser>,
  announcements: ESnapshot<EAnnouncement>[]
) => {
  const results = await Promise.all(
    announcements.map(async announcement => {
      const { start, occupation, state, users } = announcement.data();

      // Only refer to the user list if one exists on the announcement
      if (users?.length > 0) {
        if (users.map(item => item.id).indexOf(user.ref.id) === -1) return null;
      } else {
        if (start.toDate() > new Date()) return null;

        if (occupation && occupation !== user.data().occupation) return null;

        if (state && state !== user.data().state) return null;
      }
      const existingAnnouncement = await Firebase.firestore()
        .collection(Collections.announcements)
        .doc(announcement.id)
        .collection(Collections.users)
        .doc(user.id)
        .get();

      if (existingAnnouncement.exists) return null;

      return announcement;
    })
  );

  return results.filter(Boolean);
};

export function* getAnnouncement({ user }: { user: ESnapshot<EUser> }) {
  const announcementQuery = Firebase.firestore()
    .collection(Collections.announcements)
    .where('end', '>', new Date());

  const announcementQueryResults = yield call([
    announcementQuery,
    announcementQuery.get
  ]);

  const announcementChannel = eventChannel(emitter =>
    announcementQueryResults.query.onSnapshot(emitter)
  );

  while (true) {
    const { announcementQuery } = yield race({
      announcementQuery: take(announcementChannel),
      logout: take(AuthTypes.LOGOUT)
    }) as { announcementQuery?: FirebaseQuerySnapshot };
    if (!announcementQuery) break;

    const announcements = announcementQuery.docs as ESnapshot<EAnnouncement>[];

    const [announcement] = yield call(
      extractRelevantAnnouncements,
      user,
      announcements
    );
    if (announcement) yield put(AuthActions.setAnnouncement(announcement));
  }
}

function* markAnnouncementRead() {
  const { user, announcement } = yield select(authSelector);

  if (!announcement) return;

  yield put(AuthActions.setAnnouncement(null));

  const markRead = async () => {
    await Firebase.firestore()
      .collection(Collections.announcements)
      .doc(announcement.id)
      .collection(Collections.users)
      .doc(user.id)
      .set({
        seenAt: new Date()
      });
  };
  yield call(markRead);
}

export default function* root() {
  yield fork(getOrgContext);
  yield fork(listenOnAuth);
  yield all([
    takeEvery(AuthTypes.REGISTER, register),
    takeEvery(AuthTypes.LOGIN, loginFlow),
    takeEvery(AuthTypes.LOGIN_TOKEN, loginTokenFlow),
    takeEvery(AuthTypes.LOGOUT, logoutFlow),
    takeLatest(
      [AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION],
      updatePermissions
    ),
    takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, updateActiveOrganization),
    takeEvery(AuthTypes.SET_USER, getAnnouncement),
    takeEvery(AuthTypes.SET_USER, getOrgContext),
    takeEvery(AuthTypes.MARK_ANNOUNCEMENT_READ, markAnnouncementRead)
  ]);
}
