import React, { ReactElement } from 'react';
import { CloseIcon } from 'icons';

const FreeformCModal: React.FC<{
  setOpen?: Function;
  header: string | React.ReactNode;
  body?: any;
  children: ReactElement;
  id?: string;
  noExitOutsideModal?: boolean;
  afterExit?: Function;
  width?: string;
  customStyle?: string;
  maxWidth?: string;
  disableOverflow?: boolean;
}> = ({
  setOpen,
  header,
  body,
  children,
  id,
  noExitOutsideModal,
  afterExit,
  width,
  customStyle,
  maxWidth,
  disableOverflow
}) => {
  const handleExit = () => {
    if (noExitOutsideModal) return;
    setOpen && setOpen(false);
  };
  return (
    <div
      id={id}
      className={`fixed z-100 inset-0 ${
        !disableOverflow ? 'overflow-y-auto' : ''
      }`}
    >
      <div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" onClick={handleExit}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${maxWidth ||
            'sm:max-w-lg'} ${!!width && width} sm:w-full sm:p-6`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          id={`${id}-inner`}
        >
          {setOpen && (
            <div className="absolute top-0 right-0 pt-5 md:pt-6 pr-4">
              <button
                type="button"
                onClick={_e => {
                  setOpen(false);
                  afterExit && afterExit();
                }}
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Close</span>
                <CloseIcon />
              </button>
            </div>
          )}
          <div className="sm:flex sm:items-start">
            <div className={`sm:mt-0 sm:mx-4 text-left ${customStyle}`}>
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {header}
              </h3>
              <div className="mt-2">
                <span className="text-sm font-medium leading-5 text-gray-700">
                  {body}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-3 sm:mt-0 sm:mx-4 text-left">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FreeformCModal;
