import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { guidGenerator } from 'lib/helpers';
import { BellIcon, CheckOutlinedIcon } from 'icons';
import { ESnapshot, EUser, ENotification, ESnapshotExists } from 'lib/types';
import Firebase, { Collections } from '../EnoticeFirebase';

type NotificationProps = {
  user: ESnapshot<EUser>;
  push: Push;
  open: boolean;
  setOpen: Function;
};
const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path))
});

const NotificationDropdown: React.FC<NotificationProps> = ({
  user,
  push,
  open,
  setOpen
}) => {
  const [notifications, setNotifications] = useState<
    ESnapshotExists<ENotification>[]
  >([]);

  const userNotificationQuery = Firebase.firestore()
    .collection(Collections.notifications)
    .where('user', '==', user.ref)
    .where('inApp.read', '==', false);

  const loadRecentNotifications = async () => {
    const recentNotifications = await userNotificationQuery.limit(100).get();
    setNotifications(
      (recentNotifications.docs as unknown) as ESnapshotExists<ENotification>[]
    );
  };

  useEffect(() => {
    loadRecentNotifications();
  }, []);

  const [clearingNotifications, setClearingNotifications] = useState(false);

  const getNotifications = () =>
    notifications.slice(0, 4).map(docSnapshot => {
      const { notice, inApp } = docSnapshot.data();

      const link =
        (inApp && inApp.link) || (notice && `/notice/${notice.id}`) || '';

      return (
        <div
          key={guidGenerator()}
          className="w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer"
          onClick={async () => {
            const updateObj = { ...inApp };
            updateObj.read = true;
            if (docSnapshot) await docSnapshot.ref.update({ inApp: updateObj });
            setOpen(false);
            push(link);
            loadRecentNotifications();
          }}
        >
          {inApp.text}
        </div>
      );
    });

  const clearAllNotifications = () => (
    <div
      key={guidGenerator()}
      className="w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer"
      onClick={async (e: any) => {
        e.preventDefault();
        setClearingNotifications(true);
        const userNotificationQuerySnapshot = await userNotificationQuery.get();
        const notifications = userNotificationQuerySnapshot.docs;
        setOpen(!open);
        await Promise.all(
          notifications.map(async n => {
            const { inApp } = n.data();
            const updateObj = { ...inApp };
            updateObj.read = true;
            await n.ref.update({ inApp: updateObj });
          })
        );
        setClearingNotifications(false);
        loadRecentNotifications();
      }}
    >
      <CheckOutlinedIcon className="inline-block w-5 h-5 align-center pb-0.25" />
      &nbsp;&nbsp;Clear All Notifications
    </div>
  );

  return (
    <>
      <div className="relative">
        <div>
          <button
            className="inline-block relative bg-white p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setOpen(!open)}
          >
            <span className="sr-only">View notifications</span>
            <BellIcon />
            {notifications.length && !clearingNotifications ? (
              <span
                className={`pt-0.25 absolute top-0 right-0 block h-4 w-4 rounded-circle bg-yellow-350 items-center justify-center text-gray-750 font-bold text-xxs -mt-1 -mr-1 ${notifications.length >
                  99 && 'w-6'}`}
              >
                {notifications.length > 99 ? '99+' : notifications.length}
              </span>
            ) : clearingNotifications ? (
              <div className="loader-yellow absolute ease-linear rounded-full border-4 border-t-4 border-gray-200 w-3 h-3 top-0 right-0" />
            ) : (
              <></>
            )}
          </button>
        </div>
        <div
          className={`${!open &&
            'hidden'} w-max border origin-top-right absolute right-0 mt-2 min-w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {notifications.length === 0 ? (
            <div className="block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer w-full">
              No new notifications
            </div>
          ) : (
            <>
              {getNotifications()}
              {clearAllNotifications()}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, mapDispatchToProps)(NotificationDropdown);
