import { Collections } from '../constants';
import {
  EUser,
  ESnapshot,
  EOrganization,
  ESnapshotExists,
  Customer,
  CustomerOrganization
} from '../types';
import { ENotice } from '../types/notice';
import OccupationType from '../enums/OccupationType';
import { invoicedOutsideColumnForNoticeType } from '../helpers';

// Import firebase
let engine: any;
try {
  const firebaseAdmin = 'firebase-admin';
  // eslint-disable-next-line import/no-dynamic-require
  engine = require(firebaseAdmin);
} catch (err) {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    engine = require('firebase').default.app();
  } catch (err) {
    // Used in frontend tests
    // eslint-disable-next-line import/no-dynamic-require
    engine = require('firebase').default;
  }
}

export const getOrCreateCustomer = async (
  user: ESnapshot<EUser>,
  organization: ESnapshot<EOrganization>,
  info: Record<string, any> | null = null
) => {
  const existingCustomerQuery = await engine
    .firestore()
    .collection('customers')
    .where('user', '==', user.ref)
    .where('organization', '==', organization.ref)
    .get();

  let customer;
  let organizationName;

  const userOrg = (await user.data()?.organization?.get()) as
    | ESnapshotExists<EOrganization>
    | undefined;

  if (user.data()!.organizationName) {
    organizationName = user.data()?.organizationName!;
  } else if (
    user.data()?.occupation !== OccupationType.individual.value &&
    userOrg
  ) {
    organizationName = userOrg?.data()?.name!;
  }

  if (existingCustomerQuery.docs.length) {
    customer = (existingCustomerQuery.docs[0] as unknown) as ESnapshotExists<
      Customer
    >;

    const updateObject = {
      ...(user?.data()?.name !== customer.data().userName && {
        userName: user.data()?.name
      }),
      ...(organizationName &&
        organizationName !== customer.data().organizationName && {
          organizationName
        })
    };

    if (Object.keys(updateObject).length) {
      await customer.ref.update(updateObject);
    }
  } else {
    const customerRef = await engine
      .firestore()
      .collection('customers')
      .add({
        organization: organization.ref,
        user: user.ref,
        internalID: '',
        userName: user.data()?.name,
        ...(organizationName && { organizationName })
      } as Customer);
    customer = ((await customerRef.get()) as unknown) as ESnapshotExists<
      Customer
    >;
  }

  if (info) {
    customer.ref.update({ ...info });
  }
  return customer as ESnapshotExists<Customer>;
};

export const getOrCreateCustomerOrganization = async (
  client: ESnapshot<EOrganization>,
  organization: ESnapshot<EOrganization>
) => {
  const existingCustomerOrgQuery = await engine
    .firestore()
    .collection(Collections.customerOrganizations)
    .where('client', '==', client.ref)
    .where('organization', '==', organization.ref)
    .get();

  let customerOrganization;
  if (existingCustomerOrgQuery.docs.length) {
    customerOrganization = (existingCustomerOrgQuery
      .docs[0] as unknown) as ESnapshotExists<CustomerOrganization>;
  } else {
    const customerOrgRef = await engine
      .firestore()
      .collection(Collections.customerOrganizations)
      .add({
        organization: organization.ref,
        client: client.ref
      } as CustomerOrganization);
    customerOrganization = ((await customerOrgRef.get()) as unknown) as ESnapshotExists<
      CustomerOrganization
    >;
  }

  return customerOrganization as ESnapshotExists<CustomerOrganization>;
};

export const getOrCreateCustomerOrganizationForNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const { filedBy, newspaper } = notice.data();
  if (!filedBy) return null;
  const clientOrgSnapshot = await filedBy.get();
  const newspaperSnapshot = await newspaper.get();
  return getOrCreateCustomerOrganization(clientOrgSnapshot, newspaperSnapshot);
};

export const getShouldInvoiceCustomerOutsideColumn = async (
  customer: ESnapshotExists<Customer>
) => {
  const user = await customer.data().user.get();
  if (user.data()?.invoiceOutsideColumn) return true;

  if (customer.data().invoicedOutsideColumn) return true;

  const userOrg = await user.data()?.organization?.get();
  if (userOrg?.data()?.invoiceOutsideColumn) {
    return true;
  }

  return false;
};

export const isNoticeInvoicedOutsideColumn = async (
  notice: ESnapshotExists<ENotice>
) => {
  try {
    if (await invoicedOutsideColumnForNoticeType(notice)) return true;
    const customer = await getOrCreateCustomer(
      await notice.data().filer.get(),
      await notice.data().newspaper.get()
    );
    return getShouldInvoiceCustomerOutsideColumn(customer);
  } catch (err) {
    console.log(`Error getting customer for notice: ${notice.id}`);
    return false;
  }
};
