import firebase from 'firebase';

export interface ERef<T>
  extends Pick<firebase.firestore.DocumentReference, 'id' | 'collection'> {
  update(update: Partial<T>): Promise<any>;
  create?(update: Partial<T>): Promise<any>;
  set(update: Partial<T>): Promise<any>;
  get(): Promise<ESnapshot<T>>;
  delete(): Promise<any>;
  onSnapshot(onNext: (snapshot: ESnapshot<T>) => void): () => void;
  parent: firebase.firestore.CollectionReference;
}

interface ESnapshotBase<T>
  extends Pick<firebase.firestore.DocumentSnapshot, 'id'> {
  ref: ERef<T>;
  proto?: {
    createTime: string;
    updateTime: string;
  };
}

export interface ESnapshotExists<T> extends ESnapshotBase<T> {
  exists: true;
  data(): T;
}

interface ESnapshotNotExists<T> extends ESnapshotBase<T> {
  exists: false;
  data(): undefined;
}

export interface EQuerySnapshot<T> {
  [x: string]: any;
  empty: boolean;
  docs: ESnapshot<T>[];
}

export type ESnapshot<T> = ESnapshotExists<T> | ESnapshotNotExists<T>;

export const autoBot = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) =>
    snap.data() as T
});
