/* tslint:disable:no-invalid-this */
export interface EnumItemType {
  abbrev: string;
  value: number;
  key: string;
  label: string;
  logical?: string;
  plural?: string;
  parentEnum: any;
  template: string;
  symbol?: string;
}

const EnumItemPrototype = {
  child_item_keys: [] as any[],
  parentEnum: {} as any,

  children() {
    if (!this.child_item_keys) {
      return [];
    }
    const children = this.child_item_keys.map(childValue => {
      return this.parentEnum[childValue];
    });
    return children;
  }
};

export default class Enum {
  public _items: any = {};

  public _items_by_value: any;

  public _items_by_label: any;

  public _items_cache: any;

  constructor(items: any) {
    const extendedItems: any = {};

    Object.keys(items).forEach(key => {
      let extended = (extendedItems[key] = Object.create(
        EnumItemPrototype
      )) as EnumItemType;
      extended = Object.assign(extended, items[key]);
      extended.parentEnum = this;
    });

    Object.assign(this, extendedItems);
    this._items = extendedItems;
  }

  public by_value(val: any) {
    if (!this._items_by_value) {
      this._items_by_value = {};
      this.items().forEach(
        (item: any) => (this._items_by_value[item.value] = item)
      );
    }
    return this._items_by_value[parseInt(val, 10)];
  }

  public by_label(label: string) {
    if (!this._items_by_label) {
      this._items_by_label = {};
      this.items().forEach(
        (item: any) => (this._items_by_label[item.label] = item)
      );
    }
    return this._items_by_label[label];
  }

  public items() {
    if (!this._items_cache) {
      this._items_cache = Object.keys(this._items)
        .map(key => this._items[key])
        .filter(value => value.value);
    }
    return this._items_cache as EnumItemType[];
  }

  public rootItems() {
    if (!this._items_cache) {
      this._items_cache = Object.keys(this._items)
        .map(key => this._items[key])
        .filter(value => value.value);
    }
    return this._items_cache.filter((item: any) => item.is_root_item);
  }
}
