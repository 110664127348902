/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { push, Push, RouterState } from 'connected-react-router';
import { QuestionMarkRoundedIcon, CogIcon, AccountCircleIcon } from 'icons';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { RoleType } from 'lib/enums';
import { isDonationOrg, canRunDonations } from 'lib/publishers';
import { getSubdomain } from 'utils/urls';
import Country from 'lib/enums/Country';
import { useTranslation } from 'react-i18next';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { autoBot } from 'lib/types/firebase';
import SwitchToChrome from '../components/SwitchToChrome';
import ActiveOrganizationSelect from './ActiveOrganizationSelect';
import {
  ESnapshot,
  EUser,
  EOrganization,
  FirebaseUser,
  ESnapshotExists,
  EDonationProfile
} from '../lib/types';
import NotificationDropdown from '../components/NotificationDropdown';
import Survey from '../routes/settings/publisher/Survey';

const mapStateToProps = (state: any) => ({
  activeOrganization: state.auth.activeOrganization,
  organization: state.auth.organization,
  orgContext: state.auth.orgContext,
  user: state.auth.user,
  userAuth: state.auth.userAuth,
  location: (state.router as RouterState<any>).location,
  showAllOrgsNotices: state.auth.showAllOrgsNotices
});

type FrameProps = {
  userAuth?: FirebaseUser;
  organization?: ESnapshot<EOrganization>;
  orgContext?: ESnapshot<EOrganization>;
  user?: ESnapshot<EUser>;
  noFramePadding: boolean;
  location?: any;
  activeOrganization: ESnapshot<EOrganization>;
  push: Push;
  showAllOrgsNotices: boolean;
  children: any;
};

const COLUMN_LOGO_URL =
  'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2FColumn_Logo_Blue-08%20(1).png?alt=media&token=9c3821a8-d4be-401c-af9b-e0e2c0d8e328';

const isCurrentlyRunningDonations = async (
  activeOrganization: ESnapshot<EOrganization>
) => {
  if (
    !(isDonationOrg(activeOrganization) || canRunDonations(activeOrganization))
  ) {
    return false;
  }
  const donationProfileQuery = await Firebase.firestore()
    .collection(Collections.donationProfiles)
    .where('organization', '==', activeOrganization.ref)
    .withConverter<EDonationProfile>(autoBot())
    .limit(1)
    .get();

  if (donationProfileQuery.empty) return false;
  const [profile] = donationProfileQuery.docs;

  if (!profile.data().legalDocs) return false;

  return true;
};

const Frame: React.FC<FrameProps> = ({
  userAuth,
  organization,
  orgContext,
  user,
  noFramePadding,
  location,
  activeOrganization,
  push,
  showAllOrgsNotices,
  children
}) => {
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [isActiveOrgSelectOpen, setIsActiveOrgSelectOpen] = useState(false);
  const [frameLogoURL, setFrameLogoURL] = useState(COLUMN_LOGO_URL);
  const [showDonationTab, setShowDonationTab] = useState(false);
  const pathName = window.location.pathname;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      if (!activeOrganization?.data()) return;

      // Future TODO: we should have a language toggle
      if (activeOrganization?.data().country === Country.GBR.value) {
        i18n.changeLanguage('en-GB');
      }

      if (await isCurrentlyRunningDonations(activeOrganization)) {
        setShowDonationTab(true);
      }
    })();
  }, [activeOrganization?.id]);

  useEffect(() => {
    const getFrameUrl = async () => {
      if (window.location.href.includes('florida')) return '/FPF-color.png';

      const subdomainSnapshot = await Firebase.firestore()
        .collection(Collections.organizations)
        .where('subdomain', '==', getSubdomain())
        .get();

      if (!subdomainSnapshot.docs.length) {
        return;
      }

      const subdomainOrg = subdomainSnapshot.docs[0];

      if (
        subdomainOrg.data().filingFlowSubdomainImage &&
        getSubdomain() === subdomainOrg.data().subdomain
      ) {
        setFrameLogoURL((subdomainOrg as any).data().filingFlowSubdomainImage);
      }
    };
    getFrameUrl();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-80">
      {user && user.data() && user.data().role === RoleType.super.value && (
        <div className="flex justify-center items-center bg-yellow-500 z-20">
          <div className="font-semibold">SUPERUSER</div>
        </div>
      )}
      <nav className="bg-white shadow z-20">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {/* <!-- Icon when menu is closed. --> */}
                {/* <!--
                  Heroicon name: menu
      
                  Menu open: "hidden", Menu closed: "block"
                --> */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* <!-- Icon when menu is open. -->
                <!--
                  Heroicon name: x
      
                  Menu open: "block", Menu closed: "hidden"
                --> */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="md:flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
              <div className="flex-shrink-0 flex items-center">
                {frameLogoURL && (
                  <div
                    onClick={() => {
                      user ? push('/') : push('/login');
                    }}
                  >
                    <img
                      className={`object-cover object-left h-5 md:object-contain md:object-left -mt-1 cursor-pointer`}
                      src={showAllOrgsNotices ? COLUMN_LOGO_URL : frameLogoURL}
                      alt="Company name"
                    />
                  </div>
                )}
                {location && !location.pathname.includes('search') && (
                  <ActiveOrganizationSelect
                    open={isActiveOrgSelectOpen}
                    setOpen={(value: boolean) => {
                      setIsActiveOrgSelectOpen(value);
                      if (value) {
                        setIsHelpMenuOpen(false);
                        setIsSettingsMenuOpen(false);
                        setIsNotificationMenuOpen(false);
                      }
                    }}
                  />
                )}

                <Survey
                  fromMainView
                  user={user}
                  activeOrganization={activeOrganization}
                />
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8 mr-2">
                {userAuth && (
                  <div
                    className={`inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes(
                      '/notices'
                    ) && 'border-blue-600 text-gray-900'}`}
                    onClick={() => push('/')}
                  >
                    My Notices
                  </div>
                )}
                {showDonationTab && (
                  <div
                    className={`inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes(
                      '/donation-campaign'
                    ) && 'border-blue-600 text-gray-900'}`}
                    onClick={() => push('/donation-campaign')}
                  >
                    Donation Campaign
                  </div>
                )}
                {user?.data().card && (
                  <div
                    className={`inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes(
                      '/cards'
                    ) && 'border-blue-600 text-gray-900'}`}
                    onClick={() => push('/cards')}
                  >
                    Cards
                  </div>
                )}
                {user?.data().bulkPaymentEnabled && (
                  <div
                    className={`inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer ${pathName.includes(
                      '/payments'
                    ) && 'border-blue-600 text-gray-900'}`}
                    onClick={() => push('/payments')}
                  >
                    Payments
                  </div>
                )}
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {user && (
                <NotificationDropdown
                  user={user}
                  open={isNotificationMenuOpen}
                  setOpen={(value: boolean) => {
                    setIsNotificationMenuOpen(value);
                    if (value) {
                      setIsHelpMenuOpen(false);
                      setIsSettingsMenuOpen(false);
                    }
                  }}
                />
              )}

              <div className="relative">
                <div>
                  <button
                    id="help-bar-icon"
                    data-testid="help-bar-icon"
                    className="bg-white mx-3 p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      setIsHelpMenuOpen(!isHelpMenuOpen);
                      setIsSettingsMenuOpen(false);
                      setIsNotificationMenuOpen(false);
                      setIsActiveOrgSelectOpen(false);
                    }}
                  >
                    <QuestionMarkRoundedIcon />
                  </button>
                </div>
                <div
                  className={`${!isHelpMenuOpen &&
                    'hidden'} z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                  data-testid="help-menu-dropdown"
                >
                  <div
                    className="block px-4 py-2 text-sm text-gray-750 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      window.open('https://help.column.us');
                    }}
                  >
                    Visit Help Center
                  </div>
                </div>
              </div>

              <div className="relative">
                <Tooltip
                  title={
                    showAllOrgsNotices
                      ? 'Select a specific organization to see settings'
                      : ''
                  }
                  disableHoverListener={!showAllOrgsNotices}
                >
                  <div>
                    <button
                      id="settings-icon"
                      className={`bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${showAllOrgsNotices &&
                        'opacity-50'}`}
                      onClick={() => {
                        setIsSettingsMenuOpen(!isSettingsMenuOpen);
                        setIsHelpMenuOpen(false);
                        setIsNotificationMenuOpen(false);
                        setIsActiveOrgSelectOpen(false);
                      }}
                      disabled={showAllOrgsNotices}
                    >
                      {userAuth ? (
                        <CogIcon className="text-white hover:text-gray-200" />
                      ) : (
                        <AccountCircleIcon className="text-gray-400 hover:text-gray-500" />
                      )}
                    </button>
                  </div>
                </Tooltip>
                <div
                  className={`${!isSettingsMenuOpen &&
                    'hidden'} z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  {userAuth && (
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        push('/settings/');
                      }}
                    >
                      My Account
                    </div>
                  )}

                  {organization && (
                    <div
                      id="my-organization"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        push('/settings/organization');
                      }}
                    >
                      {t('frame.org-settings')}
                    </div>
                  )}
                  {userAuth && (
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        push('/logout');
                      }}
                    >
                      Logout
                    </div>
                  )}
                  {!userAuth && (
                    <div>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          push('/login');
                        }}
                      >
                        Sign In
                      </div>

                      {organization && (
                        <div
                          id="my-organization"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            push('/settings/organization');
                          }}
                        >
                          {t('frame.org-settings')}
                        </div>
                      )}
                      {userAuth && (
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            push('/register');
                          }}
                        >
                          Register
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--
          Mobile menu, toggle classes based on menu state.
      
          Menu open: "block", Menu closed: "hidden"
        --> */}
        <div className="hidden sm:hidden">
          <div className="pt-2 pb-4 space-y-1">
            {(isDonationOrg(activeOrganization) ||
              canRunDonations(activeOrganization)) && (
              <div
                className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50"
                onClick={() => push('/donation-campaign')}
              >
                Donation Campaign
              </div>
            )}
            <a
              href="#"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300"
            >
              Team
            </a>
          </div>
        </div>
      </nav>
      <SwitchToChrome hideOnTheseRoutes={['/search']} />
      <main
        id="main"
        className={
          noFramePadding
            ? 'fixed min-w-full bg-gray-80 h-full'
            : 'flex-grow p-3 bg-gray-80 h-full overflow-x-scroll'
        }
        onClick={() => {
          setIsNotificationMenuOpen(false);
          setIsSettingsMenuOpen(false);
          setIsHelpMenuOpen(false);
          setIsActiveOrgSelectOpen(false);
        }}
      >
        {children}
      </main>
      <footer
        id="appfooter"
        className={`${!pathName.includes('/notices') &&
          'hidden'} text-gray-700 pb-3 text-center bg-gray-80`}
      >
        <div>
          <span
            onClick={() =>
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/Terms%20of%20Use.pdf?alt=media&token=7c8fd6e4-c71b-4602-8f48-ed7948bbeea2'
              )
            }
            className="cursor-pointer"
          >
            Terms of Use
          </span>{' '}
          |{' '}
          <span
            onClick={() =>
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Flegal%2Fcolumn%2FColumn%20Privacy%20Policy.pdf?alt=media&token=4a43d827-8b64-4e2f-a175-04d426c2cfcd'
              )
            }
            className="cursor-pointer"
          >
            Privacy Policy
          </span>{' '}
          | <span>help@column.us</span> |{' '}
          <span
            onClick={() => window.open('https://www.column.us')}
            className="cursor-pointer"
          >
            column.us
          </span>
        </div>
      </footer>
    </div>
  );
};
export default connect(mapStateToProps, { push })(Frame);
