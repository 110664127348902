import { Switch, Route } from 'react-router';

import React, { lazy, Suspense } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import LoadingState from './components/LoadingState';
import WithFrame from './routes/WithFrame';
import SuspenseErrorBoundary from './components/SuspenseErrorBoundary';
import history from './customHistory';

const retry: any = (
  fn: Function,
  retriesLeft: any = 5,
  interval: any = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(async (error: Error) => {
        await new Promise(resolve => setTimeout(resolve, interval));
        if (retriesLeft === 1) {
          return window.location.reload(true);
        }
        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
  });
};
const Reports = lazy(() => retry(() => import('./routes/reports')));

const RFP = lazy(() => retry(() => import('./routes/rfps')));

const Foreclosure = lazy(() => retry(() => import('./routes/foreclosures')));

const OrganizationSettings = lazy(() =>
  retry(() => import('./routes/settings/OrganizationSettings'))
);
const Typeform = lazy(() => retry(() => import('./routes/typeform')));
const NewspaperTypeForm = lazy(() =>
  retry(() => import('./routes/typeform/newspaper'))
);

const RegisterOrganization = lazy(() =>
  retry(() => import('./routes/register/organization'))
);
const PostOrgRegistration = lazy(() =>
  retry(() => import('./routes/register/organization/PostOrgRegistration'))
);
const ConfirmOrganization = lazy(() =>
  retry(() => import('./routes/invites/ConfirmOrganization'))
);

const ColumnCards = lazy(() => retry(() => import('./routes/cards')));

const Payments = lazy(() => retry(() => import('./routes/payments')));

const EmailAction = lazy(() =>
  retry(() => import('./routes/redirects/EmailAction'))
);
const AddNoticeWrapper = lazy(() => retry(() => import('./routes/addNotice')));
const UserNotices = lazy(() => retry(() => import('./routes/userNoticeList')));
const Settings = lazy(() => retry(() => import('./routes/settings')));
const Register = lazy(() => retry(() => import('./routes/register/user')));
const Welcome = lazy(() => retry(() => import('./routes/register/welcome')));
const Occupation = lazy(() =>
  retry(() => import('./routes/register/occupations'))
);
const Invitation = lazy(() =>
  retry(() => import('./routes/invites/invitation'))
);
const Login = lazy(() => retry(() => import('./routes/login')));
const Logout = lazy(() => retry(() => import('./routes/logout')));
const ErrorPage = lazy(() => retry(() => import('./routes/errors/ErrorPage')));
const NewspaperSettings = lazy(() =>
  retry(() => import('./routes/settings/NewspaperSettings'))
);
const NoticeDetail = lazy(() => retry(() => import('./routes/notice')));
const CreateInvoice = lazy(() =>
  retry(() => import('./routes/notice/billing/CreateInvoice'))
);

const CreateBulkInvoice = lazy(() =>
  retry(() => import('./routes/notice/billing/CreateBulkInvoice'))
);

const ReviewInvoice = lazy(() =>
  retry(() => import('./routes/notice/billing/ReviewInvoice'))
);

const EmailNeedsConfirm = lazy(() =>
  retry(() => import('./routes/register/user/EmailNeedsConfirm'))
);
const PayInvoice = lazy(() =>
  retry(() => import('./routes/notice/billing/payInvoice/PayInvoice'))
);
const PlaceScroll = lazy(() => import('./routes/placeScroll'));
const Landing = lazy(() => import('./routes/landing'));
const DonationCampaign = lazy(() => import('./routes/donationCampaign'));
const ResetPassword = lazy(() => import('./routes/resetPassword/index'));

type EnoticeRouterProps = {};

const EnoticeRouter: React.FC<EnoticeRouterProps> = () => {
  return (
    <ConnectedRouter history={history}>
      <SuspenseErrorBoundary>
        <Suspense fallback={<LoadingState />}>
          <Switch>
            <WithFrame exact path="/rfps/:id/" hideFrame component={RFP} />
            <WithFrame
              exact
              path="/foreclosures/:id/"
              hideFrame
              component={Foreclosure}
            />
            <WithFrame exact path="/login/" component={Login} />
            <WithFrame exact path="/logout/" component={Logout} />
            <WithFrame exact path="/reports/" component={Reports} />
            <WithFrame
              exact
              path="/place/"
              noFramePadding
              component={PlaceScroll}
            />
            <WithFrame
              exact
              path="/place/:id/"
              noFramePadding
              component={PlaceScroll}
            />
            <WithFrame hideFrame exact path="/register/" component={Register} />
            <WithFrame
              exact
              noFramePadding
              hideFrame
              path="/register/welcome"
              component={Welcome}
            />
            <WithFrame
              exact
              noFramePadding
              hideFrame
              path="/register/occupations"
              component={Occupation}
            />
            <WithFrame exact path="/email-action" component={EmailAction} />
            <WithFrame
              exact
              path="/register/organization/post-registration/"
              component={PostOrgRegistration}
            />
            <WithFrame
              exact
              hideFrame
              path="/register/organization/"
              component={RegisterOrganization}
            />
            <WithFrame
              exact
              path="/register/confirm/"
              noFramePadding
              component={EmailNeedsConfirm}
            />
            <WithFrame exact path="/temporary" component={NewspaperSettings} />
            <WithFrame exact path="/settings/" component={Settings} />
            <WithFrame
              exact
              path="/form/newspaper/:noticeId"
              component={NewspaperTypeForm}
            />
            <WithFrame
              exact
              path="/form/:noticeId/:noticeType"
              component={Typeform}
            />
            <WithFrame
              exact
              path="/file/by-type/:type"
              component={AddNoticeWrapper}
            />
            <WithFrame exact path="/file/:id/" component={AddNoticeWrapper} />
            <WithFrame
              exact
              path="/file/:id/:noticeSlug?"
              component={AddNoticeWrapper}
            />
            <WithFrame exact path="/notices/" component={UserNotices} />
            <WithFrame
              exact
              path="/settings/organization/"
              component={OrganizationSettings}
            />
            <WithFrame
              exact
              noFramePadding
              path="/:path(notice|publish)/:id/"
              component={NoticeDetail}
            />
            <WithFrame
              exact
              noFramePadding
              path="/:path(notice|publish)/:id/invoice/create"
              component={CreateInvoice}
            />
            <WithFrame
              exact
              path="/:path(notice|publish)/:id/invoice/review"
              component={ReviewInvoice}
            />
            <WithFrame
              exact
              path="/:path(notice|publish)/:id/invoice/create-bulk"
              component={CreateBulkInvoice}
            />
            <WithFrame
              noFramePadding
              exact
              path="/invites/confirm-organization/"
              component={ConfirmOrganization}
            />
            <WithFrame
              exact
              path="/cards/"
              noFramePadding
              component={ColumnCards}
            />
            <WithFrame
              exact
              path="/payments/"
              noFramePadding
              component={Payments}
            />
            <WithFrame
              exact
              hideFrame
              path="/invites/:id"
              component={Invitation}
            />
            <WithFrame exact path="/error/:code" component={ErrorPage} />
            <WithFrame exact hideFrame path="/landing" component={Landing} />
            <WithFrame
              exact
              path="/donation-campaign"
              component={DonationCampaign}
            />
            <WithFrame
              exact
              hideFrame
              path="/invoices/:id/pay/"
              component={PayInvoice}
            />
            <WithFrame
              exact
              hideFrame
              path="/cards/invoices/:id/pay/"
              component={PayInvoice}
            />
            <WithFrame
              exact
              hideFrame
              path="/reset-password/"
              component={ResetPassword}
            />
            <Route exact path="/" render={() => <LoadingState />} />
          </Switch>
        </Suspense>
      </SuspenseErrorBoundary>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: any) => ({
  pathname: state.router.location.pathname
});

export default connect(mapStateToProps)(EnoticeRouter);
