import { EChars } from '../constants';
import {
  ECrop,
  ENotice,
  ESnapshotExists,
  ESnapshot,
  EOrganization
} from '../types';
import { EHandlebars } from '../headers_footers/shared';

const splitArrayIntoChunksOfNLength = (array: any[], n: number) => {
  const groups = array
    .map(function(_, i) {
      return i % n === 0 ? array.slice(i, i + n) : [];
    })
    .filter(e => e);

  return groups;
};

export const documentPagesFromImages = (img_array: any[], columns: number) => {
  const columnsWideColumnsPerPageMap = {
    1: 3,
    2: 2,
    3: 1
  } as Record<number, number>;

  const groups = splitArrayIntoChunksOfNLength(
    img_array,
    columnsWideColumnsPerPageMap[columns] || 1
  );

  return groups
    .map(group => {
      return {
        imgs: group,
        emptyFrames: groups[0].length - group.length
      };
    })
    .filter(group => group.imgs.length);
};

const getColRelWidthsFrmPx = (tableNode: HTMLElement) => {
  const table = tableNode.querySelector('tr') as HTMLElement;
  if (!table) return;

  const firstRowCells = table.children;
  const arr = Array.prototype.slice.call(firstRowCells);

  const totalWidth = arr.reduce((acc, node) => {
    const width = node.getAttribute('computed-width');
    return acc + parseFloat(width);
  }, 0);

  const relWidths = arr.map((node, i) => {
    const width = node.getAttribute('computed-width');
    return { i, val: (parseFloat(width) / totalWidth) * 100 };
  });

  relWidths.sort((a, b) => a.val - b.val);

  const MIN_WIDTH_PCT = 9;
  for (const w of relWidths) {
    if (w.val < MIN_WIDTH_PCT) {
      const diff = MIN_WIDTH_PCT - w.val;
      w.val += diff;
      relWidths[relWidths.length - 1].val -= diff;
    } else break;
  }

  return relWidths.sort((a, b) => a.i - b.i).map(w => w.val);
};

const htmlToIndesignCellAlignerMap = {
  left: 'LeftAlign',
  right: 'RightAlign',
  center: 'CenterAlign'
};

const addColGroupsToTables = (fakeDOM: Document) => {
  const div = fakeDOM.body;
  const tables = div.querySelectorAll('table');

  for (let i = 0; i < tables.length; i++) {
    const ogTable = tables[i];
    const relWidths = getColRelWidthsFrmPx(ogTable);
    const colGroup = fakeDOM.createElement('colgroup');

    (relWidths as any[]).forEach(widthPct => {
      const col = fakeDOM.createElement('col');
      col.setAttribute('width', `${widthPct}%`);
      colGroup.appendChild(col);
    });
    ogTable.insertBefore(colGroup, ogTable.firstChild);

    // add aligner divs inside cells
    const cells = ogTable.querySelectorAll('td');
    for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
      const cell = cells[cellIndex];
      const alignment = (cell as any).style['text-align'] as string;
      const currentStyleTag = cell.getAttribute('style');
      if (currentStyleTag) {
        cell.setAttribute(
          'style',
          currentStyleTag.replace(/text-align: (\w*);/g, '')
        );
      }
      const idCellStyle =
        (htmlToIndesignCellAlignerMap as any)[alignment] ||
        htmlToIndesignCellAlignerMap.left;
      cell.innerHTML = `<div custom-style=${idCellStyle}> ${cell.innerHTML} </div>`;
    }
  }
  return fakeDOM;
};

const addHeadingStyle = (fakeDOM: Document) => {
  const firstParagraph = fakeDOM.body.querySelector('p');
  if (firstParagraph) {
    firstParagraph.innerHTML = `<span custom-style="Heading">${firstParagraph.textContent}</span>`;
  }
};

export const headers = {
  addXML: (str: string) =>
    `<?xml version="1.0" encoding="UTF-8"?><dynamic-header xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${str}</dynamic-header>`,
  removeXML: (str: string) => {
    const match = str.match(
      new RegExp(/<dynamic-header.*?>(.+)<\/dynamic-header>/)
    );
    return Array.isArray(match) ? match[1] : '';
  }
};

export const MCEChars = {
  tab:
    '<span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span>'
};

const NEWSPAPERS_WITH_HYPHENATION_SET = ['WC7k2p3rmrcJuges3N3i'];
export const shouldPreserveLongSequencesForNewspaper = (
  newspaper: ESnapshot<EOrganization> | ESnapshotExists<EOrganization>
) => {
  return NEWSPAPERS_WITH_HYPHENATION_SET.includes(newspaper?.id);
};
export const shouldPreserveLongSequencesForNotice = (
  notice: ESnapshot<ENotice> | ESnapshotExists<ENotice>
) => {
  return NEWSPAPERS_WITH_HYPHENATION_SET.includes(
    notice?.data()?.newspaper?.id || ''
  );
};

/**
 * @param {string} html
 * @param {object} DOMParser
 * @param {object} options
 */
export const htmlToIndesignHtml = (
  html: string,
  DOMparser: typeof DOMParser,
  {
    isFirstPHeading,
    preserveLongSequences
  }: { isFirstPHeading: boolean; preserveLongSequences: boolean },
  template: Record<string, any>
) => {
  const fakeDOM = new DOMparser().parseFromString(html, 'text/html');
  addColGroupsToTables(fakeDOM);
  if (isFirstPHeading) addHeadingStyle(fakeDOM);
  const transformed = fakeDOM.body.innerHTML;
  const replaced = transformed
    .replace(/data-custom-style/g, 'custom-style')
    /* 
      handle both alignment attributes of the form 
      style="text-align: center;" and style="text-align:center"
      both are valid CSS, and different packages we rely on generate the two types
    */
    .replace(/(?:margin-left|margin-right):[^;]*;/gi, '')
    .replace(/style="\s?text-align:\s?(\w*)\s?;?"/g, 'custom-style="align-$1"')
    .replace(/<\/p>/g, '</div>')
    .replace(/<p>/g, '<div>')
    .replace(/<p /g, '<div ')
    .replace(/text-decoration:underline/g, 'text-decoration: underline')
    .replace(/<span><strong>(.|\n)*?<\/strong>.{0,1}<\/span>/g, res => {
      const withoutStartOrEndTags = res
        .replace('<span><strong>', '')
        .replace('</strong>', '')
        .replace('</span>', '');
      return `<strong><span>${withoutStartOrEndTags}</span></strong>`;
    })
    .replace(/<br>/g, '<div><br></div>')
    .replace(/_{10,}/g, (match: string) => {
      let str = `<span custom-style="Underline">`;
      for (let i = 0; i < match.length; i++) {
        str += '&nbsp;';
      }
      str += '</span>';
      return str;
    })
    .replace(
      // eslint-disable-next-line no-useless-escape
      /[\w:\/\.,=?]{20,}/g,
      (match: string) => {
        if (preserveLongSequences) {
          return match;
        }
        return `${match.slice(0, 16)} ${match.slice(16)}`;
      }
    )
    .replace(
      /<span style="text-decoration:\s?underline;?" data-mce-style="text-decoration:\s?underline;?">/g,
      '<span custom-style="Underline">'
    )
    .replace(/<u>/g, '<span custom-style="Underline">')
    .replace(new RegExp(MCEChars.tab, 'g'), EChars.tab)
    // eslint-disable-next-line no-useless-escape
    .replace(/<\/u>/g, '</span>')
    .replace(/<figure>.*?<\/figure>/g, EChars.tab);

  const compiled = EHandlebars.compile(replaced);
  const templated = compiled(template);
  return templated;
};

type PageParams = {
  columnGutter: number;
  columnWidth: number;
  headerHeight: number;
  footerHeight: number;
};

export const displayParamsFromNoticeAndPageParams = (
  crop: ECrop,
  pageParams: PageParams,
  selectedColumns: number
) => {
  const minColumns = 1;
  const columns = selectedColumns
    ? Math.max(minColumns, selectedColumns)
    : minColumns;

  const scaledWidth =
    pageParams.columnWidth * columns + (columns - 1) * pageParams.columnGutter;

  const scaledHeight =
    (scaledWidth / crop.absWidth) * crop.absHeight +
    pageParams.headerHeight +
    pageParams.footerHeight;

  return {
    height: scaledHeight,
    width: scaledWidth,
    area: scaledWidth * scaledHeight,
    columns: selectedColumns || minColumns,
    minColumns
  };
};
