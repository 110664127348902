import {
  all,
  take,
  takeEvery,
  call,
  put,
  select,
  race,
  takeLatest
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import { authSelector, AuthTypes } from 'redux/auth';
import JoyRideActions, { joyrideSelector, JoyRideTypes } from 'redux/joyrides';
import { EJoyRide, EUser, ESnapshot, FirebaseQuerySnapshot } from 'lib/types';
import pathToRegex from 'path-to-regexp';

import Firebase, { Collections } from '../EnoticeFirebase';

const test = (path: string, pathname: string) => {
  if (!path) return false;
  return pathToRegex(path).test(pathname);
};

const placementCB = async (e: any) => {
  if (e?.type === 'error:target_not_found') return;
  const enableSteps = () => {
    const disabled = document.querySelectorAll(
      "[class*='disabled-scroll-step']"
    );
    Array.from(disabled).forEach((el: any) => {
      // eslint-disable-next-line no-param-reassign
      el.style.opacity = 100;
    });
  };

  const disableSteps = () => {
    const disabled = document.querySelectorAll(
      "[class*='disabled-scroll-step']"
    );
    Array.from(disabled).forEach((el: any) => {
      // eslint-disable-next-line no-param-reassign
      el.style.opacity = '';
    });
  };

  const scrollTo = async (e: any) => {
    try {
      const target = e.step.scrollTarget || e.step.target;
      if (!document.querySelector(target)) return;
      const navStyle = window.getComputedStyle(
        document.getElementById('nav-spacer')
      );
      const navHeight = parseFloat(navStyle.getPropertyValue('height'));

      const formContainer = document.getElementById(
        'place-notice-form-container'
      );
      formContainer.scrollTo({
        top: document.querySelector(target).offsetTop - navHeight - 16,
        behavior: 'smooth'
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (e.action === 'update') {
    enableSteps();
  } else if (e.action === 'reset') {
    disableSteps();
  } else if (e.action === 'close') {
    disableSteps();
  }

  if (!e?.step?.noScroll) {
    scrollTo(e);
  }
};

const getRelevantJoyRide = (joyRides: ESnapshot<EJoyRide>[]) => {
  return joyRides
    .filter(j => {
      return test(
        j.data().route,
        window.location.pathname + window.location.search
      );
    })
    .sort((a: any, b: any) => {
      return a.data().start.toDate() - b.data().start.toDate();
    })[0];
};

const delay = (time: number) =>
  new Promise(resolve => setTimeout(resolve, time));

function* updateJoyRide() {
  const { joyRides, activeJoyRide } = yield select(joyrideSelector);
  if (!joyRides) return;

  const jr = getRelevantJoyRide(joyRides);
  if (!jr || activeJoyRide?.id === jr?.id) return;

  let cb = async (_: any) => {};
  if (window.location.pathname.includes('/place/')) {
    cb = placementCB;
  }

  yield call(delay, 200);
  yield put(JoyRideActions.setActiveJoyRide(jr, cb));
}

const extractRelevantJoyRides = async (
  user: ESnapshot<EUser>,
  joyrides: ESnapshot<EJoyRide>[]
) => {
  const results = await Promise.all(
    joyrides.map(async joyride => {
      const { occupations, staging } = joyride.data();

      if (occupations && !occupations.includes(user.data().occupation))
        return null;

      if (staging && !user.data().stagingJoyRides) return null;

      const existingJoyride = await Firebase.firestore()
        .collection(Collections.joyrides)
        .doc(joyride.id)
        .collection(Collections.users)
        .doc(user.id)
        .get();

      if (existingJoyride.exists) return null;

      return joyride;
    })
  );

  return results.filter(Boolean);
};

export function* getJoyRide({ user }: { user: ESnapshot<EUser> }) {
  // feature flag to not set any joyrides in e2e env
  if (localStorage.getItem('ise2e')) return;

  const joyRidesQuery = yield Firebase.firestore().collection(
    Collections.joyrides
  );

  const joyRidesQueryResults = yield call([joyRidesQuery, joyRidesQuery.get]);

  const joyRidesChannel = eventChannel(emitter =>
    joyRidesQueryResults.query.onSnapshot(emitter)
  );

  while (true) {
    const { joyRidesQuery } = yield race({
      joyRidesQuery: take(joyRidesChannel),
      logout: take(AuthTypes.LOGOUT)
    }) as { announcementQuery?: FirebaseQuerySnapshot };
    if (!joyRidesQuery) break;

    const allJoyRides = joyRidesQuery.docs as ESnapshot<EJoyRide>[];

    const joyrides = yield call(extractRelevantJoyRides, user, allJoyRides);

    if (joyrides) yield put(JoyRideActions.setJoyRides(joyrides));
  }
}

function* markJoyRideRead() {
  const { user } = yield select(authSelector);
  const { activeJoyRide, joyRides } = yield select(joyrideSelector);
  if (!activeJoyRide) return;

  yield put(JoyRideActions.setActiveJoyRide(null));
  yield put(
    JoyRideActions.setJoyRides(
      joyRides.filter((j: ESnapshot<EJoyRide>) => j.id !== activeJoyRide.id)
    )
  );

  const markRead = async () => {
    await Firebase.firestore()
      .collection(Collections.joyrides)
      .doc(activeJoyRide.id)
      .collection(Collections.users)
      .doc(user.id)
      .set({
        seenAt: new Date()
      });
  };
  yield call(markRead);
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, updateJoyRide);
  yield takeLatest(JoyRideTypes.SET_NEW_JOY_RIDE_POSSIBLE, updateJoyRide);
  yield all([
    takeEvery(LOCATION_CHANGE, updateJoyRide),
    takeEvery(JoyRideTypes.SET_JOY_RIDES, updateJoyRide),
    takeEvery(JoyRideTypes.MARK_JOY_RIDE_READ, markJoyRideRead),
    takeEvery(AuthTypes.SET_USER, getJoyRide)
  ]);
}
