import moment from 'moment';
import { RateType, NoticeType, Day } from '../enums';
import {
  ESnapshotExists,
  ENotice,
  ERef,
  ERate,
  EOrganization,
  ESnapshot,
  EInvoice
} from '../types';
import { DistributeSettings } from '../types/organization';
import { LineItem } from '../types/invoices';
import { firestoreTimestampOrDateToDate } from '../helpers';
import { dateObjectToDayEnum } from '../utils/deadlines';

export const getColumnInches = (height: number, columns: number) => {
  return height * columns;
};

export const getColumnCentimeters = (height: number, columns: number) => {
  return height * columns * 2.54;
};
// (3.1, .25) => 3.25, (3.1, 0) => 3.1
export const roundUp = (x: number, r: number) => (r ? x + r - (x % r) : x);

type Rate = {
  rateType: number;
  roundOff: number;
  rate_0: number;
  rate_1: number;
  rate_2: number;
  additionalRates?: { [key: string]: number };
  runBased?: boolean;
};

type DisplayParams = {
  words?: number;
  lines?: number;
  area?: number;
  height: number;
  width: number;
  columns?: number;
  tableWords?: number;
  tableLines?: number;
  headerWords: number;
  headerLines: number;
  boldWords?: number;
  nonTableBoldedLines?: number;
  justifications: {
    LEFT_ALIGN: {
      lines: number;
      words: number;
    };
    RIGHT_ALIGN: {
      lines: number;
      words: number;
    };
    CENTER_ALIGN: {
      lines: number;
      words: number;
    };
    LEFT_JUSTIFIED: {
      lines: number;
      words: number;
    };
  };
};

export const oklahoma = {
  getBodyWords: (displayParameters: DisplayParams) =>
    Object.entries(displayParameters.justifications).reduce((acc, [k, v]) => {
      if (k === 'LEFT_ALIGN' || k === 'RIGHT_ALIGN' || k === 'CENTER_ALIGN')
        return acc;
      return acc + v.words;
    }, 0),
  getTabularLines: (displayParameters: DisplayParams) =>
    displayParameters.justifications.LEFT_ALIGN.lines +
    displayParameters.justifications.RIGHT_ALIGN.lines +
    displayParameters.justifications.CENTER_ALIGN.lines
};

export const calculateConvenienceFee = (
  subtotal: number,
  percentage: number,
  disableMinimumConvenienceFee?: boolean
) => {
  // 0 pct means it is a government rate
  if (percentage === 0) return 0;

  const convenience_fee = Math.round((subtotal * percentage) / 100);

  if (disableMinimumConvenienceFee) return convenience_fee;
  return convenience_fee;
  // return convenience_fee < 500
  //   ? 500
  //   : convenience_fee > 15000
  //   ? 15000
  //   : convenience_fee;
};

export const getApplicableRate = (
  numRuns: number,
  rateRecord: Rate,
  runNumber: number
) => {
  let applicableRate;
  const applicableRun = rateRecord.runBased ? runNumber : numRuns;
  if (applicableRun === 1 || rateRecord.rateType === RateType.per_run.value) {
    applicableRate = rateRecord.rate_0;
  } else if (applicableRun === 2) {
    applicableRate = rateRecord.rate_1;
  } else if (applicableRun === 3) {
    applicableRate = rateRecord.rate_2;
  } else if (applicableRun >= 4 && rateRecord.additionalRates) {
    const maxRateNumber = Math.max(
      ...Object.keys(rateRecord.additionalRates).map(rate =>
        parseInt(rate.replace('rate_', ''), 10)
      )
    );
    const rate = Math.min(maxRateNumber, applicableRun - 1);
    applicableRate = rateRecord.additionalRates[`rate_${rate}`];
  } else {
    applicableRate = rateRecord.rate_0;
  }
  return applicableRate;
};

export const relevantDisplayParameterFromRate = (
  rateRecord: Rate,
  displayParameters: DisplayParams,
  columns: number
) => {
  if (rateRecord.rateType === RateType.flat.value) return null;
  if (rateRecord.rateType === RateType.per_run.value) return null;
  if (rateRecord.rateType === RateType.word_count.value)
    return displayParameters.words;
  if (rateRecord.rateType === RateType.inch.value)
    return roundUp(
      displayParameters.height * displayParameters.width,
      rateRecord.roundOff
    ).toFixed(2);
  if (rateRecord.rateType === RateType.column_inch.value)
    return getColumnInches(
      roundUp(displayParameters.height, rateRecord.roundOff),
      columns
    ).toFixed(2);
  if (rateRecord.rateType === RateType.line.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.nebraska.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.oklahoma.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.battle_born.value)
    return displayParameters.lines;
  if (rateRecord.rateType === RateType.berthoud_government.value)
    return `${displayParameters.lines} lines, ${getColumnInches(
      roundUp(displayParameters.height, rateRecord.roundOff),
      columns
    ).toFixed(2)} total column inches`;
  if (rateRecord.rateType === RateType.enterprise.value)
    return `${getColumnInches(displayParameters.height, columns).toFixed(
      2
    )} total column inches`;
  if (rateRecord.rateType === RateType.single_column_centimetre.value) {
    return `${getColumnCentimeters(displayParameters.height, columns).toFixed(
      2
    )} total scm`;
  }
  throw new Error(`Unknown rate type ${rateRecord.rateType}`);
};

export const floatToP2Float = (bigFloat: number) => {
  const floatStr = bigFloat.toFixed(2);
  return parseFloat(floatStr);
};

export const uiToDBCurrency = (bigFloat: string | number) => {
  const parsedFloat =
    typeof bigFloat === 'string' ? parseFloat(bigFloat) : bigFloat;
  const floatStr = parsedFloat.toFixed(2);
  const float = parseFloat(floatStr);
  const dbNum = Math.round(float * 100);
  return dbNum;
};

export const dbToUICurrency = (num: number) => {
  const floatStr = (num / 100).toFixed(2);
  return parseFloat(floatStr);
};

export const dbToUICurrencyString = (num: number) => {
  const floatStr = (num / 100).toFixed(2);
  return floatStr;
};

export const floatToDBPercent = (pct: number | string) => {
  const isStr = typeof pct === 'string';
  const parsedPct = isStr ? parseFloat(pct as string) : pct;
  const floatStr = (parsedPct as number).toFixed(2);
  const dbNum = parseFloat(floatStr);
  return dbNum;
};

const getPercentString = (pct: number) => {
  return pct.toFixed(2);
};

export const customerPaidAmtFromInvoice = async (
  invoice: EInvoice,
  ENOTICE_FEE_PCT: number
) => {
  return invoice.inAppInvoicedAmt * (1 + ENOTICE_FEE_PCT / 100);
};

export const calculateFee = (
  paper: ESnapshotExists<EOrganization>,
  numRuns: number,
  runNumber: number
): number | undefined =>
  paper.data().fee && runNumber + 1 === numRuns ? paper.data().fee : 0;

export const calculateBoldPrices = (
  noticeRecord: Partial<ENotice>,
  rateRecord: ERate,
  displayParameters: DisplayParams
) => {
  let boldPrice = 0;
  if (noticeRecord.noticeType !== NoticeType.display_ad.value) {
    if (rateRecord?.bold_words && displayParameters?.boldWords) {
      return (boldPrice +=
        displayParameters?.boldWords * rateRecord.bold_words);
    }
    if (
      rateRecord?.line_with_bold_words &&
      displayParameters?.nonTableBoldedLines
    ) {
      return (boldPrice +=
        displayParameters?.nonTableBoldedLines *
        rateRecord.line_with_bold_words);
    }
  }
  return boldPrice;
};

export const calculateDBPrice = (
  noticeRecord: Partial<ENotice>,
  rateRecord: ERate,
  displayParameters: DisplayParams,
  numRuns: number,
  columns: number,
  dayRate: number,
  runNumber: number
) => {
  if (noticeRecord.fixedPrice) {
    if (runNumber > 0) return 0;
    return noticeRecord.fixedPrice;
  }

  let result;

  const applicableRate =
    dayRate || getApplicableRate(numRuns, rateRecord, runNumber + 1);
  if (rateRecord.rateType === RateType.flat.value) {
    if (dayRate) return dayRate;
    if (runNumber > 0) return 0;
    if (numRuns === 1) return rateRecord.rate_0;
    if (numRuns === 2) return rateRecord.rate_1;
    if (numRuns >= 4 && rateRecord.additionalRates) {
      const maxRateNumber = Math.max(
        ...Object.keys(rateRecord.additionalRates).map(rate =>
          parseInt(rate.replace('rate_', ''), 10)
        )
      );
      const rate = Math.min(maxRateNumber, numRuns - 1);
      return rateRecord.additionalRates[`rate_${rate}`];
    }
    return rateRecord.rate_2;
  }

  if (rateRecord.rateType === RateType.per_run.value) {
    return dayRate || rateRecord.rate_0;
  }

  result = runNumber === 0 ? applicableRate : 0;

  if (rateRecord.rateType === RateType.word_count.value)
    result = (displayParameters.words as number) * applicableRate;

  if (rateRecord.rateType === RateType.inch.value)
    result = floatToP2Float(
      roundUp(
        displayParameters.height * displayParameters.width,
        rateRecord.roundOff
      ) * applicableRate
    );

  if (rateRecord.rateType === RateType.column_inch.value) {
    result = floatToP2Float(
      getColumnInches(
        roundUp(displayParameters.height, rateRecord.roundOff),
        columns
      ) * applicableRate
    );
  }

  if (rateRecord.rateType === RateType.line.value)
    result = floatToP2Float(
      (displayParameters.lines as number) * columns * applicableRate
    );

  if (rateRecord.rateType === RateType.nebraska.value) {
    if (runNumber === 0) {
      result = floatToP2Float(
        (displayParameters.lines as number) * columns * rateRecord.rate_0
      );
    } else if (runNumber === 1) {
      result = floatToP2Float(
        (displayParameters.lines as number) * columns * rateRecord.rate_1
      );
    } else {
      result = floatToP2Float(
        (displayParameters.lines as number) * columns * rateRecord.rate_2
      );
    }
  }

  if (rateRecord.rateType === RateType.oklahoma.value) {
    const wordRate = runNumber === 0 ? 15 : 14;
    const tabularRate = runNumber === 0 ? 70 : 65;

    const wordTotal = floatToP2Float(
      oklahoma.getBodyWords(displayParameters) * wordRate
    );

    const tabularTotal = floatToP2Float(
      oklahoma.getTabularLines(displayParameters) * tabularRate
    );

    result = wordTotal + tabularTotal;
  }

  if (rateRecord.rateType === RateType.battle_born.value) {
    const lineRate50 = 200;
    const lineRate50Gt = 170;
    const lines50 = 50;
    const lines50Gt = Math.max((displayParameters.lines as number) - 50, 0);
    result =
      runNumber + 1 === numRuns
        ? lines50 * lineRate50 + lines50Gt * lineRate50Gt
        : 0;
  }

  if (rateRecord.rateType === RateType.berthoud_government.value) {
    const lineRate = 44;
    const ciRate = 766;
    result =
      runNumber === 0
        ? lineRate * (displayParameters.lines as number)
        : ciRate *
          getColumnInches(
            displayParameters.height,
            displayParameters.columns as number
          );
  }

  if (rateRecord.rateType === RateType.enterprise.value) {
    const flatciRate = 9500;
    const ciRate = 950;
    const cInches = getColumnInches(
      displayParameters.height,
      displayParameters.columns as number
    );
    result =
      cInches <= 10
        ? flatciRate
        : flatciRate + roundUp(cInches - 10, 0.25) * ciRate;
  }

  if (rateRecord.rateType === RateType.single_column_centimetre.value) {
    result = floatToP2Float(
      getColumnCentimeters(
        roundUp(displayParameters.height, rateRecord.roundOff),
        columns
      ) * applicableRate
    );
  }

  if (!(result === 0 || result))
    console.error(
      `Unknown pricing scheme: ${rateRecord.rateType} with result ${result}`
    );

  return result;
};

export const getRelevantRateString = (
  rateRecord: Rate,
  displayParameters: DisplayParams,
  columns: number
) => {
  const ratePlural = RateType.by_value(rateRecord.rateType).plural;
  const relevantParam = relevantDisplayParameterFromRate(
    rateRecord,
    displayParameters,
    columns
  );

  if (rateRecord.rateType === RateType.oklahoma.value)
    return `Tabular Lines: ${oklahoma.getTabularLines(
      displayParameters
    )}, Body Words: ${oklahoma.getBodyWords(displayParameters)}`;

  if (rateRecord.rateType === RateType.single_column_centimetre.value)
    return `Single column centimetres: ${relevantParam}`;

  if (rateRecord.rateType === RateType.per_run.value) return '';
  return rateRecord.rateType === RateType.column_inch.value
    ? `Height: ${roundUp(displayParameters.height, rateRecord.roundOff).toFixed(
        2
      )} / Columns: ${columns}`
    : `${ratePlural}: ${relevantParam ? `${relevantParam}` : 'n/a'}`;
};

export const getCorrectNoticeRate = async (
  oldRate: ESnapshot<ERate>,
  newType: number,
  newspaper: ESnapshotExists<EOrganization>
): Promise<ERef<ERate>> => {
  const defaultDisplay = (await newspaper
    .data()
    .defaultDisplayRate.get()) as ESnapshotExists<ERate>;
  const defaultLiner = (await newspaper
    .data()
    .defaultLinerRate.get()) as ESnapshotExists<ERate>;

  const oldRateData = oldRate && oldRate.data();

  if (
    newType === (NoticeType as any).display_ad.value &&
    oldRateData &&
    oldRateData.code === defaultLiner.data().code
  ) {
    return defaultDisplay.ref;
  }
  if (
    newType !== (NoticeType as any).display_ad.value &&
    oldRateData &&
    oldRateData.code === defaultDisplay.data().code
  ) {
    return defaultLiner.ref;
  }
  return oldRate.ref;
};

export const getNoticeRate = async (
  notice: Partial<ENotice>,
  newspaper: EOrganization,
  placementRate?: ERef<ERate>
): Promise<ESnapshotExists<ERate>> => {
  let rateSnap;
  if (placementRate) {
    rateSnap = await placementRate.get();
  } else if (notice.rate) {
    rateSnap = await notice.rate.get();
  } else if (notice.noticeType === (NoticeType as any).display_ad.value) {
    rateSnap = await newspaper.defaultDisplayRate.get();
  } else {
    rateSnap = await newspaper.defaultLinerRate.get();
  }

  return rateSnap as ESnapshotExists<ERate>;
};

const mockDisplayParams = {
  words: 0,
  lines: 0,
  area: 0,
  height: 0,
  width: 0,
  headerLines: 0,
  headerWords: 0,
  nonTableBoldedLines: 0,
  boldWords: 0,
  justifications: {
    RIGHT_ALIGN: {
      lines: 0,
      words: 0
    },
    LEFT_ALIGN: {
      lines: 0,
      words: 0
    },
    CENTER_ALIGN: {
      lines: 0,
      words: 0
    },
    LEFT_JUSTIFIED: {
      lines: 0,
      words: 0
    }
  }
};

export type DBLineItem = {
  date: Date;
  amount: number;
  description?: string;
};

export type DBPricingObj = {
  lineItems: DBLineItem[];
  subtotal: number;
  taxPct: number;
  taxAmt: number;
  convenienceFeePct: number;
  convenienceFee: number;
  total: number;
  // we are setting the convenience fee to null in distributeDbPrice which is leading to incorrect payouts,
  // If we are distributing the fee, we can determine the original fee by accessing the property distributedFee
  distributed?: boolean;
  distributedFee?: number;
};

export type DistributedDBPricingObj = DBPricingObj & {
  convenienceFeePct: undefined;
  convenienceFee: undefined;
  distributed?: boolean;
  distributedFee?: number;
};

export const isCustomLineItem = (lineItem: DBLineItem) => {
  // no description means not custom
  if (!lineItem.description) return false;

  // return yes on line items of the format mm/dd/yyyy:
  if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/)) return false;

  return true;
};

export const createDBPricingObjectFromData = async (
  notice: Partial<ENotice>,
  displayParameters: DisplayParams = mockDisplayParams,
  rateSnap?: ESnapshotExists<ERate>
): Promise<DBPricingObj> => {
  if (!notice.newspaper)
    throw new Error(
      `Cannot compute pricing for notice ${notice.id} without newspaper`
    );
  const newspaperSnap = (await notice.newspaper?.get()) as ESnapshotExists<
    EOrganization
  >;
  const newspaper = newspaperSnap?.data();

  const customNoticeType =
    newspaper?.allowedNotices?.find(
      (type: any) => type.value === notice.noticeType
    ) || null;
  const rate = rateSnap
    ? rateSnap.data()
    : (await getNoticeRate(notice, newspaper)).data();
  const columns = displayParameters.columns || notice.columns || 1;

  if (!notice.publicationDates)
    throw new Error(
      `Cannot compute pricing for notice ${notice.id} without publication dates`
    );
  let lineItems: DBLineItem[] = notice.publicationDates.map((date, i) => {
    const dayEnum = dateObjectToDayEnum(date.toDate());
    let dayRate = 0;
    if (rate.dayRates && rate.dayRates.find(dRate => dRate.day === dayEnum)) {
      dayRate = rate.dayRates.find(dRate => dRate.day === dayEnum)!.rate;
    }
    return {
      date: date.toDate(),
      amount:
        calculateDBPrice(
          notice,
          rate,
          displayParameters,
          notice.publicationDates!.length,
          columns,
          dayRate,
          i
        ) +
        calculateBoldPrices(notice, rate, displayParameters) +
        (calculateFee(newspaperSnap, notice.publicationDates!.length, i) || 0),
      ...(dayRate && {
        description: `${moment(date.toDate()).format(
          'MM/DD/YYYY'
        )}: Custom notice (${Day.by_value(dayEnum).label} Rate)`
      })
    };
  });

  // allow for additional line items at the newspaper level
  if (newspaper.additionalFees) {
    lineItems = lineItems.concat(
      newspaper.additionalFees.map(fee => ({
        date: notice.publicationDates![0].toDate(),
        amount: fee.amount,
        description: fee.description
      }))
    );
  }

  // allow for additional fees for custom affidavits
  // this enables additional fees for Ogden papers that
  // are requesting notice-type-specific pricing
  if (newspaper.customAffidavitFee) {
    lineItems = [
      {
        date: notice.publicationDates![0].toDate(),
        amount: newspaper.customAffidavitFee,
        description: 'Custom Affidavit Fee'
      } as DBLineItem
    ].concat(lineItems);
  }

  // allow for additional line items at the rate level
  // Note: some typeform-zap notice types have fixed pricing and
  // no associated rate; do not add rate-associated line items
  // for those
  const blockAdditionalRateFee =
    customNoticeType &&
    !customNoticeType.rate &&
    !NoticeType.by_value(notice.noticeType);
  if (!blockAdditionalRateFee && rate.additionalFee) {
    lineItems = lineItems.concat({
      date: notice.publicationDates[0].toDate(),
      amount: rate.additionalFee.amount,
      description: rate.additionalFee.description
    });
  }

  const taxPct = newspaper.taxPct || 0;

  const totalAcrossRuns = lineItems.reduce(
    (acc, lineItem) => acc + lineItem.amount,
    0
  );

  const subtotal = Math.max(totalAcrossRuns, rate.minimum);

  // find the index of the last non-custom line item
  let lastNonCustomLineItemIndex = 0;
  for (const [i, item] of lineItems.entries()) {
    if (!isCustomLineItem(item)) lastNonCustomLineItemIndex = i;
  }

  // enforce that all line items are cents
  let roundedSubtotal = 0;
  for (const item of lineItems) {
    const centTotal = Math.floor(item.amount);
    item.amount = centTotal;
    roundedSubtotal += centTotal;
  }
  // put the impact of rounding onto the last non-custom line item
  lineItems[lastNonCustomLineItemIndex].amount += subtotal - roundedSubtotal;

  let customLineItemTotal = 0;
  let changedFinalLineItem = false;
  if (rate.finalLineItemPricing) {
    for (let i = lineItems.length - 1; i >= 0; i -= 1) {
      if (isCustomLineItem(lineItems[i])) {
        customLineItemTotal += lineItems[i].amount;
      } else if (changedFinalLineItem) {
        lineItems[i].amount = 0;
      } else {
        lineItems[i].amount = totalAcrossRuns - customLineItemTotal;
        changedFinalLineItem = true;
      }
    }
  }

  const taxAmt = lineItems.reduce(
    (acc, lineItem) => acc + Math.round((lineItem.amount * taxPct) / 100),
    0
  );
  const convenience_fee = calculateConvenienceFee(
    subtotal,
    rate.enotice_fee_pct
  );

  const total = subtotal + taxAmt + convenience_fee;

  return {
    lineItems,
    subtotal,
    taxPct: floatToDBPercent(taxPct),
    taxAmt,
    convenienceFeePct: floatToDBPercent(rate.enotice_fee_pct),
    convenienceFee: convenience_fee,
    total
  };
};

export const createDBPricingObject = async (
  noticeSnap: ESnapshotExists<ENotice>,
  displayParameters: DisplayParams = mockDisplayParams,
  rateSnap?: ESnapshotExists<ERate>
): Promise<DBPricingObj> => {
  if (!noticeSnap.data()) {
    throw new Error('Notice not set');
  }

  const notice = noticeSnap.data();
  return await createDBPricingObjectFromData(
    notice,
    displayParameters,
    rateSnap
  );
};

export const createDBPricingFromNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const rate = (await noticeSnap.data().rate.get()) as ESnapshotExists<ERate>;
  const { displayParams } = noticeSnap.data();
  return createDBPricingObject(noticeSnap, displayParams, rate);
};

export const distributeDbPrice = (
  dbPricingObj: DBPricingObj,
  distributeEnoticeFee: DistributeSettings,
  finalLineItemPricing?: boolean,
  rateType?: number
) => {
  const itemsToDistributeOver = dbPricingObj.lineItems.filter(
    l => !isCustomLineItem(l)
  );

  const distributedFee =
    dbPricingObj.convenienceFee / itemsToDistributeOver.length;
  const subtotal = dbPricingObj.subtotal + dbPricingObj.convenienceFee;

  let taxAmt = dbPricingObj.lineItems
    .map(item => Math.round(item.amount * (dbPricingObj.taxPct / 100)))
    .reduce((pre, cur) => pre + cur, 0);
  taxAmt += dbPricingObj.convenienceFee * (dbPricingObj.taxPct / 100);

  const total = subtotal + taxAmt;

  let finalLineItemAmount: number;
  let lineItems;

  if (
    distributeEnoticeFee?.finalLineItem &&
    finalLineItemPricing &&
    rateType === RateType.flat.value
  ) {
    let customLineItemTotal = 0;

    dbPricingObj.lineItems.forEach(item => {
      if (isCustomLineItem(item)) {
        customLineItemTotal += item.amount;
      }
    });

    lineItems = dbPricingObj.lineItems.map((item, i) => {
      return {
        description: item.description,
        date: item.date,
        amount:
          i === 0
            ? subtotal - customLineItemTotal
            : isCustomLineItem(item)
            ? item.amount
            : 0
      };
    });
  } else if (
    distributeEnoticeFee?.finalLineItem &&
    !finalLineItemPricing &&
    rateType === RateType.flat.value
  ) {
    finalLineItemAmount =
      dbPricingObj.convenienceFee + itemsToDistributeOver[0].amount;

    lineItems = dbPricingObj.lineItems.map((item, i) => {
      return {
        description: item.description,
        date: item.date,
        amount:
          i === itemsToDistributeOver.length - 1
            ? finalLineItemAmount
            : isCustomLineItem(item)
            ? item.amount
            : 0
      };
    });
  } else if (
    distributeEnoticeFee?.finalLineItem &&
    !finalLineItemPricing &&
    rateType !== RateType.flat.value
  ) {
    finalLineItemAmount =
      dbPricingObj.convenienceFee + itemsToDistributeOver[0].amount;

    lineItems = dbPricingObj.lineItems.map((item, i) => {
      return {
        description: item.description,
        date: item.date,
        amount:
          i === itemsToDistributeOver.length - 1
            ? finalLineItemAmount
            : item.amount
      };
    });
  } else if (
    (distributeEnoticeFee?.evenly &&
      (rateType === RateType.flat.value || finalLineItemPricing)) ||
    (distributeEnoticeFee?.finalLineItem && finalLineItemPricing)
  ) {
    lineItems = dbPricingObj.lineItems.map((item, i) => {
      return {
        description: item.description,
        date: item.date,
        amount:
          item.amount !== 0 && !isCustomLineItem(item)
            ? item.amount + dbPricingObj.convenienceFee
            : item.amount
      };
    });
  } else {
    const totalToDistributeOver = itemsToDistributeOver.reduce(
      (a, b) => a + b.amount,
      0
    );

    // if there is only one value to distribute over, put the whole fee on it
    if (itemsToDistributeOver.length === 1) {
      finalLineItemAmount = totalToDistributeOver + dbPricingObj.convenienceFee;
    }

    // otherwise we need to be careful with rounding
    else {
      const initialDistributed = itemsToDistributeOver
        .slice(0, -1)
        .reduce((a, b) => a + b.amount + Math.round(distributedFee), 0);

      finalLineItemAmount =
        totalToDistributeOver +
        dbPricingObj.convenienceFee -
        initialDistributed;
    }

    lineItems = dbPricingObj.lineItems.map((item, i) => {
      let amount: number;

      // don't include the fee on custom items
      if (isCustomLineItem(item)) amount = item.amount;
      // if we are handling rounding on the final item
      // or we are handling rounding on the last not custom line item
      else if (
        i === itemsToDistributeOver.length - 1 ||
        (i === itemsToDistributeOver.length - 2 &&
          isCustomLineItem(dbPricingObj.lineItems[i + 1]))
      ) {
        amount = finalLineItemAmount;
      }

      // otherwise include the default spread
      else amount = item.amount + Math.round(distributedFee);

      return {
        description: item.description,
        date: item.date,
        amount
      };
    });
  }

  return {
    ...dbPricingObj,
    lineItems,
    convenienceFee: null,
    convenienceFeePct: null,
    subtotal,
    taxAmt,
    total,
    distributed: true,
    distributedFee: dbPricingObj.convenienceFee
  } as DistributedDBPricingObj;
};

export const invoiceDataToDBPricingObject = (
  inAppLineItems: LineItem[],
  convenienceFeePct: number,
  inAppTaxPct: number,
  distributeEnoticeFee?: DistributeSettings,
  disableMinimumConvenienceFee?: boolean,
  finalLineItemPricing?: boolean,
  rateType?: number
): DBPricingObj | DistributedDBPricingObj => {
  const subtotal = inAppLineItems
    .map(item => item.amount)
    .reduce((pre, cur) => pre + cur, 0);

  const convenienceFee = calculateConvenienceFee(
    subtotal,
    convenienceFeePct,
    disableMinimumConvenienceFee
  );

  const taxAmt = inAppLineItems
    .map(item => Math.round(item.amount * (inAppTaxPct / 100)))
    .reduce((pre, cur) => pre + cur, 0);

  const total = subtotal + taxAmt + convenienceFee;

  let obj = {
    lineItems: inAppLineItems
      .map(item => {
        return {
          ...item,
          date: firestoreTimestampOrDateToDate(item.date)
        } as DBLineItem;
      })
      .sort(
        (a: DBLineItem, b: DBLineItem) => a.date.getTime() - b.date.getTime()
      ),
    taxPct: inAppTaxPct,
    taxAmt,
    convenienceFeePct,
    convenienceFee,
    subtotal,
    total
  };

  if (
    distributeEnoticeFee &&
    Object.values(distributeEnoticeFee).some(v => v)
  ) {
    obj = distributeDbPrice(
      obj,
      distributeEnoticeFee,
      finalLineItemPricing,
      rateType
    );
  }

  return obj;
};

export type UIPricing = {
  lineItems: {
    date: Date;
    amount: number;
  }[];
  subtotal: number;
  taxPct: number;
  convenienceFeePct: number;
  convenienceFee: number;
  total: number;
};

export const getUIPricingObject = (dbPricingObj: DBPricingObj) => {
  const lineItems = dbPricingObj.lineItems.map(item => {
    return { date: item.date, amount: dbToUICurrency(item.amount) };
  });

  const subtotal = dbToUICurrency(dbPricingObj.subtotal);
  const { taxPct } = dbPricingObj;
  const { convenienceFeePct } = dbPricingObj;
  const { convenienceFee } = dbPricingObj;
  const total = dbToUICurrency(dbPricingObj.total);

  return {
    lineItems,
    subtotal,
    taxPct,
    convenienceFeePct,
    convenienceFee,
    total
  } as UIPricing;
};

export type UIPricingStrings = {
  lineItems: {
    date: Date;
    amount: string;
    description: string | null;
  }[];
  subtotal: string;
  taxPct: string;
  convenienceFeePct: string;
  convenienceFee: string;
  total: string;
};

type UIPricingOptions = {
  distributeEnoticeFee: DistributeSettings | null | undefined;
};

export const getUIPricingObjectStrings = (
  dbPricingObj: DBPricingObj,
  options?: UIPricingOptions,
  finalLineItemPricing?: boolean,
  rateType?: number
) => {
  const dbPricing =
    options && options.distributeEnoticeFee
      ? distributeDbPrice(
          dbPricingObj,
          options.distributeEnoticeFee,
          finalLineItemPricing,
          rateType
        )
      : dbPricingObj;

  const lineItems = dbPricing.lineItems.map(item => {
    return {
      date: item.date,
      amount: dbToUICurrencyString(item.amount),
      description: item.description || null
    };
  });

  const convenienceFeePct = dbPricing.convenienceFeePct
    ? getPercentString(dbPricing.convenienceFeePct)
    : undefined;
  const convenienceFee = dbPricing.convenienceFee
    ? dbToUICurrencyString(dbPricing.convenienceFee)
    : undefined;
  const subtotal = dbToUICurrencyString(dbPricing.subtotal);
  const taxPct = getPercentString(dbPricing.taxPct);
  const total = dbToUICurrencyString(dbPricing.total);

  return {
    lineItems,
    subtotal,
    taxPct,
    convenienceFeePct,
    convenienceFee,
    total
  } as UIPricingStrings;
};

export const ENOTICE_NAME = 'Column, PBC';

export default {
  getColumnInches,
  getColumnCentimeters,
  calculateDBPrice,
  getRelevantRateString,
  getApplicableRate,
  relevantDisplayParameterFromRate,
  getCorrectNoticeRate,
  getNoticeRate,
  createDBPricingObject,
  createDBPricingObjectFromData,
  getUIPricingObject,
  getUIPricingObjectStrings,
  floatToP2Float,
  uiToDBCurrency,
  dbToUICurrency,
  dbToUICurrencyString,
  floatToDBPercent,
  ENOTICE_NAME,
  customerPaidAmtFromInvoice,
  calculateConvenienceFee,
  calculateBoldPrices
};
