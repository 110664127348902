export default {
  inch: {
    value: 1,
    label: 'Rate by display inch',
    label_abbrev: 'Cost / In^2',
    plural: 'Display Inches',
    singular: 'Display Inch',
    key: 'inch'
  },
  word_count: {
    value: 2,
    label: 'Rate by word count',
    label_abbrev: 'Cost / Word',
    plural: 'Words',
    singular: 'Word',
    key: 'word_count'
  },
  flat: {
    value: 3,
    label: 'Flat rate',
    label_abbrev: 'Cost',
    plural: 'Flat Rate',
    singular: 'Flat Rate',
    key: 'flat'
  },
  line: {
    value: 4,
    label: 'Rate by line',
    label_abbrev: 'Cost / Line',
    plural: 'Lines',
    singular: 'Line',
    key: 'line'
  },
  column_inch: {
    value: 5,
    label: 'Rate by column inch',
    label_abbrev: 'Cost / In^2',
    plural: 'Total Column Inches',
    singular: 'Column Inch',
    key: 'column_inch'
  },
  nebraska: {
    value: 6,
    label: 'Nebraska Statute',
    label_abbrev: 'Nebraska Rate',
    plural: 'Lines',
    singular: 'Line',
    key: 'nebraska'
  },
  oklahoma: {
    value: 7,
    label: 'Oklahoma Statute',
    label_abbrev: 'Oklahoma Rate',
    plural: 'Lines',
    singular: 'Line',
    long_description: 'Oklahoma Statute',
    key: 'oklahoma'
  },
  battle_born: {
    value: 8,
    label: 'Battle Born Media Liner Rate',
    label_abbrev: 'Battle Born Rate',
    plural: 'Lines',
    singular: 'Line',
    key: 'battle_born'
  },
  per_run: {
    value: 9,
    label: 'Per Run',
    label_abbrev: 'Per Run',
    plural: 'Per Run',
    singular: 'Per Run',
    key: 'per_run'
  },
  berthoud_government: {
    value: 10,
    label: 'Berthoud Government Rate',
    label_abbrev: 'Berthoud Government Rate',
    plural: 'Berthoud Government Rate',
    singular: 'Berthoud Government Rate',
    key: 'berthoud_government'
  },
  enterprise: {
    value: 11,
    label: 'Enterprise',
    label_abbrev: 'Enterprise',
    plural: 'Enterprise',
    singular: 'Enterprise',
    key: 'enterprise'
  },
  single_column_centimetre: {
    value: 12,
    label: 'Rate by scm',
    label_abbrev: 'Cost / scm',
    plural: 'Total Single Column Centimetres',
    singular: 'Column Centimetre',
    key: 'single_column_centimetre'
  }
};
