import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { getSubdomain } from 'utils/urls';
import { State } from 'lib/enums';
import {
  ENV,
  PROD,
  DEMO,
  DEV,
  LOGROCKET_KEY,
  SENTRY_DSN,
  HEAP_KEY,
  IS_LOCALHOST,
  RECORD
} from '../constants';

export default function* root() {
  if (ENV === PROD || ENV === DEMO) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENV
    });
  }

  const sanitizeLogRocketRequests = (request: any) => {
    if (request.url.toLowerCase().indexOf('register') !== -1) {
      request.body = null;
    }
    return request;
  };

  if (
    ENV === PROD ||
    ENV === DEMO ||
    (ENV === DEV && !IS_LOCALHOST && RECORD)
  ) {
    LogRocket.init(LOGROCKET_KEY, {
      network: {
        requestSanitizer: sanitizeLogRocketRequests
      }
    });
    try {
      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    } catch (e) {
      console.log(`Unable to link LogRocket session to Sentry ${e}`);
    }
  }

  if (ENV === PROD) {
    (window as any).heap.load(HEAP_KEY);
  }

  const shouldDisplayChat = ENV === PROD || ENV === DEMO;

  if (shouldDisplayChat) {
    if (
      window.location.href.indexOf('/search') === -1 &&
      !(State as any)[getSubdomain()]
    ) {
      (function() {
        const scriptTag0 = document.createElement('script');
        scriptTag0.type = 'text/javascript';
        scriptTag0.async = true;
        scriptTag0.innerText =
          '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
        const scriptTag1 = document.createElement('script');
        scriptTag1.type = 'text/javascript';
        scriptTag1.async = true;
        scriptTag1.innerText =
          "window.Beacon('init', 'de7afdd6-c3c9-4825-a4df-10fcd06f92e8')";
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(scriptTag0, s);
        s.parentNode.insertBefore(scriptTag1, s);
      })();
    }
  }
  return true;
}
