export default {
  dynamicHeader: {
    value: 'dynamic-header',
    key: 'dynamicHeader',
    replacements: {
      numRuns: {
        regexMatch: '{{numRuns}}'
      },
      dateString: {
        regexMatch: '{{dateString (.+)}}'
      }
    }
  }
};
