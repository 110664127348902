import React, { ReactNode } from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import amber from '@material-ui/core/colors/amber';
import { ESnapshotExists, EOrganization } from 'lib/types';
import { mergeDeep } from './utils/funcs';
import computeColors from './lib/theme';

const defaultPrimary = computeColors('#2d9bdb') as any;
const nonProgrammaticDarkBlue = '#24415b';
defaultPrimary[900] = nonProgrammaticDarkBlue;

type EnoticeThemeProps = {
  children: ReactNode;
  orgContext?: ESnapshotExists<EOrganization>;
};

const EnoticeTheme: React.FC<EnoticeThemeProps> = ({
  children,
  orgContext
}) => {
  const createTheme = () => {
    const defaultTheme = {
      typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"'
        ].join(','),
        subtitle2: {
          fontWeight: 200
        }
      },
      palette: {
        primary: defaultPrimary,
        secondary: amber,
        grey: {
          button: '#efefef',
          inputBackground: 'rgba(255, 255, 255, 0.25)',
          scrollFormHeading: 'rgba(0, 0, 0, 0.54)',
          scrollFormBorder: `1px solid rgba(0, 0, 0, 0.1)`,
          scrollFormInteractiveElt: 'rgb(218, 218, 218)',
          scrollFormInteractiveEltHov: 'rgb(190, 190, 190)',
          buttonDark: '#dadada'
        },
        tertiary: {
          main: '#e24f21',
          600: '#cc481f'
        }
      }
    };
    const customTheme = orgContext ? orgContext.data().theme || {} : {};
    try {
      return createMuiTheme(mergeDeep(defaultTheme, customTheme));
    } catch (err) {
      return createMuiTheme(defaultTheme as any);
    }
  };
  const theme = createTheme();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const mapStateToProps = (state: any) => ({
  orgContext: state.auth.orgContext
});

export default connect(mapStateToProps)(EnoticeTheme);
