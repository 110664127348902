import handlebars from 'handlebars';
import moment from 'moment';
import { FirebaseTimestamp } from '../types';
import { dateTimeLikeToDate, dateTimeLikeToTimestamp } from '../date';
import {
  DateParseError,
  MissingParameters
} from '../errors/NoticePreviewErrors';

const default_conjunctions: Conjunctions = { default: ',', final: ' and' };
type Conjunctions = {
  default: string;
  final: string;
  months?: string;
  spaces?: string;
};

const getDelimiter = (
  conjunctions: Conjunctions,
  index: number,
  total: number
) => {
  const DEFAULT_CONJUCT = conjunctions.default;
  const FINAL_CONJUCT = conjunctions.final;

  if (DEFAULT_CONJUCT && FINAL_CONJUCT === undefined) return DEFAULT_CONJUCT;

  if (total === 2) {
    if (index === 0) return `${FINAL_CONJUCT}`;
  }
  if (index === total - 2) return `${FINAL_CONJUCT}`;
  if (index === total - 1) return '';

  return DEFAULT_CONJUCT;
};

const getConjunction = (
  index: number,
  publicationDates: FirebaseTimestamp[],
  conjunctions: Conjunctions
) => {
  if (
    !conjunctions ||
    !Array.isArray(publicationDates) ||
    !publicationDates[index]
  )
    return undefined;

  if (!publicationDates || publicationDates.length === 0) return '';

  const currentMonth = publicationDates[index].toDate().getMonth();
  const isFinalDateInMonth =
    publicationDates
      .slice(index + 1)
      .filter((date: FirebaseTimestamp) =>
        date ? date.toDate().getMonth() === currentMonth : false
      ).length === 0;

  const areMoreDays = publicationDates.slice(index + 1).length;

  // return the month separator
  if (isFinalDateInMonth && areMoreDays && conjunctions.months !== undefined) {
    return conjunctions.months;
  }

  // e.g. return ',' or ', and' depending on where we are in the list
  const delim = `${getDelimiter(conjunctions, index, publicationDates.length)}`;
  if (delim) return `${delim}${conjunctions.spaces === 'false' ? '' : ' '}`;

  return undefined;
};

const dayTokens = /(Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?)/g;
const monthTokens = /(Mo|MM?M?M?)/g;
const yearTokens = /(YYYYYY|YYYYY|YYYY|YY)/g;

export const squash = (
  footerTag: string,
  publicationDates: FirebaseTimestamp[],
  conjunctions?: Conjunctions
) => {
  let monthFormat: string;
  let dayFormat: string;
  let yearFormat: string | null;
  let monthDecorators = false;

  if (!publicationDates || publicationDates.length === 0) return '';

  try {
    [monthFormat] = footerTag.match(monthTokens) as RegExpMatchArray;

    // ..{(***}... -> ***
    const monthAndDecorators = footerTag.match(/\{.*?\}/);
    if (
      Array.isArray(monthAndDecorators) &&
      typeof monthAndDecorators[0] === 'string'
    ) {
      monthDecorators = true;
      monthFormat = monthAndDecorators[0].slice(1, -1);
    }
  } catch (err) {
    throw new DateParseError('month', footerTag);
  }

  try {
    [dayFormat] = footerTag.match(dayTokens) as RegExpMatchArray;
  } catch (err) {
    throw new DateParseError('day', footerTag);
  }

  try {
    [yearFormat] = footerTag.match(yearTokens) as RegExpMatchArray;
  } catch (err) {
    yearFormat = null;
  }

  const firstDate = publicationDates[0].toDate();
  let currentYear = firstDate.getFullYear();
  let currentMonth = firstDate.getMonth();

  let formattedString = `${moment(firstDate).format(monthFormat)}${
    monthDecorators ? '' : ' '
  }`;

  for (const [i, timestamp] of (publicationDates || []).entries()) {
    const date = timestamp.toDate();

    if (date.getFullYear() !== currentYear) {
      if (yearFormat)
        formattedString += `${moment(firstDate).format(yearFormat)}, `;
    }

    if (date.getMonth() !== currentMonth) {
      formattedString += `${moment(date).format(monthFormat)}${
        monthDecorators ? '' : ' '
      }`;
    }

    formattedString += moment(date).format(dayFormat);
    formattedString += getConjunction(i, publicationDates, conjunctions!) || '';

    currentYear = date.getFullYear();
    currentMonth = date.getMonth();
  }

  if (yearFormat) formattedString += ', ';

  const lastDate = publicationDates[publicationDates.length - 1].toDate();
  formattedString += yearFormat ? moment(lastDate).format(yearFormat) : '';
  return formattedString.trim();
};

export const date = (
  footerTag: string,
  publicationDates: FirebaseTimestamp[],
  conjunctions?: Conjunctions
) => {
  let format: string;
  let separator: string;

  if (!publicationDates || publicationDates.length === 0) return '';

  try {
    // ...(***)... -> ***
    format = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
  } catch (err) {
    throw new DateParseError('date', footerTag);
  }
  try {
    // ...[***]... -> ***
    separator = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
  } catch (err) {
    separator = '';
  }

  if (conjunctions) {
    let formattedString = '';

    for (const [i] of (publicationDates || []).entries()) {
      const d = publicationDates[i];
      const delim = `${getDelimiter(conjunctions, i, publicationDates.length)}`;
      formattedString += moment(d.toDate()).format(format);
      if (delim) {
        formattedString +=
          conjunctions.spaces === 'false' ? `${delim}` : `${delim} `;
      }
    }
    return formattedString;
  }

  return publicationDates
    .map(t => `${moment(t.toDate()).format(format)}`)
    .join(separator);
};

handlebars.registerHelper('ARRAY', function(...args: any[]) {
  return Array.prototype.slice.call(args, 0, -1);
});

handlebars.registerHelper('SLICE', function(
  arr: string[],
  start: number,
  finish: number
) {
  return (arr || []).slice(
    start,
    typeof finish === 'number' ? finish : undefined
  );
});

handlebars.registerHelper('SQUASH_DATES', (a: any) => {
  const {
    hash: { dates, format, conjunctions }
  } = a;

  if (!dates || !dates.length) return '';

  const parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;

  return squash(format, dates, parsedConjuct || default_conjunctions);
});

handlebars.registerHelper('FORMAT_DATES', (a: any) => {
  if (!a) throw new MissingParameters();
  const {
    hash: { dates, format, conjunctions }
  } = a;

  if (!dates || dates.length === 0) return '';

  if (dates && dates.length === 1)
    return moment(dateTimeLikeToDate(dates[0])!).format(format);

  const parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;

  return date(`(${format})`, dates, parsedConjuct || default_conjunctions);
});

handlebars.registerHelper(
  'ADD_DATES',
  (dates: any, days: string, months: string, years: string) => {
    return (dates || []).map((d: any) => {
      const m = moment(dateTimeLikeToDate(d) as Date);
      if (days) m.add(days, 'days');
      if (months) m.add(months, 'months');
      if (years) m.add(years, 'years');
      return dateTimeLikeToTimestamp(m.toDate());
    });
  }
);

handlebars.registerHelper('FORMAT_MONEY', (value: number) => {
  if (!value) return '';
  return parseFloat(`${value}`).toFixed(2);
});

handlebars.registerHelper('ROUND', (value: number, digits: number) => {
  if (!digits) return value;
  return parseFloat(`${value}`).toFixed(digits);
});

handlebars.registerHelper('FORMAT_DATE', function(
  dateTimeLike: any,
  format: any
) {
  if (!dateTimeLike) return '';
  const date = dateTimeLikeToDate(dateTimeLike);
  return moment(date!).format(format);
});

handlebars.registerHelper('GET_INDEX', (array: any[], index: number) => {
  if (!array?.length) return null;
  return array[index];
});

handlebars.registerHelper('LENGTH', (a: any) => {
  return (a || []).length;
});

handlebars.registerHelper('MATH', function(
  lv: string,
  operator: string,
  rv: string
) {
  const lvalue = parseFloat(lv);
  const rvalue = parseFloat(rv);
  return ({
    '+': lvalue + rvalue,
    '-': lvalue - rvalue,
    '*': lvalue * rvalue,
    '/': lvalue / rvalue,
    '%': lvalue % rvalue
  } as string | any)[operator];
});

handlebars.registerHelper('UPPERCASE', (a: string) => {
  return a ? a.toUpperCase() : a;
});

handlebars.registerHelper('FIRST', (...a: any[]) => {
  return a.find(Boolean) || '';
});

handlebars.registerHelper('LAST', (a: any[]) => {
  return [a[a.length - 1]];
});

handlebars.registerHelper('times', function(n: any, block: any) {
  let accum = '';
  for (let i = 0; i < n; ++i) accum += block.fn(i);
  return accum;
});

handlebars.registerHelper('isdefined', function(value: any) {
  return value !== undefined;
});

handlebars.registerHelper('equals', function(arg1: any, arg2: any) {
  return arg1 === arg2;
});

export const EHandlebars = handlebars;
