import React from 'react';

const Tooltip = ({
  step,
  index,
  primaryProps,
  tooltipProps,
  backProps
}: any) => {
  return (
    <div
      {...tooltipProps}
      className="relative flex flex-col content-end justify-end items-end bg-white w-full rounded-lg px-8 py-5 overflow-hidden shadow-xl transform transition-all max-w-2xl"
    >
      {step.title && (
        <div className="w-full font-semibold text-lg text-gray-900 mb-4 flex justify-between">
          {step.title}
        </div>
      )}
      <div className="font-normal text-sm text-gray-700 mr-4">
        {step.content}
      </div>
      <div className="flex justify-center aligned-center mt-4">
        {index > 0 && (
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 hover:text-blue-800 bg-transparent transition duration-150 ease-in-out"
            {...backProps}
          >
            Previous
          </button>
        )}
        <button
          type="button"
          className="hover:bg-blue-800 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-900 transition duration-150 ease-in-out"
          {...primaryProps}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Tooltip;
