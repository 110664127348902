import { ESnapshot } from 'lib/types/firebase';
import { EJoyRide } from 'lib/types/joyride';
import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setActiveJoyRide: ['activeJoyRide', 'callback'],
  setJoyRides: ['joyRides'],
  markJoyRideRead: [],
  setNewJoyRidePossible: []
});

export const JoyRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type JoyrideState = {
  activeJoyRide: ESnapshot<EJoyRide>;
  callback: (e: any) => void;
  steps: any[];
  joyRides: ESnapshot<EJoyRide>[];
};

const INITIAL_STATE: JoyrideState = {
  activeJoyRide: null,
  callback: () => {},
  steps: [],
  joyRides: []
};

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_JOY_RIDE]: (
    state: JoyrideState,
    { activeJoyRide, callback, steps }
  ): JoyrideState => ({
    ...state,
    activeJoyRide,
    callback,
    steps
  }),
  [Types.SET_JOY_RIDES]: (state: JoyrideState, { joyRides }): JoyrideState => ({
    ...state,
    joyRides
  }),
  [Types.MARK_JOY_RIDE_READ]: (state: JoyrideState): JoyrideState => ({
    ...state
  }),
  [Types.SET_NEW_JOY_RIDE_POSSIBLE]: (state: JoyrideState): JoyrideState => ({
    ...state
  })
});

export const joyrideSelector = (state: any) => state.joyrides as JoyrideState;
