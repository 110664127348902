import {
  all,
  takeEvery,
  takeLatest,
  select,
  put,
  call,
  delay
} from 'redux-saga/effects';

import { ESnapshotExists, EUser } from 'lib/types';
import api from 'api';
import { TableNotice } from 'lib/types/table';
import { authSelector, AuthState, AuthTypes } from '../redux/auth';
import { OccupationType } from '../lib/enums';
import NoticeActions, { NoticeTypes, noticesSelector } from '../redux/notices';

function* getQuery(tab: number, hideLoading: boolean) {
  const { user, isPublisher, showAllOrgsNotices } = yield select(authSelector);

  const getNotices = async () =>
    api.post('notices/table', {
      showAllOrgsNotices,
      userId: user?.id,
      isPublisher,
      tab
    });
  console.log('BEFORE GET NOTICE CALLL');
  if (!hideLoading) {
    yield put(NoticeActions.setFetching(true));
  }
  const notices = yield call(getNotices);
  yield put(NoticeActions.setFetching(false));

  return notices;
}

function* updateNotices(hideLoading = false) {
  const auth: AuthState = yield select(authSelector);
  if (!auth.user) return;
  if (
    auth.user.data().occupation !== OccupationType.individual.value &&
    !auth.activeOrganization
  )
    return;
  const activeNotices: TableNotice[] = yield call(getQuery, 0, hideLoading);
  yield put(NoticeActions.setActiveNotices(activeNotices));
}

function* updateTab() {
  const { archivedNotices, drafts, tab } = yield select(noticesSelector);
  yield put(NoticeActions.setPage(0));

  switch (tab) {
    case 1: {
      if (!archivedNotices) {
        const archivedNotices = yield call(getQuery, 1, false);
        yield put(NoticeActions.setArchivedNotices(archivedNotices));
      }
      break;
    }

    case 2: {
      if (!drafts) {
        const draftNotices = yield call(getQuery, 2, false);
        yield put(NoticeActions.setDrafts(draftNotices));
      }
      break;
    }

    default: {
    }
  }
}

function* resetNoticeTable() {
  yield put(NoticeActions.setActiveNotices(null));
  yield put(NoticeActions.setArchivedNotices(null));
  yield put(NoticeActions.setDrafts(null));
  yield put(NoticeActions.setTab(0));
}

function* setTableParameters({
  user
}: {
  user: null | ESnapshotExists<EUser>;
}) {
  if (!user) return;

  const { noticeTablePageSize } = user.data();
  if (!noticeTablePageSize) return;

  yield put(NoticeActions.setNumRows(noticeTablePageSize));
}

function* refreshActiveNotices() {
  while (true) {
    yield delay(60000);
    yield call(updateNotices, true);
  }
}

export default function* root() {
  yield all([
    takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, resetNoticeTable),
    takeLatest(
      [
        AuthTypes.SET_USER,
        NoticeTypes.UPDATE_NOTICES,
        AuthTypes.SET_ACTIVE_ORGANIZATION
      ],
      updateNotices
    ),
    takeEvery(AuthTypes.SHOW_ALL_ORGS_NOTICES, updateNotices),
    takeEvery(NoticeTypes.SET_TAB, updateTab),
    takeEvery(AuthTypes.SET_USER, setTableParameters)
  ]);
  yield call(refreshActiveNotices);
}
