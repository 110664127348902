import { EnoticeError, ErrorType } from './index';

export class DateParseError extends EnoticeError {
  errorType = `${ErrorType.NoticePreview.type}01`;

  errorCode = 500;

  name = `${ErrorType.NoticePreview.name}`;

  constructor(dateType: string, tag: string) {
    super(`Unable to parse ${dateType} format from tag: ${tag}`);
  }
}

export class UnknownDateFormat extends EnoticeError {
  errorType = `${ErrorType.NoticePreview.type}02`;

  errorCode = 500;

  name = `${ErrorType.NoticePreview.name}`;

  constructor(tag: string) {
    super(`Unknow date fromat from: ${tag}`);
  }
}

export class MissingParameters extends EnoticeError {
  errorType = `${ErrorType.NoticePreview.type}03`;

  errorCode = 400;

  name = `${ErrorType.NoticePreview.name}`;

  constructor() {
    super('Missing or bad parameters');
  }
}

export class ResponseError extends EnoticeError {
  errorType = `${ErrorType.NoticePreview.type}04`;

  errorCode = 400;

  name = `${ErrorType.NoticePreview.name}`;

  constructor(errorCode: number) {
    super(`Column Response Error: ${errorCode}`);
  }
}
