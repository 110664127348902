import sanitize from 'sanitize-html';
import moment from 'moment-timezone';
import convert from 'convert-length';
import firebase from 'firebase';

import axios, { AxiosRequestConfig } from 'axios';
import { FIREBASE_PROJECT, CLOUDINARY_BUCKET } from './constants';
import {
  FirebaseTimestamp,
  ENotice,
  ESnapshotExists,
  EOrganization,
  EInvoice,
  ENoticeDraft,
  ESnapshot,
  EUser,
  DisplayImageRequest,
  ETemplate
} from './types';

import {
  getDeadlineTimeForPaper,
  publishingDayEnumValuesFromDeadlines,
  disableNonPublishingDays
} from './utils/deadlines';
import {
  OrganizationType,
  OccupationType,
  RoleType,
  InvoiceStatus,
  NoticeStatusType,
  NoticeType,
  CurrencyType,
  Country
} from './enums';

import { CustomNoticeFilingType } from './types/organization';

import { FileType } from './types/upload';
import { getOrCreateCustomer } from './notice/customer';
import { IDRequest } from './types/notice';
import {
  shouldPreserveLongSequencesForNotice,
  htmlToIndesignHtml
} from './indesign/helpers';
import {
  addFooterXML,
  generateFormattedFooter
} from './headers_footers/footers';

export const getOccupationValFromOrganizationVal = (
  organizationVal: number
) => {
  const organizationType = OrganizationType.by_value(organizationVal);
  return (OccupationType as any)[organizationType.defaultOccupationKey].value;
};

export const getOrganizationValFromOccupationVal = (occupationVal: number) => {
  const occupationKey = OccupationType.by_value(occupationVal).key;
  return Object.values(OrganizationType).find(
    org => org && org.defaultOccupationKey === occupationKey
  ).value;
};

export const dateToDateString = (date: Date, timezone?: string) => {
  date.setHours(12);
  return moment(date)
    .tz(timezone || 'America/Chicago')
    .format(!timezone?.startsWith('America') ? 'D MMM YYYY' : 'MMM. D, YYYY');
};

export const unixTimeStampToDateString = (
  timestamp: number,
  timezone?: string
) => {
  return dateToDateString(new Date(timestamp * 1000), timezone);
};

export const dateToAbbrev = (date: Date, timezone?: string) => {
  if (!timezone) date.setHours(12);
  return moment(date)
    .tz(timezone || 'America/Chicago')
    .format(timezone && !timezone?.startsWith('America') ? 'D/M/YY' : 'M/D/YY');
};
export const dateToUtc = (date: Date) =>
  moment.utc(date).format('MMM. D, YYYY');

export const toLocaleString = (date: Date) =>
  moment(date).format('MMM. D, YYYY');

export const firestoreTimestampOrDateToDate = (
  date: { _seconds: number } | Date | FirebaseTimestamp | string
) => {
  try {
    if ((date as any)._seconds) return new Date((date as any)._seconds * 1000);
  } catch (err) {}

  if (!date) throw new Error('date is undefined');
  try {
    return (date as FirebaseTimestamp).toDate() as Date;
  } catch (err) {
    if ((date as FirebaseTimestamp).seconds)
      return new Date((date as FirebaseTimestamp).seconds * 1000);
    if (typeof date === 'number') return new Date(date);
    if (typeof date === 'string') return new Date(date);
    return date as Date;
  }
};

export const areSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};

export const escapeHTML = (str: string) => {
  const entityMap = new Map<string, string>(
    Object.entries({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    })
  );
  return str.replace(/[&<>"']/g, (tag: string) => entityMap.get(tag) || tag);
};

export const centsToDollarsString = (cents: number) => {
  return (cents / 100).toFixed(2);
};

export const confirmationNumberFromTransferId = (transferId: string) => {
  return transferId.slice(-8);
};

export const guidGenerator = () => {
  const S4 = function() {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const rateCodeGenerator = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

export const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const isValidDate = (d: Date) => {
  return !Number.isNaN(Date.parse(d.toDateString()));
};

export const closestDayFutureDay = (dates: Date[]) => {
  let closest = Infinity;
  const startOfToday = new Date(Date.now());
  startOfToday.setHours(0, 0, 0, 0);

  const comparableStartOfToday = startOfToday.getTime();

  dates.forEach(date => {
    const comparableDate = date.getTime();
    if (comparableDate >= comparableStartOfToday && comparableDate < closest) {
      closest = comparableDate;
    }
  });

  const close = new Date(closest);

  if (!isValidDate(close)) return null;
  return close;
};

export const lastPublicationDate = (dates: Date[]) => {
  const lastDate = dates[dates.length - 1];
  if (!lastDate) return null;
  if (!isValidDate(lastDate)) return null;
  return lastDate;
};

export const firstNoticePublicationDate = (
  noticeSnap: ESnapshotExists<ENotice>
) =>
  noticeSnap
    .data()
    .publicationDates.map(fbTime => fbTime.toDate())
    .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))[0];

export const lastNoticePublicationDate = (
  noticeSnap: ESnapshotExists<ENotice>
) =>
  noticeSnap
    .data()
    .publicationDates.map(fbTime => fbTime.toDate() as Date)
    .sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))[0];

export const canPublisherEditNoticeWithoutSupport = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  if (!noticeSnap.data().publicationDates) return true;
  return new Date() < firstNoticePublicationDate(noticeSnap);
};

export const canAdvertiserEditNoticeWithoutSupport = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const publicationDayEnumValues = publishingDayEnumValuesFromDeadlines(
    newspaperSnap.data().deadlines
  );

  // get any unusual publication dates that might have been edited in by the publisher
  const customPublicationDate = noticeSnap.data().publicationDates.find(date =>
    // returns true if date isn't a valid publication date
    disableNonPublishingDays(
      date.toDate(),
      publicationDayEnumValues,
      newspaperSnap.data().deadlineOverrides
    )
  );

  // if there is an unusual publication date, the advertiser cannot edit this notice
  if (customPublicationDate) return false;

  const nearestDeadline = getDeadlineTimeForPaper(
    firstNoticePublicationDate(noticeSnap),
    newspaperSnap.data().deadlines,
    newspaperSnap.data().iana_timezone,
    noticeSnap.data(),
    newspaperSnap
  );

  return moment(new Date()).isBefore(nearestDeadline);
};

export const canEditNoticeWithoutSupport = async (
  noticeSnap: ESnapshotExists<ENotice>,
  userSnap: ESnapshotExists<EUser>
) => {
  if (userSnap?.data()?.role === RoleType.super.value) return true;

  if (noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  if (userSnap.data().occupation === OccupationType.publishing.value)
    return await canPublisherEditNoticeWithoutSupport(noticeSnap);
  return await canAdvertiserEditNoticeWithoutSupport(noticeSnap);
};

export const canCancelInvoiceWithoutSupport = (
  invoice: ESnapshotExists<EInvoice> | null
) => {
  return (
    invoice?.data() &&
    (invoice.data().status === InvoiceStatus.unpaid.value ||
      invoice.data().status === InvoiceStatus.payment_failed.value ||
      invoice.data().invoiceOutsideColumn)
  );
};

export const assetQuality = {
  high: {
    width: 1000
  }
};

export const cdnIfy = (
  storagePath: string,
  options: Record<string, any> = {}
) => {
  const fileType = storagePath && (storagePath.split('.').pop() as any);

  const requiresImgix = [
    FileType.IDML,
    FileType.HTML,
    FileType.CSV,
    FileType.WORD_DOC,
    FileType.TIF
  ].includes(fileType);
  if (requiresImgix)
    return `https://${FIREBASE_PROJECT}.imgix.net/${storagePath}`;

  const isImage = [
    FileType.TIF,
    FileType.PNG,
    FileType.JPG,
    FileType.JPEG,
    FileType.PDF
  ].includes(fileType);
  if (!FIREBASE_PROJECT)
    throw new Error('Cannot create CDN link without firebase project');
  if (isImage) {
    return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/image/upload${
      options?.transformations ? `/${options.transformations}` : ''
    }/${FIREBASE_PROJECT}/${storagePath}?invalidate=true`;
  }
  return `https://res.cloudinary.com/${CLOUDINARY_BUCKET}/raw/upload/${FIREBASE_PROJECT}/${storagePath}?invalidate=true`;
};

export const preventXSS = (html: string) =>
  sanitize(html, {
    allowedTags: ['div', 'strong', 'ais-highlight-0000000000']
  });

type GetNoticeTypeOptions = {
  skipDisplayType?: boolean;
};

export const getNoticeTypeFromNoticeData = (
  notice: Partial<ENoticeDraft>,
  newspaper: ESnapshot<EOrganization>,
  options?: GetNoticeTypeOptions
) => {
  const newspaperSpecificType = newspaper
    ?.data()
    ?.allowedNotices?.find(type => {
      if (
        notice.noticeType === NoticeType.display_ad.value &&
        options?.skipDisplayType &&
        notice.previousNoticeType
      )
        return type.value === notice.previousNoticeType;
      return type.value === notice.noticeType;
    });

  if (newspaperSpecificType) return newspaperSpecificType;

  const noticeType = NoticeType.by_value(notice.noticeType);
  if (noticeType) {
    return noticeType as CustomNoticeFilingType;
  }

  return null;
};

export const getNoticeType = (
  noticeSnap: Pick<ESnapshot<Partial<ENoticeDraft>>, 'data'>,
  newspaperSnap: ESnapshot<EOrganization>,
  options?: GetNoticeTypeOptions
) => {
  if (!noticeSnap?.data())
    return (NoticeType.custom as unknown) as CustomNoticeFilingType;
  return getNoticeTypeFromNoticeData(
    noticeSnap.data()!,
    newspaperSnap,
    options
  );
};

export const DEFAULT_DPI = 300;

export const inchesToPixels = (
  inches: number,
  cols: number,
  ppi?: number
): number => {
  const PPI = { pixelsPerInch: ppi || DEFAULT_DPI };
  return convert(inches, 'in', 'px', PPI);
};

export const noticeNeedsUpFrontInvoice = (
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  if (!newspaper.data().allowedNotices) return false;
  if (notice.data().noticeType === NoticeType.custom.value) return false;
  if (notice.data().noticeType === NoticeType.display_ad.value) return false;
  if (notice.data().invoice) return false;
  if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  const typeform = newspaper
    .data()
    .allowedNotices!.find(an => an.value === notice.data().noticeType)
    ?.typeform;

  if (!typeform) return false;

  return true;
};
/**
 * A notice should be auto-invoiced if:
 *  - autoInvoice is set on the org or notice type, AND
 *  - notice is not custom or cancelled
 * @param noticeSnap
 * @returns {Promise<boolean>}
 */
export const shouldAutoInvoice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const parentSnap = (await newspaperSnap
    .data()
    .parent?.get()) as ESnapshotExists<EOrganization>;
  const rateSnap = await noticeSnap.data().rate?.get();

  return (
    (newspaperSnap.data().autoInvoice ||
      parentSnap?.data()?.autoInvoice ||
      rateSnap?.data()?.autoInvoice ||
      !!newspaperSnap
        .data()
        .allowedNotices?.find(
          an =>
            an.value === noticeSnap.data().noticeType && (an as any).autoInvoice
        )) &&
    noticeSnap.data().noticeType !== NoticeType.custom.value &&
    noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value &&
    !noticeSnap.data().invoice
  );
};

export const toggleInvoiceOutsideColumnForNoticeType = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  return (
    !!newspaperSnap
      .data()
      .allowedNotices?.find(
        an =>
          an.value === noticeSnap.data().noticeType &&
          (an as any).invoiceOutsideColumn
      ) &&
    !!newspaperSnap.data().allowInvoiceOutsideColumn &&
    noticeSnap.data().noticeType !== NoticeType.custom.value &&
    noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value
  );
};

/**
 * A notice should be set to invoice outside column if:
 *  - invoiceOutsideColumn is set on the notice type, AND
 *  - allowInvoiceOutsideColumn is set to true on newspaper level
 *  - invoicedOutsideColumn is set to true on notice
 *  - notice is not custom and cancelled
 * @param noticeSnap
 * @returns {Promise<boolean>}
 */
export const invoicedOutsideColumnForNoticeType = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const invoiceTypeForNotice =
    !!newspaperSnap
      .data()
      .allowedNotices?.find(
        an =>
          an.value === noticeSnap.data().noticeType &&
          (an as any).invoiceOutsideColumn
      ) &&
    !!newspaperSnap.data().allowInvoiceOutsideColumn &&
    noticeSnap.data().noticeType !== NoticeType.custom.value &&
    noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value;
  let invoicing: boolean;
  if (
    newspaperSnap
      .data()
      .allowedNotices?.find(
        an =>
          an.value === noticeSnap.data().noticeType && (an as any).autoInvoice
      )
  ) {
    invoicing = invoiceTypeForNotice;
  } else {
    invoicing =
      invoiceTypeForNotice && !!noticeSnap.data()?.invoicedOutsideColumn;
  }
  return invoicing;
};

export const shouldReleaseAffidavit = async (
  noticeSnap: ESnapshotExists<ENotice>,
  invoiceSnap: ESnapshot<EInvoice> | undefined,
  advertiserSnap: ESnapshotExists<EUser>,
  advertiserOrgSnap: ESnapshot<EOrganization>,
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  if (!noticeSnap.data().affidavit) return false;

  const customer = await getOrCreateCustomer(advertiserSnap, newspaperSnap);

  const alwaysAllowAffidavitDownload =
    advertiserSnap.data()?.alwaysAllowAffidavitDownload ||
    advertiserOrgSnap?.data()?.alwaysAllowAffidavitDownload ||
    newspaperSnap.data()?.alwaysAllowAffidavitDownload ||
    customer.data().enableAffidavitsBeforePayment;

  const isInvoicePaid =
    invoiceSnap?.data()?.status === InvoiceStatus.paid.value;

  if (isInvoicePaid) return true;
  if (alwaysAllowAffidavitDownload) return true;
  return false;
};

export const shouldReleaseAffidavitForNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const invoiceSnap = await noticeSnap.data().invoice?.get();
  const advertiserSnap = (await noticeSnap
    .data()
    .filer?.get()) as ESnapshotExists<EUser>;
  const advertiserOrgSnap = await advertiserSnap.data().organization?.get();
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;

  return shouldReleaseAffidavit(
    noticeSnap,
    invoiceSnap,
    advertiserSnap,
    advertiserOrgSnap,
    newspaperSnap
  );
};

export const getCustomAffidavit = async (
  noticeSnap: ESnapshotExists<ENotice>,
  userSnap: ESnapshotExists<EUser>,
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const noticeType = getNoticeType(noticeSnap, newspaperSnap);
  const rate = await noticeSnap.data().rate?.get();

  return (
    noticeSnap.data().customAffidavit ||
    userSnap.data()?.customAffidavit ||
    rate?.data()?.customAffidavit ||
    noticeType?.customAffidavit ||
    newspaperSnap.data()?.customAffidavit
  );
};

/**
 * This function Ensures that dates passed from frontend to backend are same
 * @param date Date string from new Date().toDateString();
 * @returns newDate;
 */
export const ensureSameDate = (date: string) => {
  if (!moment(date, 'ddd MMM DD YYYY', true).isValid())
    throw new Error('Invalid Date Format');
  return moment(date).toDate();
};

export const getCurrencySymbol = (currency: any) => {
  if (!currency) {
    return CurrencyType.usd.symbol;
  }
  return CurrencyType[currency.toLowerCase()].symbol;
};

export const getLocalizedDescription = (notification: any, country: number) => {
  if (
    notification.localized_description &&
    notification.localized_description[Country.by_value(country).key]
  ) {
    return notification.localized_description[Country.by_value(country).key];
  }
  return notification.description;
};

export const showConvertToLinerWarning = (
  displayOnlyAds: boolean,
  isDisplay: boolean,
  showDisplayError: boolean,
  postWithoutFormatting: boolean
) => {
  return (
    !displayOnlyAds && isDisplay && showDisplayError && !postWithoutFormatting
  );
};

export const isPayingInvoiceThroughSupport = (change: {
  before: firebase.firestore.DocumentData;
  after: firebase.firestore.DocumentData;
}) => {
  if (!change.before || !change.after) return false;
  if (change.before.data()?.payBySupport) return false;
  if (!change.after.data().payBySupport) return false;
  return true;
};

export const getNoticeRequiresUpfrontPayment = async (
  noticeSnap: ESnapshotExists<ENotice>
) => {
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const advertiserSnap = (await noticeSnap
    .data()
    .filer.get()) as ESnapshotExists<EUser>;
  const rateSnap = await noticeSnap.data()?.rate?.get();

  const customer = await getOrCreateCustomer(advertiserSnap, newspaperSnap);

  if (customer.data().requireUpfrontPayment === false) return false;
  if (
    newspaperSnap.data().requireUpfrontPayment ||
    customer.data().requireUpfrontPayment
  )
    return true;
  if (rateSnap?.data()?.requireUpfrontPayment) return true;
  const noticeType = getNoticeType(noticeSnap, newspaperSnap);
  if (noticeType && noticeType.upFrontPayment) {
    return true;
  }

  return false;
};

export const getDueDate = async (
  noticeSnap: ESnapshotExists<ENotice>,
  requireUpfrontPayment?: boolean
) => {
  const advertiserSnap = (await noticeSnap
    .data()
    .filer.get()) as ESnapshotExists<EUser>;
  const newspaperSnap = (await noticeSnap
    .data()
    .newspaper.get()) as ESnapshotExists<EOrganization>;
  const { deadlines, iana_timezone } = newspaperSnap.data() as EOrganization;

  if (
    newspaperSnap &&
    newspaperSnap.data().bulkPaymentEnabled &&
    advertiserSnap &&
    advertiserSnap.data().bulkPaymentEnabled
  ) {
    const endOfNextMonth =
      moment()
        .tz(iana_timezone)
        .add(2, 'M')
        .startOf('month')
        .toDate()
        .getTime() - 2000;
    return endOfNextMonth / 1000;
  }
  if (requireUpfrontPayment) {
    const closestPublicationDate: Date = firstNoticePublicationDate(noticeSnap);
    const deadline: moment.Moment = getDeadlineTimeForPaper(
      closestPublicationDate,
      deadlines,
      iana_timezone,
      noticeSnap.data(),
      newspaperSnap
    );
    return deadline.unix() - 2;
  }

  return (
    moment()
      .tz(iana_timezone)
      .add(1, 'M')
      .toDate()
      .getTime() /
      1000 -
    2
  );
};
export const standardizePhoneNumber = async (phoneNumberString: any) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumberString;
};

export const validatePhoneNumbers = async (phoneNumberString: any) => {
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  return !!phoneNumberString.match(phoneRegex);
};

export const getPhoneNumberFromNotice = async (
  notice: ESnapshotExists<ENotice>
) => {
  const userSnap = (await notice.data().filer.get()) as ESnapshotExists<EUser>;
  const activeOrganizationSnap = (await userSnap
    .data()
    .activeOrganization?.get()) as ESnapshotExists<EOrganization>;
  const savedPhone =
    userSnap.data().occupation === OccupationType.publishing.value
      ? activeOrganizationSnap?.data()?.phone
      : userSnap.data()?.phone || activeOrganizationSnap?.data()?.phone;

  const standardizedPhone = await standardizePhoneNumber(savedPhone);
  if (savedPhone !== standardizedPhone) {
    if (userSnap.data().occupation === OccupationType.publishing.value) {
      await activeOrganizationSnap.ref.update({
        phone: standardizedPhone
      });
    } else {
      await userSnap.ref.update({
        phone: standardizedPhone
      });
    }
  }

  return standardizedPhone;
};

export const getXMLExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const newspaperXMLExport = newspaperSnap.data().xmlExport;
  if (newspaperXMLExport) return newspaperXMLExport;

  const parent = await newspaperSnap.data().parent?.get();
  if (parent?.data()?.xmlExport) return parent.data()?.xmlExport;

  throw new Error(
    `XML export information not specified for paper ${
      newspaperSnap.data().name
    } with ID: ${newspaperSnap.id}`
  );
};

export const getExportSettings = async (
  newspaperSnap: ESnapshotExists<EOrganization>
) => {
  const newspaperFTP = newspaperSnap.data().bulkDownload?.ftp!;
  if (newspaperFTP) return newspaperFTP;

  const parent = await newspaperSnap.data().parent?.get();
  if (parent?.data()?.bulkDownload?.ftp)
    return parent.data()?.bulkDownload?.ftp!;

  throw new Error(
    `FTP export information not specified for paper ${
      newspaperSnap.data().name
    } with ID: ${newspaperSnap.id}`
  );
};

export const getShouldShowUpFrontBilling = (
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>,
  invoice: ESnapshotExists<EInvoice> | null,
  isInvoicedOutsideColumn: boolean | undefined
) => {
  // do not show if invoice hasn't been created or if notice is invoiced outside Column
  if (!invoice || isInvoicedOutsideColumn === undefined) return false;
  if (isInvoicedOutsideColumn) return false;

  // don't run if notice has been cancelled
  if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  // don't run if the invoice has already been paid
  if (invoice?.data()?.status === InvoiceStatus.paid.value) return false;

  // if toggled off on the notice level
  if (notice.data().requireUpfrontPayment !== undefined)
    return notice.data().requireUpfrontPayment;

  const noticeType = getNoticeType(notice, newspaper);

  // short circuit if we are specifically setting the property
  if (noticeType?.upFrontPayment !== undefined)
    return noticeType.upFrontPayment;

  if (newspaper.data().requireUpfrontPayment)
    return newspaper.data().requireUpfrontPayment;

  return false;
};

// count bold words from confirmedHtml
export const getBoldWords = (html: string, DOMparser: typeof DOMParser) => {
  const space = /\s/;
  const doc = new DOMparser().parseFromString(html, 'text/html');

  let totalBoldWords = 0;
  const totalBoldElements: string[] = [];
  const elementsWithStrongTag = doc.getElementsByTagName('strong');
  const elementsWithBTag = doc.getElementsByTagName('b');

  for (let i = 0; i < elementsWithStrongTag.length; i++) {
    totalBoldElements.push(elementsWithStrongTag?.[i].innerHTML);
  }

  for (let i = 0; i < elementsWithBTag.length; i++) {
    totalBoldElements.push(elementsWithBTag?.[i].innerHTML);
  }

  if (!totalBoldElements.length) return 0;
  for (let i = 0; i < totalBoldElements.length; i++) {
    if (space.test(totalBoldElements[i])) {
      const splitText = totalBoldElements[i]
        .split(' ')
        .filter((elem: string) => elem !== '');
      totalBoldWords += splitText.length;
    } else totalBoldWords += 1;
  }

  return totalBoldWords;
};

export const isPastDueInNewspaperTimezone = (
  dueDate: moment.Moment,
  timezone: string,
  now: moment.Moment
) => {
  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_DAY = 86400;
  const MILLISECONDS_IN_SECOND = 1000;
  const dueDateNewspaperTimeZone = dueDate.tz(timezone);
  const offset = dueDateNewspaperTimeZone.utcOffset() * SECONDS_IN_MINUTE;
  return now.isAfter(
    moment(
      (Math.ceil(dueDateNewspaperTimeZone.unix() / SECONDS_IN_DAY) *
        SECONDS_IN_DAY -
        offset) *
        MILLISECONDS_IN_SECOND
    )
  );
};

// check if upload file type is accepted

export const getFileExtension = (fileName: string) => {
  return fileName
    ?.split('.')
    ?.pop()
    ?.toLowerCase() as string;
};

export const isValidExtension = (
  currentFileName: string,
  validExtensions: string
) => {
  const extension = getFileExtension(currentFileName);

  if (validExtensions.includes(extension)) {
    return true;
  }
  return false;
};

export const getNoticeNumberAndCustomIdFromNewspaper = (
  newspaper: ESnapshot<EOrganization>
): { currentNumber: number; customId: string } => {
  const currentNumber = newspaper.data()?.numberOfOrders || 1;
  const numberOfDigits =
    newspaper.data()?.orderNumberGeneration?.orderNumDigitCount || 4;
  const numOfZeros =
    numberOfDigits - `${currentNumber}`.length > 0
      ? numberOfDigits - `${currentNumber}`.length
      : 0;
  const prefix = newspaper.data()?.orderNumberGeneration?.orderNumPrefix || '';
  const suffix = newspaper.data()?.orderNumberGeneration?.orderNumSuffix || '';
  const customId = `${prefix}${'0'.repeat(
    numOfZeros
  )}${currentNumber}${suffix}`;
  return {
    currentNumber,
    customId
  };
};

export const sendToRenderingEngine = async ({
  url,
  request,
  options
}: {
  url: string;
  request: DisplayImageRequest | IDRequest;
  options?: AxiosRequestConfig;
}) => {
  return axios.post(url, request, options || {});
};

type DocumentRequestOptions = {
  format: 'jpg' | 'rtf' | 'idml' | 'pdf';
  type: 'RAW' | 'DISPLAY_PARAMETERS';
  url: string;
  optimizeColumns?: boolean;
};

export const requestDocument = async (
  notice: ESnapshotExists<ENotice> | ESnapshot<ENotice>,
  options: DocumentRequestOptions,
  DOMparser: typeof DOMParser
) => {
  if (!notice.exists) {
    throw new Error(`Notice not found`);
  }

  const { format, type, url, optimizeColumns } = options;
  const adTemplate = (await notice.data().adTemplate.get()) as ESnapshotExists<
    ETemplate
  >;
  const newspaper = (await notice.data().newspaper.get()) as ESnapshotExists<
    EOrganization
  >;

  const generatedFooter = await generateFormattedFooter(notice);

  const {
    confirmedHtml,
    columns,
    dynamicHeaders,
    publicationDates
  } = notice.data()!;

  const { isFirstPHeading, downloadUrl, icmlSubstitutions } = adTemplate.data();

  const { linerMaxColumns } = newspaper.data();
  const preserveLongSequences = shouldPreserveLongSequencesForNotice(notice);

  const dynamicFooter = notice.data().dynamicFooter || generatedFooter;

  const xmlFooter =
    dynamicFooter && dynamicFooter.length > 0
      ? addFooterXML(dynamicFooter)
      : '';

  if (!notice.data().dynamicFooter) {
    notice.ref.update({ dynamicFooter: xmlFooter });
  }

  const request = {
    id: adTemplate.id,
    downloadUrl,
    html:
      htmlToIndesignHtml(
        confirmedHtml,
        DOMparser,
        {
          isFirstPHeading,
          preserveLongSequences
        },
        {
          dates: publicationDates
        }
      ) + xmlFooter,
    format,
    quality: 'high',
    columns: columns + (optimizeColumns ? 1 : 0),
    dynamicHeader: Array.isArray(dynamicHeaders) ? dynamicHeaders[0] : null,
    linerBorder: adTemplate.data().linerBorder || newspaper.data().linerBorder,
    icmlSubstitutions,
    linerMaxColumns,
    optimizeColumns: !!optimizeColumns,
    resizeTextFramesForProofPDF: type === 'DISPLAY_PARAMETERS',
    outputToBase64: type === 'DISPLAY_PARAMETERS'
  } as IDRequest;
  const renderRequestOptions = (type === 'RAW'
    ? {
        responseType: 'arraybuffer'
      }
    : {}) as AxiosRequestConfig;

  const { data } = await sendToRenderingEngine({
    url,
    request,
    options: renderRequestOptions
  });

  if (type === 'RAW') {
    return { data };
  }
  const boldWords = getBoldWords(notice.data().confirmedHtml, DOMparser);
  return { ...data, boldWords };
};

export const getDaysSinceFirstWeekdayOfQuarter = (now: moment.Moment) => {
  let startOfQuarter = moment(now.format('YYYY-MM-DD')).startOf('quarter');

  // handle Saturday
  if (startOfQuarter.day() === 6) {
    startOfQuarter = startOfQuarter.add(2, 'days');
  }

  // handle Sunday
  if (startOfQuarter.day() === 0) {
    startOfQuarter = startOfQuarter.add(1, 'days');
  }

  return now.startOf('day').diff(startOfQuarter.startOf('day'), 'days');
};
