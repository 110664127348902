import { createReducer, createActions } from 'reduxsauce';
import {
  ESnapshotExists,
  ENoticeDraft,
  ENotice,
  EMail,
  EOrganization,
  ERate,
  ERef,
  ETemplate,
  EUser,
  FirebaseTimestamp,
  Customer
} from 'lib/types';
import { NoticeType } from 'lib/enums';
import { Crop } from 'lib/types/notice';
import { InvoiceMail } from 'lib/types/customer';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  hydrateNoticeData: ['noticeId', 'redirect'],
  populateNoticeData: ['noticeData'],
  setFiler: ['filerRef'],
  setFiledBy: ['filedBy'],
  setFilingState: ['filingState'],
  setAvailableStates: ['availableStates'],
  setNewspaper: ['newspaperRef'],
  setPublicationDates: ['publicationDates'],
  setNoticeText: ['noticeText'],
  setNoticeType: ['noticeType'],
  setPreviousNoticeType: ['previousNoticeType'],
  setFile: ['file'],
  setColumns: ['columns'],
  setColumnsEditingEnabled: ['columnsEditingEnabled'],
  setDraft: ['draftRef'],
  setOriginal: ['originalRef'],
  setAttachedFiles: ['attachedFiles'],
  setMail: ['mail'],
  setInvoiceMailings: ['invoiceMailings'],
  setRate: ['rateRef'],
  setTemplate: ['templateRef'],
  setNewspaperList: ['newspaperList'],
  setDisplayUrl: ['displayUrl'],
  setDisplayParams: ['displayParams'],
  setProcessedDisplay: ['processedDisplay'],
  setSquashable: ['squashable'],
  setUnusedConfirmedHtml: ['unusedConfirmedHtml'],
  confirmSchedule: ['scheduleParams'],
  setConfirmedCrop: ['crop'],
  setPdfStoragePath: ['pdfStoragePath'],
  setUnusedDisplay: ['unusedDisplay'],
  setReferenceId: ['referenceId'],
  setCustomAffidavit: ['customAffidavit'],
  setDynamicFooter: ['dynamicFooter'],
  setProofStoragePath: ['proofStoragePath'],
  setDefaultRateOverride: ['defaultRateOverride'],
  setDraftSnap: ['draftSnap'],
  setProof: [],
  saveDraft: [],
  publishNotice: [],
  resetState: [],
  setEditing: ['editing'],
  setPlacementError: ['placementError'],
  setConfirmedText: ['confirmedText'],
  setFormattingError: ['formattingError'],
  setPostWithoutFormatting: ['postWithoutFormatting'],
  fileWithoutProof: [
    'formattingError',
    'postWithoutFormatting',
    'requiresFormatting'
  ],
  clearFileWithoutProof: [],
  publishNoticeNoProof: [],
  continueWithLargeFile: [],
  setCreatingInvoice: ['creatingInvoice'],
  setConfirming: ['confirming'],
  setCurrentStep: ['currentStep'],
  setCustomer: ['customer'],
  setRequireEmailAffidavit: ['requireEmailAffidavit'],
  setMailAffidavitsOutsideColumn: ['mailAffidavitsOutsideColumn']
});
export const PlacementTypes = Types;
export default Creators;

/* ---------------- Type EPlacement -------------- */
export type EPlacement = {
  filer: ERef<EUser>;
  availableStates: number[] | null;
  filingState: number;
  publicationDates: FirebaseTimestamp[];
  adTemplate: ERef<ETemplate>;
  original: ERef<ENotice>;
  rate: ERef<ERate>;
  draft: ERef<ENoticeDraft>;
  newspaper: ERef<EOrganization>;
  newspaperList: { id: string; state: number; name: string }[];
  noticeType: number;
  previousNoticeType: number;
  columns: number;
  columnsEditingEnabled: boolean;
  file: any;
  confirmedHtml: string;
  unusedConfirmedHtml: string;
  confirmedCrop: Required<Crop>;
  displayParams: any;
  displayUrl: string;
  mail: EMail[];
  invoiceMailings: InvoiceMail[];
  filedBy: ERef<EOrganization> | null; // the advertisers organization if it exists
  attachedFiles: any;
  processedDisplay: boolean;
  squashable: boolean;
  dynamicHeaders: string[];
  dynamicFooter: string;
  footerFormatString: string;
  pdfStoragePath: string;
  jpgStoragePath: string;
  jpgURL: string;
  referenceId?: string;
  proofStoragePath?: string;
  customAffidavit?: string;
  defaultRateOverride?: boolean;
  userId: string;
  editing: boolean;
  draftSnap: ESnapshotExists<ENoticeDraft>;
  unusedDisplay: string;
  // use better solution when not in fire-fighting mode
  confirmedText: string;
  placementError: any;
  formattingError?: string;
  postWithoutFormatting: boolean;
  requiresFormatting: boolean;
  continueWithLargeFile: boolean;
  creatingInvoice: boolean;
  confirming: boolean;
  currentStep: { id: string };
  customer: Customer;
  requireEmailAffidavit: boolean;
  mailAffidavitsOutsideColumn: boolean;
};

/* ------------- Initial State ------------- */

export const INITIAL_STATE: EPlacement = {
  filer: null,
  filedBy: null,
  availableStates: null,
  filingState: null,
  publicationDates: null,
  adTemplate: null,
  original: null,
  draft: null,
  rate: null,
  newspaper: null,
  newspaperList: [],
  noticeType: NoticeType.custom.value,
  previousNoticeType: null,
  columns: 1,
  columnsEditingEnabled: true,
  confirmedHtml: null,
  unusedConfirmedHtml: null,
  confirmedCrop: null,
  displayParams: null,
  displayUrl: null,
  file: null,
  mail: null,
  invoiceMailings: null,
  proofStoragePath: null,
  attachedFiles: null,
  processedDisplay: false,
  squashable: false,
  dynamicHeaders: null,
  dynamicFooter: null,
  footerFormatString: null,
  pdfStoragePath: '',
  jpgStoragePath: '',
  jpgURL: null,
  referenceId: '',
  customAffidavit: null,
  defaultRateOverride: false,
  userId: '',
  editing: false,
  draftSnap: null,
  unusedDisplay: null,
  placementError: null,
  confirmedText: null,
  postWithoutFormatting: false,
  requiresFormatting: false,
  continueWithLargeFile: false,
  creatingInvoice: false,
  confirming: false,
  currentStep: { id: '' },
  customer: null,
  requireEmailAffidavit: null,
  mailAffidavitsOutsideColumn: null
};

export const placementSelector = (state: any) => state.placement as EPlacement;

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FILING_STATE]: (
    state: EPlacement,
    { filingState }
  ): EPlacement => ({
    ...state,
    filingState
  }),
  [Types.SET_DRAFT_SNAP]: (state: EPlacement, { draftSnap }): EPlacement => ({
    ...state,
    draftSnap
  }),
  [Types.RESET_STATE]: (state: EPlacement): EPlacement => {
    return {
      ...INITIAL_STATE
    };
  },
  [Types.POPULATE_NOTICE_DATA]: (
    state: EPlacement,
    { noticeData }
  ): EPlacement => {
    const { text, confirmedHtml, ...noticeState } = noticeData;
    return {
      ...state,
      ...noticeState,
      confirmedHtml: confirmedHtml || text || null
    };
  },
  [Types.SET_MAIL]: (state: EPlacement, { mail }): EPlacement => ({
    ...state,
    mail
  }),
  [Types.SET_INVOICE_MAILINGS]: (
    state: EPlacement,
    { invoiceMailings }
  ): EPlacement => ({
    ...state,
    invoiceMailings
  }),
  [Types.SET_CUSTOM_AFFIDAVIT]: (
    state: EPlacement,
    { customAffidavit }
  ): EPlacement => ({
    ...state,
    customAffidavit
  }),
  [Types.SET_NEWSPAPER]: (state: EPlacement, { newspaperRef }): EPlacement => ({
    ...state,
    newspaper: newspaperRef
  }),
  [Types.SET_FILER]: (state: EPlacement, { filerRef }): EPlacement => ({
    ...state,
    filer: filerRef,
    userId: filerRef.id
  }),
  [Types.SET_FILED_BY]: (state: EPlacement, { filedBy }): EPlacement => ({
    ...state,
    filedBy
  }),
  [Types.SET_TEMPLATE]: (state: EPlacement, { templateRef }): EPlacement => ({
    ...state,
    adTemplate: templateRef
  }),
  [Types.SET_RATE]: (state: EPlacement, { rateRef }): EPlacement => ({
    ...state,
    rate: rateRef
  }),
  [Types.SET_ORIGINAL]: (state: EPlacement, { originalRef }): EPlacement => ({
    ...state,
    original: originalRef
  }),
  [Types.SET_DRAFT]: (state: EPlacement, { draftRef }): EPlacement => ({
    ...state,
    draft: draftRef
  }),
  [Types.SET_AVAILABLE_STATES]: (
    state: EPlacement,
    { availableStates }
  ): EPlacement => ({
    ...state,
    availableStates
  }),
  [Types.SET_NEWSPAPER_LIST]: (
    state: EPlacement,
    { newspaperList }
  ): EPlacement => ({
    ...state,
    newspaperList
  }),

  [Types.SET_NOTICE_TYPE]: (state: EPlacement, { noticeType }): EPlacement => ({
    ...state,
    noticeType
  }),

  [Types.SET_PREVIOUS_NOTICE_TYPE]: (
    state: EPlacement,
    { previousNoticeType }
  ): EPlacement => ({
    ...state,
    previousNoticeType
  }),

  [Types.SET_DISPLAY_URL]: (state: EPlacement, { displayUrl }): EPlacement => ({
    ...state,
    displayUrl
  }),
  [Types.SET_PROCESSED_DISPLAY]: (
    state: EPlacement,
    { processedDisplay }
  ): EPlacement => ({
    ...state,
    processedDisplay
  }),
  [Types.SET_COLUMNS]: (state: EPlacement, { columns }): EPlacement => ({
    ...state,
    columns
  }),
  [Types.SET_COLUMNS_EDITING_ENABLED]: (
    state: EPlacement,
    { columnsEditingEnabled }
  ): EPlacement => ({
    ...state,
    columnsEditingEnabled
  }),
  [Types.SET_ATTACHED_FILES]: (
    state: EPlacement,
    { attachedFiles }
  ): EPlacement => ({
    ...state,
    attachedFiles
  }),
  [Types.SET_NOTICE_TEXT]: (state: EPlacement, { noticeText }): EPlacement => ({
    ...state,
    confirmedHtml: noticeText
  }),
  [Types.SET_UNUSED_CONFIRMED_HTML]: (
    state: EPlacement,
    { unusedConfirmedHtml }
  ): EPlacement => ({
    ...state,
    unusedConfirmedHtml
  }),
  [Types.SET_SQUASHABLE]: (state: EPlacement, { squashable }): EPlacement => ({
    ...state,
    squashable
  }),
  [Types.SET_DISPLAY_PARAMS]: (
    state: EPlacement,
    { displayParams }
  ): EPlacement => ({
    ...state,
    displayParams
  }),

  [Types.SET_CONFIRMED_CROP]: (state: EPlacement, { crop }): EPlacement => ({
    ...state,
    confirmedCrop: crop
  }),
  [Types.SET_PROOF_STORAGE_PATH]: (
    state: EPlacement,
    { proofStoragePath }
  ): EPlacement => ({
    ...state,
    proofStoragePath
  }),
  [Types.SET_PDF_STORAGE_PATH]: (
    state: EPlacement,
    { pdfStoragePath }
  ): EPlacement => ({
    ...state,
    pdfStoragePath
  }),

  [Types.SET_FILE]: (state: EPlacement, { file }): EPlacement => ({
    ...state,
    file
  }),
  [Types.SET_REFERENCE_ID]: (
    state: EPlacement,
    { referenceId }
  ): EPlacement => ({
    ...state,
    referenceId
  }),
  [Types.CONFIRM_SCHEDULE]: (
    state: EPlacement,
    { scheduleParams }
  ): EPlacement => ({
    ...state,
    publicationDates: scheduleParams.publicationDates,
    dynamicFooter: scheduleParams.dynamicFooter,
    footerFormatString: scheduleParams.footerFormatString,
    dynamicHeaders: scheduleParams.dynamicHeaders
  }),
  [Types.SET_DYNAMIC_FOOTER]: (
    state: EPlacement,
    { dynamicFooter }
  ): EPlacement => ({
    ...state,
    dynamicFooter
  }),
  [Types.SET_DEFAULT_RATE_OVERRIDE]: (
    state: EPlacement,
    { defaultRateOverride }
  ): EPlacement => ({
    ...state,
    defaultRateOverride
  }),
  [Types.SET_EDITING]: (state: EPlacement, { editing }): EPlacement => ({
    ...state,
    editing
  }),
  [Types.SET_UNUSED_DISPLAY]: (
    state: EPlacement,
    { unusedDisplay }
  ): EPlacement => ({
    ...state,
    unusedDisplay
  }),
  [Types.SET_PLACEMENT_ERROR]: (
    state: EPlacement,
    { placementError }
  ): EPlacement => ({
    ...state,
    placementError
  }),
  [Types.SET_CONFIRMED_TEXT]: (
    state: EPlacement,
    { confirmedText }
  ): EPlacement => ({
    ...state,
    confirmedText
  }),
  [Types.SET_FORMATTING_ERROR]: (
    state: EPlacement,
    { formattingError }
  ): EPlacement => ({
    ...state,
    formattingError
  }),
  [Types.SET_POST_WITHOUT_FORMATTING]: (
    state: EPlacement,
    { postWithoutFormatting }
  ): EPlacement => ({
    ...state,
    postWithoutFormatting
  }),
  [Types.SET_PUBLICATION_DATES]: (
    state: EPlacement,
    { publicationDates }
  ): EPlacement => ({
    ...state,
    publicationDates
  }),
  [Types.FILE_WITHOUT_PROOF]: (
    state: EPlacement,
    { formattingError, postWithoutFormatting, requiresFormatting }
  ): EPlacement => ({
    ...state,
    formattingError,
    postWithoutFormatting,
    requiresFormatting,
    continueWithLargeFile: false
  }),
  [Types.CLEAR_FILE_WITHOUT_PROOF]: (state: EPlacement): EPlacement => ({
    ...state,
    proofStoragePath: null,
    jpgStoragePath: null,
    jpgURL: null,
    formattingError: null,
    postWithoutFormatting: false,
    requiresFormatting: false,
    continueWithLargeFile: false
  }),
  [Types.CONTINUE_WITH_LARGE_FILE]: (state: EPlacement): EPlacement => ({
    ...state,
    continueWithLargeFile: true,
    formattingError: null,
    postWithoutFormatting: false,
    requiresFormatting: false
  }),
  [Types.SET_CREATING_INVOICE]: (
    state: EPlacement,
    { creatingInvoice }
  ): EPlacement => ({
    ...state,
    creatingInvoice
  }),
  [Types.SET_CONFIRMING]: (state: EPlacement, { confirming }): EPlacement => ({
    ...state,
    confirming
  }),
  [Types.SET_CURRENT_STEP]: (
    state: EPlacement,
    { currentStep }
  ): EPlacement => ({
    ...state,
    currentStep
  }),
  [Types.SET_CUSTOMER]: (state: EPlacement, { customer }): EPlacement => ({
    ...state,
    customer
  }),
  [Types.SET_REQUIRE_EMAIL_AFFIDAVIT]: (
    state: EPlacement,
    { requireEmailAffidavit }
  ): EPlacement => ({
    ...state,
    requireEmailAffidavit
  }),
  [Types.SET_MAIL_AFFIDAVITS_OUTSIDE_COLUMN]: (
    state: EPlacement,
    { mailAffidavitsOutsideColumn }
  ): EPlacement => ({
    ...state,
    mailAffidavitsOutsideColumn
  })
});
