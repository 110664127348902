import { createReducer, createActions } from 'reduxsauce';

import { OccupationType } from '../lib/enums';

import {
  EOrganization,
  ESnapshot,
  EUser,
  EAnnouncement,
  FirebaseUser
} from '../lib/types';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  login: ['email', 'password'],
  logout: [],
  setUser: ['user'],

  startAuth: [],
  endAuth: [],

  resetPassword: ['email'],

  setPermissions: ['permissions'],

  setUserAuth: ['userAuth'],
  loginToken: ['token'],
  register: [],

  setOrganization: ['organization'],
  setActiveOrganization: ['activeOrganization'],
  setAvailableOrganizations: ['availableOrganizations'],
  showAllOrgsNotices: ['showAllOrgsNotices'],

  setOrgContext: ['orgContext'],

  logoutSuccess: [],

  setAuthError: ['error'],

  setAnnouncement: ['announcement'],
  markAnnouncementRead: []
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type AuthState = {
  user: ESnapshot<EUser>;
  userAuth: FirebaseUser;
  isPublisher: boolean;
  organization: ESnapshot<EOrganization>;
  activeOrganization: EOrganization;
  availableOrganizations: EOrganization[];
  error: string;
  orgContext: ESnapshot<EOrganization>;
  permissions: {
    billing: boolean;
    admin: boolean;
  };
  loading: boolean;
  announcement: ESnapshot<EAnnouncement>;
};
const INITIAL_STATE: AuthState = {
  user: null,
  userAuth: null,
  isPublisher: null,
  organization: null,
  activeOrganization: null,
  availableOrganizations: [],
  error: null,
  orgContext: null,
  permissions: {
    billing: false,
    admin: false
  },
  loading: true,
  announcement: null
};

export const authSelector: (state: any) => AuthState = (state: any) =>
  state.auth;

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: (state, { user }) => ({
    ...state,
    isPublisher: user.data().occupation === OccupationType.publishing.value,
    alwaysAllowAffidavitDownload:
      user.data().alwaysAllowAffidavitDownload || false,
    user
  }),

  [Types.SET_USER_AUTH]: (state, { userAuth }) => ({
    ...state,
    userAuth
  }),

  [Types.START_AUTH]: state => ({
    ...state,
    loading: true
  }),

  [Types.END_AUTH]: state => ({
    ...state,
    loading: false
  }),

  [Types.SET_PERMISSIONS]: (state, { permissions }) => ({
    ...state,
    permissions
  }),

  [Types.SET_ORGANIZATION]: (state, { organization }) => ({
    ...state,
    organization
  }),
  [Types.SET_AVAILABLE_ORGANIZATIONS]: (state, { availableOrganizations }) => ({
    ...state,
    availableOrganizations
  }),
  [Types.SET_ACTIVE_ORGANIZATION]: (state, { activeOrganization }) => ({
    ...state,
    activeOrganization
  }),

  [Types.SHOW_ALL_ORGS_NOTICES]: (state, { showAllOrgsNotices }) => ({
    ...state,
    showAllOrgsNotices
  }),

  [Types.SET_AUTH_ERROR]: (state, { error }) => ({ ...state, error }),

  [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,
  [Types.SET_ORG_CONTEXT]: (state, { orgContext }) => ({
    ...state,
    orgContext
  }),

  [Types.SET_ANNOUNCEMENT]: (state, { announcement }) => ({
    ...state,
    announcement
  })
});
