import {
  all,
  take,
  takeEvery,
  call,
  put,
  select,
  fork,
  takeLatest
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { eventChannel } from 'redux-saga';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { authSelector, AuthTypes } from 'redux/auth';
import OccupationType from 'lib/enums/OccupationType';
import OrganizationType from 'lib/enums/OrganizationType';
import { push } from 'connected-react-router';
import { NoticeType, State, NoticeStatusType } from 'lib/enums';
import firebase from 'firebase';
import {
  ERef,
  ENoticeDraft,
  ESnapshot,
  ENotice,
  EUser,
  EOrganization
} from 'lib/types';
import {
  appendToCurrentParams,
  updateHistoryNoRerender,
  getLocationParams
} from 'utils/urls';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import { checkForDefault } from 'routes/placeScroll/rates';
import api from 'api';
import { PlacementError } from '../lib/errors/PlacementError';
import PlacementActions, {
  PlacementTypes,
  placementSelector
} from '../redux/placement';

const getValidPapers = (
  papers: { id: string; name: string }[],
  user: ESnapshot<EUser>
) => {
  let filteredPapers = papers;

  const restrictedPapers = getLocationParams().get('restrictedPapers')
    ? getLocationParams()
        .get('restrictedPapers')
        .split(',')
    : null;

  if (restrictedPapers) {
    filteredPapers = filteredPapers.filter(p =>
      restrictedPapers.includes(p.id)
    );
  }

  if (user && user.data().enabledNewspapers) {
    const enabledIds = user.data().enabledNewspapers!.map(n => n.id);
    return filteredPapers.filter(p => enabledIds.indexOf(p.id) !== -1);
  }

  return filteredPapers.sort((a, b) => {
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    return 0;
  });
};

function* createNotice() {
  let auth;
  try {
    auth = yield select(authSelector);
    if (
      auth.user &&
      auth.user.data().occupation !== OccupationType.publishing.value
    ) {
      yield put(PlacementActions.setFiler(auth.user.ref));
    }
  } catch (e) {
    yield put(PlacementActions.setPlacementError(new PlacementError()));
    Sentry.captureException(e);
    console.log('Placement: Error creating notice line 67:', e);
  }

  const newspaperRef =
    (auth.user &&
      auth.user.data().occupation === OccupationType.publishing.value &&
      auth.user.data().activeOrganization) ||
    (auth.orgContext &&
      auth.orgContext.data().organizationType ===
        OrganizationType.newspaper.value &&
      auth.orgContext.ref) ||
    null;

  let adTemplateRef = null;
  let rateRef = null;
  yield put(PlacementActions.setNewspaper(newspaperRef));
  if (newspaperRef) {
    const newspaper = yield call([newspaperRef, newspaperRef.get]);
    adTemplateRef = newspaper.data().adTemplate;
    if (!adTemplateRef) {
      yield put(push('/'));
      return;
    }
    yield put(PlacementActions.setTemplate(adTemplateRef));
    rateRef = newspaper.data().defaultLinerRate;
    yield put(PlacementActions.setRate(rateRef));
  }

  if (auth.user && auth.user.data().defaultTemplate) {
    adTemplateRef = auth.user.data().defaultTemplate;
    yield put(PlacementActions.setTemplate(adTemplateRef));
  }
  const placement = yield select(placementSelector);

  const filedBy = auth.isPublisher
    ? null
    : auth.user?.data()?.activeOrganization || null;

  const initialNotice = {
    noticeType: NoticeType.custom.value,
    newspaper: placement.newspaper,
    adTemplate: placement.adTemplate,
    rate: placement.rate,
    filer: placement.filer,
    userId: auth.user && auth.user.id,
    isArchived: false,
    createTime: firebase.firestore.FieldValue.serverTimestamp(),
    filedBy
  };

  const createRef = Firebase.firestore().collection(Collections.userNotices);
  const ref = yield call([createRef, createRef.add], initialNotice);

  const draftNotice = {
    noticeType: NoticeType.custom.value,
    newspaper: placement.newspaper,
    adTemplate: placement.adTemplate,
    rate: placement.rate,
    filer: placement.filer,
    userId: auth.user && auth.user.id,
    filedBy,
    isArchived: false,
    createTime: firebase.firestore.FieldValue.serverTimestamp(),
    original: ref,
    owner: auth.user && auth.user.ref
  };

  const draftRef = Firebase.firestore()
    .collection(Collections.userDrafts)
    .doc();

  yield call([draftRef, draftRef.set], draftNotice);

  yield call([ref, ref.update], { drafts: [draftRef] });

  yield put(PlacementActions.setOriginal(ref));

  yield put(PlacementActions.setDraft(draftRef));
  yield put(PlacementActions.setFiledBy(filedBy));
  const draftSnap = yield call([draftRef, draftRef.get]);
  yield put(PlacementActions.setDraftSnap(draftSnap));
  return ref;
}

function* getAvailableStatesAndPapers() {
  try {
    const data = yield call(() => api.get('organizations/newspapers'));
    const { user } = yield select(authSelector);
    const newspaperList: Partial<EOrganization>[] = getValidPapers(
      data.papers,
      user
    );
    const states = {} as Record<string, boolean>;
    yield put(PlacementActions.setNewspaperList(newspaperList));
    newspaperList.forEach(d => {
      const npStateValue = d.state;
      if (State.by_value(npStateValue)) states[npStateValue] = true;
    });

    const filteredStates = [
      ...Object.keys(states).map(key => parseInt(key, 10))
    ];
    yield put(PlacementActions.setAvailableStates(filteredStates));
  } catch (e) {
    yield put(PlacementActions.setPlacementError(new PlacementError()));
    Sentry.captureException(e);
    console.log('Placement: Error fetching states on line 181:', e);
  }
}

function* watchNotice(noticeRef: ERef<ENotice>) {
  if (!noticeRef) return;
  const noticeChannel = eventChannel(emitter => noticeRef.onSnapshot(emitter));
  // ignore the first update
  yield take(noticeChannel);
  yield takeLatest(noticeChannel, function* f(
    noticeSnap: ESnapshot<ENoticeDraft>
  ) {
    if (!noticeSnap.data()) {
      yield put(PlacementActions.setDraftSnap(noticeSnap));
      return;
    }
    yield put(PlacementActions.setDraftSnap(noticeSnap));
    const {
      text,
      confirmedHtml,
      unusedConfirmedHtml,
      proofStoragePath
    } = noticeSnap.data();
    if (proofStoragePath)
      yield put(PlacementActions.setProofStoragePath(proofStoragePath));
    if (unusedConfirmedHtml)
      yield put(PlacementActions.setUnusedConfirmedHtml(unusedConfirmedHtml));
    if (text || confirmedHtml)
      yield put(PlacementActions.setNoticeText(confirmedHtml || text));
  });
}

function* publishNotice() {
  try {
    const placement = yield select(placementSelector);
    const auth = yield select(authSelector);
    const { draft, original, editing } = placement;

    const originalNotice = yield call([original, original.get]);

    const submissionTime = firebase.firestore.Timestamp.now();
    const draftData = yield call([draft, draft.get]);
    if (draftData.data().inactive) return;
    const draftMailRef = draft.collection(Collections.mail);
    const draftMail = yield call([draftMailRef, draftMailRef.get]);
    const originalMailRef = original.collection(Collections.mail);
    const originalMail = yield call([originalMailRef, originalMailRef.get]);
    if (originalMail.docs) {
      for (const doc of originalMail.docs) {
        const mRef = doc.ref;
        yield call([mRef, mRef.delete]);
      }
    }

    if (draftMail.docs) {
      for (const doc of draftMail.docs) {
        yield call([originalMailRef, originalMailRef.add], doc.data());
        const mRef = doc.ref;
        yield call([mRef, mRef.delete]);
      }
    }

    const draftUpdates = draftData.data();
    // TODO: remove this. This line prevents us from nulling out the userId on the notice
    if (!draftUpdates.userId) {
      delete draftUpdates.userId;
    }

    const noticeObject = {
      ...draftUpdates,

      // clear the generated PDF document
      noticeStatus: NoticeStatusType.pending.value,

      /* Note: 
    This is Commented for making sure confirm modal does not reappear on noticeEdit.
    After Conversation with Haris, no known sideEffects were found and keeping the commented code
    for reverting back if any extensive sideEffect is found
    
    confirmedReceipt: !!auth.isPublisher,
    */
      confirmedAt: originalNotice.data().confirmedAt || submissionTime,
      confirmedReceiptTime: auth.isPublisher ? submissionTime : null,
      confirmed: true,
      confirmedBy: auth.isPublisher ? auth.user.ref : null,
      editedAt: submissionTime
    };
    delete (noticeObject as any).owner;
    delete (noticeObject as any).inactive;
    delete (noticeObject as any).original;
    delete (noticeObject as any).confirming;
    delete (noticeObject as any).unusedDisplay;
    delete (noticeObject as any).generatedAffidavitURL;
    delete (noticeObject as any).generatedAffidavitStoragePath;
    (noticeObject as any).displayParams &&
      delete (noticeObject as any).displayParams.imgs;
    (noticeObject as any).displayParams &&
      delete (noticeObject as any).displayParams.err;
    yield call([draft, draft.update], { editedAt: submissionTime });
    yield call([original, original.update], noticeObject);
    yield put(push(`/notice/${placement.original.id}`));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(PlacementActions.setConfirming(false));
  }
}

function* listenToNotice() {
  while (true) {
    const placement = yield select(placementSelector);
    yield call(watchNotice, placement.draft);
  }
}

function* fetchAndHydrateNoticeData(action: any) {
  yield put(PlacementActions.resetState());
  yield call(getAvailableStatesAndPapers);

  if (!action.noticeId) {
    try {
      const ref = yield call(createNotice);
      if (action.redirect !== false) {
        const { currentStep } = yield select(placementSelector);
        yield call(
          updateHistoryNoRerender,
          `/place/${ref.id}?${appendToCurrentParams(
            'step',
            currentStep.id
          ).toString()}`
        );
      } else {
        yield call(fetchAndHydrateNoticeData as any, {
          noticeId: ref.id,
          redirect: false
        });
      }
    } catch (e) {
      yield put(PlacementActions.setPlacementError(new PlacementError()));
      Sentry.captureException(e);
      console.log('Placement: Error creating notice:', e);
    }
  } else {
    try {
      const noticeRef = Firebase.firestore()
        .collection(Collections.userNotices)
        .doc(action.noticeId);
      const notice = yield call([noticeRef, noticeRef.get]);
      yield put(PlacementActions.setEditing(!!notice.data().noticeStatus));

      const draftSnaps = [];
      for (const doc of notice.data().drafts || []) {
        const snapshot = yield call([doc, doc.get]);
        if (snapshot.data()) draftSnaps.push(snapshot);
      }

      let draftSnap;
      const auth = yield select(authSelector);
      /**
       *
       * if the drafts exist
       * if the user exists
       * if only one draft and no owner
       * anonymous user flow
       */
      if (draftSnaps.length) {
        if (auth.user) {
          if (draftSnaps.length === 1 && !draftSnaps[0].data()?.owner) {
            [draftSnap] = draftSnaps;
          } else {
            draftSnap = draftSnaps.find(
              snap =>
                snap.data().owner && snap.data().owner.id === auth.user.ref.id
            );
          }
        } else {
          draftSnap = draftSnaps.find(snap => !snap.data().owner);
        }
      }
      // eslint-disable-next-line no-extra-boolean-cast
      if (
        auth.user &&
        auth.user.data().occupation !== OccupationType.publishing.value
      ) {
        if (draftSnaps.length > 1) {
          draftSnap = draftSnaps[draftSnaps.length - 1];
        }
        [draftSnap] = draftSnaps;
      }
      if (draftSnap) {
        const mailRef = draftSnap.ref.collection(Collections.mail);
        const mailObject = yield call([mailRef, mailRef.get]);
        const mailArray = [];
        for (const mail of mailObject.docs) {
          mailArray.push(mail.data());
        }
        yield put(PlacementActions.setDraftSnap(draftSnap));
        yield put(PlacementActions.setMail(mailArray));
        yield put(PlacementActions.setDraft(draftSnap.ref));
        const { proofStoragePath, ...data } = draftSnap.data();
        yield put(PlacementActions.populateNoticeData(data));
        if (data.confirmedHtml || data.text) {
          yield put(
            PlacementActions.setConfirmedText(data.confirmedHtml || data.text)
          );
        }
        if (draftSnap.data().newspaper) {
          yield put(PlacementActions.setNewspaper(draftSnap.data().newspaper));
        }

        if (!draftSnap.data().userId) {
          let user;
          if (auth.user) user = auth.user;
          else {
            const action = yield take(AuthTypes.SET_USER);
            user = action.user;
          }
          if (user.data().occupation !== OccupationType.publishing.value) {
            yield put(PlacementActions.setFiler(user.ref));
          }
        }
      } else {
        const draftRef = Firebase.firestore()
          .collection(Collections.userDrafts)
          .doc();

        const draftObject = {
          ...notice.data(),
          original: notice.ref,
          owner: auth.user ? auth.user.ref : null
        };
        delete draftObject.editedAt;
        delete draftObject.drafts;
        delete draftObject.proofStoragePath;
        const drafts = notice.data().drafts || [];
        drafts.push(draftRef);
        const noticeRef = notice.ref;
        yield call([noticeRef, noticeRef.update], { drafts });
        yield call([draftRef, draftRef.set], draftObject);
        if (auth.user) {
          const draftMailRef = draftRef.collection(Collections.mail);
          const mailRef = notice.ref.collection(Collections.mail);
          const mailObject = yield call([mailRef, mailRef.get]);
          const mailArray = [];
          for (const mail of mailObject.docs) {
            yield call([draftMailRef, draftMailRef.add], mail.data());
            mailArray.push(mail.data());
          }
          yield put(PlacementActions.setMail(mailArray));
        }

        const draftSnap = yield call([draftRef, draftRef.get]);
        yield put(PlacementActions.populateNoticeData(draftObject));
        if (draftObject.confirmedHtml || draftObject.text) {
          yield put(
            PlacementActions.setConfirmedText(
              draftObject.confirmedHtml || draftObject.text
            )
          );
        }
        yield put(PlacementActions.setDraftSnap(draftSnap));
        yield put(PlacementActions.setDraft(draftRef));
        yield put(PlacementActions.setOriginal(noticeRef));
      }
    } catch (e) {
      yield put(PlacementActions.setPlacementError(new PlacementError()));
      Sentry.captureException(e);
      console.log(
        'Placement: Error setting the draft contents on line 365:',
        e
      );
    }
  }
  yield fork(listenToNotice);
}

function* saveDraft() {
  const placement = yield select(placementSelector);
  const {
    draft,
    availableStates,
    newspaperList,
    file,
    mail,
    editing,
    draftSnap,
    placementError,
    confirmedText,
    currentStep,
    customer,
    publicationDates,
    columnsEditingEnabled,
    ...updateObject
  } = placement;
  try {
    if (!draft) return;
    const mailRef = draft.collection(Collections.mail);
    const oldMail = yield call([mailRef, mailRef.get]);
    if (oldMail.docs) {
      for (const doc of oldMail.docs) {
        const mRef = doc.ref;
        yield call([mRef, mRef.delete]);
      }
    }
    if (mail && mail.length) {
      for (const m of mail) {
        yield call([mailRef, mailRef.add], m);
      }
    }
  } catch (e) {
    yield put(PlacementActions.setPlacementError(new PlacementError()));
    Sentry.captureException(e);
    console.log('Placement: Error while processing mail on line 402:', e);
  }

  if (
    !updateObject.displayParams ||
    !Object.keys(placement.displayParams).length
  )
    delete updateObject.displayParams;

  // Remove imgs from the display params before saving in the database
  // as this field is *massive* and is later encoded in the image urls
  if (updateObject.displayParams?.imgs) {
    delete updateObject.displayParams?.imgs;
  }

  try {
    console.log(updateObject);
    updateObject.userId = placement.filer ? placement.filer.id : null;
    updateObject.publicationDates = publicationDates || null;
    const placementDraft = yield call([draft, draft.get]);
    if (placementDraft) yield call([draft, draft?.update], updateObject);
  } catch (e) {
    yield put(PlacementActions.setPlacementError(new PlacementError()));
    Sentry.captureException(e);
    console.log('Placement: Error while saving draft on line 413:', e);
  }
}

function* processNewspaperUpdate(action: any) {
  if (action.newspaperRef) {
    const { newspaperRef } = action;
    try {
      const { draft, draftSnap } = yield select(placementSelector);

      const newspaper = yield call([newspaperRef, newspaperRef.get]);

      if (draftSnap) {
        // this is needed for custom notice types to update notice type in placement
        const { noticeType, previousNoticeType } = draftSnap.data();

        yield put(PlacementActions.setNoticeType(noticeType));
        if (previousNoticeType)
          yield put(PlacementActions.setPreviousNoticeType(previousNoticeType));
      }

      // Reset placement data on newspaper update
      if (newspaper.id !== draftSnap?.data()?.newspaper?.id && draftSnap) {
        yield put(PlacementActions.setNoticeType(NoticeType.custom.value));
        yield put(PlacementActions.setNoticeText(''));
        yield put(PlacementActions.setConfirmedText(null));
        draftSnap.ref.update({
          confirmedHtml: '',
          publicationDates: null
        });
        yield put(PlacementActions.setPublicationDates(null));
      }

      const { adTemplate, defaultLinerRate, defaultColumns } = newspaper.data();

      yield put(PlacementActions.setTemplate(adTemplate));
      yield put(PlacementActions.setRate(defaultLinerRate));

      if (defaultColumns && !draftSnap?.data()?.columns) {
        yield put(PlacementActions.setColumns(defaultColumns));
      }

      if (draft) {
        yield draft.update({
          newspaper: newspaperRef
        });
      }
      yield call(saveDraft);
    } catch (e) {
      yield put(PlacementActions.setPlacementError(new PlacementError()));
      Sentry.captureException(e);
      console.log('Placement: Error getting rate and template on line 426:', e);
    }
  }
}

function* initAdProofGeneration() {
  try {
    const { draft } = yield select(placementSelector);
    yield call([draft, draft.update], {
      proofStoragePath: null,
      proofUrl: null,
      jpgStoragePath: null,
      jpgURL: null
    });

    const noticeId = draft.id;
    yield call([api, api.post], 'documents/generate-proof', {
      noticeDraft: noticeId
    });
  } catch (e) {
    Sentry.captureMessage(`Error generating proof on line 449: ${e}`);
    console.log('Placement: Error while generating proof on line 449:', e);
  }
}

function* publishNoticeNoProof() {}

function* setNoticeType({ noticeType }: { noticeType: number }) {
  const { newspaper } = yield select(placementSelector);
  if (!newspaper) return;
  const newspaperSnap = yield call([newspaper, newspaper.get]);

  const chosenCustomType = newspaperSnap
    .data()
    ?.allowedNotices?.find((nt: any) => nt.value === noticeType);

  // get the specific rate for the specific advertiser
  const customAdvertiserRate = yield call(
    checkForDefault,
    yield select(placementSelector),
    newspaperSnap,
    noticeType
  );

  const defaultRates = [
    newspaperSnap.data().defaultLinerRate.id,
    newspaperSnap.data().defaultDisplayRate.id
  ];
  if (customAdvertiserRate && !defaultRates.includes(customAdvertiserRate.id)) {
    yield put(PlacementActions.setRate(customAdvertiserRate));
  }

  // if we are working on a custom type
  if (chosenCustomType) {
    // if the custom type has a default rate and there is no advertiser-specific rate
    if (
      chosenCustomType.rate &&
      (!customAdvertiserRate || defaultRates.includes(customAdvertiserRate?.id))
    ) {
      yield put(PlacementActions.setRate(chosenCustomType.rate));
    }

    // if the custom type has a default template
    if (chosenCustomType.template) {
      yield put(PlacementActions.setTemplate(chosenCustomType.template));
    }
    const placement = yield select(placementSelector);
    // if the custom type has a default number of columns
    if (chosenCustomType.columns) {
      yield put(PlacementActions.setColumns(chosenCustomType.columns));
    } else {
      const isDisplay = placement.processedDisplay;
      const minColumnsAllowed = isDisplay
        ? newspaperSnap.data().displayMinColumns
        : newspaperSnap.data().linerMinColumns;

      yield put(PlacementActions.setColumns(minColumnsAllowed || 1));
    }

    yield call(saveDraft);
    return;
  }

  // if the filer has a custom rate, don't set defaults
  if (customAdvertiserRate) {
  } else if (noticeType === NoticeType.display_ad.value) {
    yield put(
      PlacementActions.setRate(newspaperSnap.data().defaultDisplayRate)
    );
  } else {
    yield put(PlacementActions.setRate(newspaperSnap.data().defaultLinerRate));
  }
}

function* updateFooter() {
  try {
    const placement = yield select(placementSelector);

    const { displayParams, draft, newspaper } = placement;
    if (!displayParams || !displayParams.height || !draft || !newspaper) return;

    const footer = yield call(generateFormattedFooter, placement);
    yield put(PlacementActions.setDynamicFooter(footer));
    const placementDraft = yield call([draft, draft.get]);
    if (placement.dynamicFooter && placementDraft.exists) {
      yield call([draft, draft.update], {
        dynamicFooter: placement.dynamicFooter
      });
    }
  } catch (ex) {
    console.log('Placement: Error updating dynamic footer value:', ex);
  }
}

function* placementSaga() {
  yield all([
    yield takeLatest(
      PlacementTypes.HYDRATE_NOTICE_DATA,
      fetchAndHydrateNoticeData
    ),
    yield takeEvery(PlacementTypes.SAVE_DRAFT, saveDraft),
    yield takeEvery(PlacementTypes.SET_NEWSPAPER, processNewspaperUpdate),
    yield takeLatest(PlacementTypes.PUBLISH_NOTICE, publishNotice),
    yield takeLatest(PlacementTypes.SET_CONFIRMED_CROP, saveDraft),
    yield takeEvery(PlacementTypes.SET_PROOF, initAdProofGeneration),
    yield takeEvery(
      [
        PlacementTypes.POPULATE_NOTICE_DATA,
        PlacementTypes.SET_NOTICE_TEXT,
        PlacementTypes.SET_COLUMNS,
        PlacementTypes.SET_DISPLAY_PARAMS,
        PlacementTypes.CONFIRM_SCHEDULE
      ],
      updateFooter
    ),
    yield takeLatest(
      PlacementTypes.PUBLISH_NOTICE_NO_PROOF,
      publishNoticeNoProof
    ),
    yield takeEvery(PlacementTypes.SET_NOTICE_TYPE, setNoticeType)
  ]);
}

export default placementSaga;
