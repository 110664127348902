import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import React from 'react';

import { ESnapshot, EOrganization } from 'lib/types';
import { isDonationOrg } from 'lib/publishers';

type HeaderProps = {
  orgContext: ESnapshot<EOrganization>;
};

const Header: React.FC<HeaderProps> = ({ orgContext }) => {
  return (
    <Helmet>
      <title>
        {orgContext
          ? `${orgContext.data().name} ${
              !isDonationOrg(orgContext) ? '| Public Notices' : ''
            }`
          : 'Column - Public Notice'}
      </title>
    </Helmet>
  );
};

const mapStateToProps = (state: any) => ({
  orgContext: state.auth.orgContext
});

export default connect(mapStateToProps)(Header);
