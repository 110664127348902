import firebase from 'firebase';

import { Collections, Functions } from './lib/constants';
import 'firebase/storage';
import FIREBASE_CONFIG from './config.firebase';

const Firebase: firebase.app.App = firebase.initializeApp(FIREBASE_CONFIG);

export { Collections, Functions };

export const deleteFieldValue = firebase.firestore.FieldValue.delete;

if (process.env.NODE_ENV === 'development') {
  (Firebase.functions() as any)._url = function(name: string) {
    return `http://localhost:5001/${FIREBASE_CONFIG.projectId}/us-central1/${name}`;
  };
}

(window as any).Firebase = Firebase;

export default Firebase;
