import moment from 'moment-timezone';

import { ENotice, EOrganization, ESnapshotExists, EInvoice } from '../types';
import { getDeadlineOverrideKeyFromDate, Deadline } from '../types/deadlines';
import { getNoticeTypeFromNoticeData } from '../helpers';
import { InvoiceStatus, NoticeType } from '../enums';

export const disableNonPublishingDays = (
  day: Date,
  publishingDayEnumValues: number[],
  overrides: EOrganization['deadlineOverrides']
) => {
  const publishingDayOverride =
    overrides && overrides[getDeadlineOverrideKeyFromDate(day)];
  if (publishingDayOverride) {
    return !publishingDayOverride.publish;
  }
  return !publishingDayEnumValues.includes(day.getDay() + 1);
};

export const getRelevantDeadline = (
  publicationDate: Date,
  deadlines: Deadline[],
  overrides: EOrganization['deadlineOverrides']
) => {
  const publishingDayOverride =
    overrides && overrides[getDeadlineOverrideKeyFromDate(publicationDate)];
  if (publishingDayOverride) {
    return publishingDayOverride;
  }

  const m = moment(publicationDate).clone();
  const publicationDayOfWeek = m.day();

  // enums days of week are off by one from moment days of week
  const relevantDeadline = deadlines.find(
    day => day.dayEnum === publicationDayOfWeek + 1
  );
  return relevantDeadline;
};

export const getDeadlineTimeForPaper = (
  publicationDate: Date,
  deadlines: Deadline[],
  newspaperTimezone: string,
  notice: Partial<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  const relevantDeadline = getRelevantDeadline(
    publicationDate,
    deadlines,
    newspaper?.data()?.deadlineOverrides
  );
  const deadlineDayOfWeek = relevantDeadline!.deadline.dayEnum - 1;

  const m = moment(publicationDate).clone();
  const publicationDayOfWeek = m.day();

  let startOfDeadlineDay: moment.Moment;
  // e.g. publication on Sunday (day 0), deadline on Saturday (day 6)
  if (publicationDayOfWeek - deadlineDayOfWeek < 0) {
    startOfDeadlineDay = moment(publicationDate)
      .clone()
      .tz(newspaperTimezone)
      .subtract(1, 'week')
      .add(deadlineDayOfWeek - publicationDayOfWeek, 'd')
      .startOf('day');
  } else {
    // e.g. publication on Monday (day 1), deadline on Sunday (day 0)
    startOfDeadlineDay = moment(publicationDate)
      .clone()
      .tz(newspaperTimezone)
      .subtract(publicationDayOfWeek - deadlineDayOfWeek, 'day')
      .startOf('day');
  }

  const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
  if (noticeType?.publicationOffsetDays) {
    startOfDeadlineDay = startOfDeadlineDay.subtract(
      noticeType.publicationOffsetDays,
      'days'
    );
  }

  if (relevantDeadline!.weeks) {
    startOfDeadlineDay = startOfDeadlineDay.subtract(
      relevantDeadline!.weeks,
      'weeks'
    );
  }

  if (
    notice.noticeType === NoticeType.display_ad.value &&
    relevantDeadline?.displayOffset
  ) {
    startOfDeadlineDay = startOfDeadlineDay.subtract(
      relevantDeadline?.displayOffset,
      'hours'
    );
  }

  const deadlineHoursFromStartOfDay = parseInt(
    relevantDeadline!.deadline.time.split(':')[0],
    10
  );

  const deadlineMinutes = parseInt(
    relevantDeadline!.deadline.time.split(':')[1],
    10
  );

  const deadlineTimeForPaper = startOfDeadlineDay
    .add(deadlineHoursFromStartOfDay, 'hours')
    .add(deadlineMinutes, 'minutes');

  return deadlineTimeForPaper.clone();
};

export const getIsAfterPublishingDeadline = (
  publicationDate: Date,
  deadlines: Deadline[],
  newspaperTimezone: string,
  notice: Partial<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  if (!deadlines || !publicationDate) return true;

  const currentTimeForPaper = moment(Date.now()).tz(newspaperTimezone);
  const deadlineTimeForPaper = getDeadlineTimeForPaper(
    publicationDate,
    deadlines,
    newspaperTimezone,
    notice,
    newspaper
  );

  return currentTimeForPaper.isAfter(deadlineTimeForPaper);
};

export const isNoticeAfterPublicationDeadline = async (
  notice: ESnapshotExists<ENotice>
) => {
  const newspaperSnap = await notice.data().newspaper.get();
  const firstPublication = notice.data().publicationDates[0];
  const { deadlines, iana_timezone } = newspaperSnap.data()!;
  return getIsAfterPublishingDeadline(
    firstPublication.toDate(),
    deadlines,
    iana_timezone,
    notice,
    newspaperSnap as ESnapshotExists<EOrganization>
  );
};

export const canCancelNoticeWithoutSupport = (
  noticeSnap: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>,
  invoice: ESnapshotExists<EInvoice> | null,
  isPublisher: boolean
) => {
  try {
    return (
      isPublisher ||
      (!getIsAfterPublishingDeadline(
        noticeSnap.data().publicationDates[0].toDate(),
        newspaper.data().deadlines,
        newspaper.data().iana_timezone,
        noticeSnap.data(),
        newspaper
      ) &&
        (invoice
          ? invoice.data().status === InvoiceStatus.payment_failed.value ||
            invoice.data().status === InvoiceStatus.unpaid.value
          : true))
    );
  } catch (err) {
    return false;
  }
};

export const publishingDayEnumValuesFromDeadlines = (deadlines: Deadline[]) => {
  if (!deadlines) return [];
  return deadlines.filter(day => day.publish).map(day => day.dayEnum);
};

// MAX_WEEKS_TO_TRY is changed here to 20 instead of 3 because in some circumstances, the original start date
// of a notice + 3 weeks is before the current date. Thus, when a user tries to add run dates on notices that
// started more than 3 weeks ago, the edit flow stops working. The fix here is to change MAX_WEEKS_TO_TRY to
// a much larger number (in this case, 20 weeks)
const MAX_WEEKS_TO_TRY = 20;
export const getClosestFuturePublishingDay = (
  deadlines: Deadline[],
  newspaperTimezone: string,
  notice: Partial<ENotice>,
  newspaper: ESnapshotExists<EOrganization>,
  initialStartDate = new Date()
) => {
  const publishingDayEnumValues = publishingDayEnumValuesFromDeadlines(
    deadlines
  ).sort();

  let startDate = initialStartDate;

  const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
  if (noticeType?.publicationOffsetDays)
    startDate = moment(startDate)
      .add(noticeType.publicationOffsetDays, 'days')
      .toDate();

  // returns the first available date in the week on which the notice can be
  // published. If there is no available day, returns null
  const findFirstAvailableDateInWeek = (firstAvailableDate: Date) => {
    for (const deadlineDay of publishingDayEnumValues) {
      // e.g. next tuesday
      const desiredWeekdayValue = deadlineDay - 1;

      const nextWeekDateMomentObj = moment(firstAvailableDate).day(
        desiredWeekdayValue
      );

      const deadlineOverride = getDeadlineOverrideKeyFromDate(
        nextWeekDateMomentObj.toDate()
      );
      const publishingDayOverride =
        newspaper.data().deadlineOverrides &&
        newspaper.data().deadlineOverrides![deadlineOverride] &&
        !newspaper.data().deadlineOverrides![deadlineOverride]?.publish;
      if (publishingDayOverride) continue;

      // ignore deadlines that are before the start date
      if (nextWeekDateMomentObj.isBefore(moment(startDate))) continue;

      const deadlinePassed = getIsAfterPublishingDeadline(
        nextWeekDateMomentObj.toDate(),
        deadlines,
        newspaperTimezone,
        notice,
        newspaper
      );

      if (!deadlinePassed) return nextWeekDateMomentObj;
    }
    return null;
  };

  let nextPublishingDay;
  for (let i = 0; i < MAX_WEEKS_TO_TRY; i++) {
    nextPublishingDay = findFirstAvailableDateInWeek(
      moment(startDate)
        .add(i, 'weeks')
        .toDate()
    );
    if (nextPublishingDay) break;
  }
  if (!nextPublishingDay)
    throw new Error(
      `Unable to find next publishing day for paper: ${newspaper.data().name}`
    );

  const nextDate = (nextPublishingDay as moment.Moment).toDate();
  nextDate.setHours(12, 0, 0, 0);
  return nextDate;
};

export const dateObjectToDay = (date: Date) => {
  const m = moment(date);
  return m.format('MMMM Do');
};

export const getDeadlineString = (
  publicationDate: Date,
  deadlines: Deadline[],
  newspaperTimezone: string,
  notice: Partial<ENotice>,
  newspaper: ESnapshotExists<EOrganization>
) => {
  const t = moment(Date.now());
  const newspaperTime = t.tz(newspaperTimezone).format('z');

  const deadlineTimeForPaper = getDeadlineTimeForPaper(
    publicationDate,
    deadlines,
    newspaperTimezone,
    notice,
    newspaper
  );

  return `${moment(deadlineTimeForPaper).format(
    'dddd, MMMM Do, h:mm a'
  )} ${newspaperTime}`;
};

export const dateObjectToDayEnum = (date: Date) => moment(date).day() + 1;
