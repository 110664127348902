import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';

import AuthActions from 'redux/auth';
import { ESnapshot, EOrganization, EUser } from 'lib/types';
import { OccupationType, OrganizationType } from 'lib/enums';
import { AddIcon, ArrowDownIcon } from 'icons';

const mapStateToProps = (state: any) => ({
  availableOrganizations: state.auth.availableOrganizations,
  activeOrganization: state.auth.activeOrganization,
  user: state.auth.user,
  showAllOrgsNotices: state.auth.showAllOrgsNotices,
  pathname: state.router.location.pathname
});

const mapDispatchToProps = (dispatch: any) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  push: (path: any) => dispatch(push(path))
});

const ALL_ORGANIZATIONS = 'all-organizations';

type ActiveOrganizationSelectProps = {
  authActions: any;
  activeOrganization?: ESnapshot<EOrganization>;
  availableOrganizations: ESnapshot<EOrganization>[];
  user: ESnapshot<EUser>;
  push: Push;
  showAllOrgsNotices: boolean;
  open: boolean;
  setOpen: Function;
  pathname: string;
};

export const formatPaperName = (name: string) => {
  if (name.startsWith('The ')) {
    return name.replace('The ', '');
  }
  return name;
};

const ActiveOrganizationSelect: React.FC<ActiveOrganizationSelectProps> = ({
  authActions,
  activeOrganization,
  availableOrganizations,
  push,
  user,
  showAllOrgsNotices,
  open,
  setOpen,
  pathname
}) => {
  const [selectedOrg, setSelectedOrg] = useState('');

  useEffect(() => {
    if (activeOrganization) {
      setSelectedOrg(activeOrganization.id);
    }
  }, [activeOrganization]);

  const shouldShowAllOrgs =
    user &&
    user.data() &&
    availableOrganizations &&
    availableOrganizations.length > 1;

  const renderMenu = () => {
    if (open)
      return (
        <div
          className={`w-max z-20 border origin-top-right absolute right-1 mt-2 max-w-xs md:max-w-sm lg:max-w-xl rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 overflow-scroll`}
          style={{ maxHeight: '92.5vh' }}
        >
          {availableOrganizations
            .sort((o1, o2) => (o1.data().name < o2.data().name ? -1 : 1))
            .map(o => (
              <div
                key={o.id}
                className={`w-full block px-4 py-2 text-sm font-normal text-gray-750 ${
                  selectedOrg === o.id ? 'bg-gray-100' : 'hover:bg-gray-100'
                } cursor-pointer`}
                onClick={() => {
                  setSelectedOrg(o.id);
                  authActions.setActiveOrganization(o);
                  authActions.showAllOrgsNotices(false);
                  const organizationType = o?.data()?.organizationType;
                  // TODO: REMOVE THIS AND FIGURE OUT WHY WE ARENT SETTING ACTIVE
                  // CORRECTLY
                  setTimeout(() => authActions.setActiveOrganization(o), 2000);
                  if (
                    organizationType !==
                      OrganizationType.holding_company.value &&
                    pathname !== '/notices/'
                  )
                    push('/notices/');
                  else if (
                    organizationType ===
                      OrganizationType.holding_company.value &&
                    pathname !== '/reports/'
                  ) {
                    push('/reports/');
                  }
                  setOpen(false);
                }}
              >
                {formatPaperName(o.data().name)}
              </div>
            ))}
          {shouldShowAllOrgs && (
            <div
              className={`w-full block px-4 py-2 text-sm font-normal text-gray-750 ${
                selectedOrg === ALL_ORGANIZATIONS
                  ? 'bg-gray-100'
                  : 'hover:bg-gray-100'
              } cursor-pointer`}
              onClick={() => {
                setSelectedOrg(ALL_ORGANIZATIONS);
                authActions.showAllOrgsNotices(true);
                if (window.location.pathname !== '/notices/') push('/notices/');
                setOpen(false);
              }}
            >
              All Organizations
            </div>
          )}
          {user.data().occupation === OccupationType.publishing.value && (
            <div
              data-testid="add-publication-button"
              className={`w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer flex items-center`}
              onClick={() => push('/register/organization?type=sub-org')}
            >
              <AddIcon className="w-4 h-4 mr-1" />
              Add Publication
            </div>
          )}
        </div>
      );
  };

  return availableOrganizations.length ? (
    <div className="ml-2" data-testid="active-org">
      {user.data().occupation !== OccupationType.publishing.value &&
      availableOrganizations.length === 1 ? (
        <div className="bg-white p-1 rounded-full text-gray-700 focus:outline-none">
          {formatPaperName(availableOrganizations[0]?.data()?.name || '')}
        </div>
      ) : (
        <div className="ml-2 relative">
          <div>
            <button
              className="max-w-xs md:max-w-sm lg:max-w-xl inline-block relative bg-white p-1 rounded-full text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center"
              onClick={() => setOpen(!open)}
              id="activeOrg"
              data-testid="active-org-select"
            >
              <span className="sr-only">View organizations</span>
              {showAllOrgsNotices ? (
                'All Organizations'
              ) : (
                <span className="truncate">
                  {formatPaperName(activeOrganization?.data()?.name || '')}
                </span>
              )}
              <ArrowDownIcon className="ml-2 mt-1" />
            </button>
          </div>
          {renderMenu()}
        </div>
      )}
    </div>
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveOrganizationSelect);
