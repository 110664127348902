import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EUser, ESnapshot, Survey } from 'lib/types';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { EOrganization } from 'lib/types/organization';
import moment from 'moment';
import State from 'lib/enums/State';
import OrganizationType from 'lib/enums/OrganizationType';
import EToast from 'components/EToast';

interface FloridaTestOrg extends EOrganization {
  showModalPrematurely?: boolean;
}

type ModalProps = {
  fromMainView?: boolean;
  setFloridaDataSurveyModal?: Function;
  user?: ESnapshot<EUser>;
  activeOrganization?: ESnapshot<FloridaTestOrg>;
};

const SurveyModal: React.FC<ModalProps> = ({
  fromMainView,
  setFloridaDataSurveyModal,
  user,
  activeOrganization
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [disableAuditRequestModal, setDisableAuditRequestModal] = useState(
    true
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const inputTextStyle =
    'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';

  const [radioButtonSelected, setRadioButtonSelected] = useState(-1);
  const [displayAuditRequested, setDisplayAuditRequested] = useState(false);
  const [auditLink, setAuditLink] = useState('');
  const [successToast, setSuccessToast] = useState<string>();
  const [openSurveyModal, setOpenSurveyModal] = useState(false);

  const shouldShowPublisherSurvey = async () => {
    if (!activeOrganization) return false;
    if (
      !activeOrganization.data()?.showModalPrematurely &&
      moment().year() <= 2021
    )
      return false;
    const surveys = await Firebase.firestore()
      .collection(Collections.survey)
      .where('newspaper', '==', activeOrganization.ref)
      .where(
        'response.timeSubmitted',
        '>=',
        moment()
          .startOf('quarter')
          .toDate()
      )
      .get();

    if (
      activeOrganization.data().state !== State.florida.value ||
      activeOrganization.data().organizationType !==
        OrganizationType.newspaper.value ||
      surveys.docs.length > 0
    )
      return false;

    return true;
  };

  const goBack = async () => {
    setDisplayAuditRequested(false);
  };

  useEffect(() => {
    setDisableAuditRequestModal(auditLink === '');
  }, [auditLink]);

  useEffect(() => {
    const setModal = async () => {
      const showSurvey = await shouldShowPublisherSurvey();
      setOpenSurveyModal(showSurvey);
    };
    setModal();
  }, [activeOrganization, successToast]);

  const populateResponse = async () => {
    const now = new Date();
    const response = {
      user: user.ref,
      auditLink,
      timeSubmitted: now,
      holdsPeriodicalPermit: radioButtonSelected === 2,
      holdsPeriodicalPermitAndInFiscallyConstrainedCounty:
        radioButtonSelected === 3
    };

    await Firebase.firestore()
      .collection(Collections.survey)
      .add({
        newspaper: activeOrganization.ref,
        quarter: moment()
          .startOf('quarter')
          .toDate(),
        response
      });

    setSuccessToast("Confirmed! You're all set.");
  };

  if (!openSurveyModal && !successToast) {
    return null;
  }

  return (
    <div>
      {openSurveyModal && displayAuditRequested && (
        <FreeformCModal
          header={'Audit requested'}
          setOpen={fromMainView ? undefined : () => setFloridaDataSurveyModal()}
          customStyle={'px-2'}
          noExitOutsideModal
          width={'30%'}
          maxWidth={'sm:max-w-2xl'}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              populateResponse();
            }}
          >
            <div className="overflow-y-auto py-2 mt-2 px-2">
              <div className="text-gray-700 text-sm font-medium -mt-3">
                Please provide a link or QR code of your audit results.
              </div>
              <fieldset className="space-y-5 text-gray-700 text-sm font-medium">
                <legend className="sr-only">Notifications</legend>
                <div className="flex items-center h-5">
                  <input
                    id="auditLink"
                    aria-describedby="audit-link"
                    name="comments"
                    type="text"
                    placeholder="Link to audit results"
                    className={inputTextStyle}
                    onChange={e => {
                      setAuditLink(e.target.value);
                    }}
                  />
                </div>
              </fieldset>
              <div className="inline-flex">
                {error && (
                  <div className="text-red-500 text-sm my-3">{error}</div>
                )}
                <button
                  className={`mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${
                    disableAuditRequestModal
                      ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                      : 'bg-blue-500'
                  }`}
                  type="submit"
                  disabled={disableAuditRequestModal || loading}
                >
                  <span className="flex">
                    {loading && (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                    )}
                    Confirm and Submit
                  </span>
                </button>
                <button
                  className={`mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2`}
                  type="button"
                  disabled={loading}
                  onClick={goBack}
                >
                  <span className="flex">
                    {loading && (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                    )}
                    Go Back
                  </span>
                </button>
              </div>
            </div>
          </form>
        </FreeformCModal>
      )}
      {openSurveyModal && !displayAuditRequested && (
        <FreeformCModal
          header={'Quarterly check-in'}
          setOpen={fromMainView ? undefined : () => setFloridaDataSurveyModal()}
          customStyle={'px-2'}
          noExitOutsideModal
          width={'30%'}
          maxWidth={'sm:max-w-2xl'}
        >
          <form
            onSubmit={e => {
              e.preventDefault();
              populateResponse();
            }}
          >
            <div className="overflow-y-auto py-2 mt-2 px-2">
              <div className="text-gray-700 text-sm font-medium -mt-3">
                Before uploading your notices, please complete your quarterly
                check-in.
              </div>
              <fieldset className="space-y-5 text-gray-700 text-sm font-medium">
                <legend className="sr-only">Florida Data Questionnaire</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="questionOne"
                      aria-describedby="questionOne-description"
                      name="questionOne"
                      type="radio"
                      className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                      checked={radioButtonSelected === 1}
                      onChange={e => {
                        setRadioButtonSelected(1);
                        setIsDisabled(false);
                      }}
                    />
                  </div>
                  <div className="ml-3">
                    <span
                      id="questionOne-description"
                      onClick={e => {
                        setRadioButtonSelected(1);
                        setIsDisabled(false);
                      }}
                    >
                      Our newspaper has a certified audit that the newspaper has
                      a combined number of print copies and monthly online
                      visitors exceeding 10% of the county households for
                      notices affecting a county or the city households where
                      city notices are concerned. See{' '}
                      <a
                        href="http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html"
                        className="text-blue-500"
                      >
                        50.011(c)1
                      </a>
                      .
                    </span>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="questionTwo"
                      aria-describedby="questionTwo-description"
                      name="questionTwo"
                      type="radio"
                      className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                      checked={radioButtonSelected === 2}
                      onChange={e => {
                        setRadioButtonSelected(2);
                        setIsDisabled(false);
                      }}
                    />
                  </div>
                  <div className="ml-3">
                    <span
                      id="questionTwo-description"
                      onClick={e => {
                        setRadioButtonSelected(2);
                        setIsDisabled(false);
                      }}
                    >
                      Our newspaper held a periodicals permit as of March 1,
                      2021 and currently holds a permit [this criteria will only
                      be valid until January 1, 2024]. See{' '}
                      <a
                        href="http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html"
                        className="text-blue-500"
                      >
                        50.011(c)2
                      </a>
                      .
                    </span>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="questionThree"
                      aria-describedby="questionThree-description"
                      name="questionThree"
                      type="radio"
                      className="form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
                      checked={radioButtonSelected === 3}
                      onChange={e => {
                        setRadioButtonSelected(3);
                        setIsDisabled(false);
                      }}
                    />
                  </div>
                  <div className="ml-3">
                    <span
                      id="questionThree-description"
                      onClick={e => {
                        setRadioButtonSelected(3);
                        setIsDisabled(false);
                      }}
                    >
                      Our newspaper publishes public notices in a fiscally
                      constrained county and currently holds a periodicals
                      permit, in which case the audience thresholds do not
                      apply. See{' '}
                      <a
                        href="http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html"
                        className="text-blue-500"
                      >
                        50.011(c)3
                      </a>
                      .
                    </span>
                  </div>
                </div>
              </fieldset>
              <div>
                {error && (
                  <div className="text-red-500 text-sm my-3">{error}</div>
                )}
                <button
                  className={`mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${
                    isDisabled
                      ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                      : 'bg-blue-500'
                  }`}
                  type="submit"
                  disabled={isDisabled || loading}
                >
                  <span className="flex">
                    {loading && (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                    )}
                    Confirm and Submit
                  </span>
                </button>
              </div>
            </div>
          </form>
        </FreeformCModal>
      )}
      {successToast && (
        <EToast
          message={successToast}
          close={() => setSuccessToast('')}
          type="success"
        />
      )}
    </div>
  );
};

export default SurveyModal;
