import {
  ESnapshotExists,
  EOrganization,
  ERef,
  FirebaseQuerySnapshot
} from 'lib/types';
import { NoticeType } from 'lib/enums';
import { Collections } from 'lib/constants';
import { ERate } from 'lib/types/rates';
import { getOrCreateCustomer } from 'lib/notice/customer';
import Firebase from '../../EnoticeFirebase';

import { EPlacement } from '../../redux/placement';

const chooseRate = (
  noticeType: number,
  userRates: FirebaseQuerySnapshot,
  orgRates: FirebaseQuerySnapshot | null
) => {
  // make sure that rates associated with the notice type show up first
  const highestPriorityNoticeTypeOnRate =
    noticeType !== NoticeType.custom.value ? noticeType : null;
  const sortedOrgRates = orgRates
    ? orgRates.docs.sort(a =>
        a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1
      )
    : null;
  const sortedUserRates = userRates.docs.sort(a =>
    a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1
  );

  const orgCandidate = sortedOrgRates ? sortedOrgRates[0] : null;
  const userCandidate = sortedUserRates.length ? sortedUserRates[0] : null;

  if (orgCandidate && !userCandidate) return orgCandidate.ref;
  return userCandidate && userCandidate.ref;
};

export const checkForDefault = async (
  placement: EPlacement,
  newspaper: ESnapshotExists<EOrganization>,
  newNoticeType?: number
) => {
  const noticeType = newNoticeType || placement.noticeType;
  let defaultOrgRates;

  if (!placement.filer) return;
  if (!newspaper) return;

  const user = await placement.filer.get();

  // await new Promise(resolve => setTimeout(resolve, 500));

  const { customer } = placement; // await getOrCreateCustomer(user, newspaper);
  if (!customer) return;
  if (customer.linerRate && noticeType !== NoticeType.display_ad.value)
    return customer.linerRate;
  if (customer.displayRate && noticeType === NoticeType.display_ad.value)
    return customer.displayRate;

  let customBaseQuery = Firebase.firestore()
    .collection(Collections.rates)
    .where('organization', '==', newspaper.ref);

  // ensure that we are only showing display options for display ads
  // TODO: remove this as it is likely redundant with the sorting above
  if (noticeType === NoticeType.display_ad.value) {
    customBaseQuery = customBaseQuery.where(
      'noticeType',
      '==',
      NoticeType.display_ad.value
    );
  }

  if (user.data().activeOrganization) {
    defaultOrgRates = await customBaseQuery
      .where('organizations', 'array-contains', user.data().activeOrganization)
      .get();
  }

  const defaultUserRates = await customBaseQuery
    .where('filers', 'array-contains', user.ref)
    .get();
  const d = chooseRate(noticeType, defaultUserRates, defaultOrgRates);
  if (d) return (d as any) as ERef<ERate>;

  if (noticeType === NoticeType.display_ad.value)
    return newspaper.data().defaultDisplayRate as ERef<ERate>;
  return newspaper.data().defaultLinerRate as ERef<ERate>;
};
