import { EUser, EOrganization, FirebaseUser } from 'lib/types';
import { includeSearchParams } from 'utils/urls';
import { OccupationType } from 'lib/enums';

export const getNotAuthdRedirect = (
  userAuth: FirebaseUser,
  pathname: string
) => {
  const alreadyRedirected = ['/login/'].includes(pathname);
  if (alreadyRedirected) return false;

  if (!userAuth) return '/login/';
};

const publishingEnum = OccupationType.publishing.value;

export const organizationIsSetup = (user: EUser, organization: EOrganization) =>
  (user.organization &&
    (user.occupation !== publishingEnum ||
      (user.occupation === publishingEnum &&
        organization &&
        organization.accountId))) ||
  user.occupation === OccupationType.individual.value;

export const getPostAuthRedirect = (
  user: EUser,
  organization: EOrganization,
  pathname: string
) => {
  if (!user) return false;

  const alreadyRedirected = [
    '/invites/confirm-organization',
    '/register/organization',
    '/register/organization/post-registration',
    '/new-feature-onboard/advertising-deadlines',
    '/register/confirm'
  ].find(path => pathname.includes(path));

  if (alreadyRedirected) return false;

  /*
    User has been created through the invitation flow, but has not yet been formally linked to an organization
  */
  if (user.invite) {
    return includeSearchParams('/invites/confirm-organization/');
  }
};
