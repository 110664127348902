import moment from 'moment';
import {
  htmlToIndesignHtml,
  shouldPreserveLongSequencesForNewspaper
} from '../indesign/helpers';
import { dateTimeLikeToDate } from '../date';
import { EHandlebars } from './shared';
import {
  DateParseError,
  UnknownDateFormat
} from '../errors/NoticePreviewErrors';

import { getNoticeType } from '../helpers';
import pricingHelpers, { DBPricingObj } from '../pricing';
import { ENotice } from '../types';

const generateV1Footer = (
  footerFormatString: string,
  notice: Partial<ENotice>
) => {
  if (!footerFormatString) return '';

  const formatFooterString: (match: string) => string = (match: string) => {
    // {{***}} -> ***
    const footerTag = match.slice(2, -1);

    const formatType = footerTag.split(' ')[0];
    if (formatType === 'SQUASH') {
      let monthFormat: string;
      let dayFormat: string;
      let yearFormat: string;
      try {
        // ...(***)... -> ***
        monthFormat = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('month', footerTag);
      }

      try {
        // ...[***]... -> ***
        dayFormat = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('day', footerTag);
      }

      try {
        // ...|***|... -> ***
        yearFormat = footerTag.match(/\|.*?\|/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('year', footerTag);
      }

      const firstDate = notice.publicationDates!.map(dateTimeLikeToDate)[0]!;
      let currentYear = firstDate.getFullYear();
      let currentMonth = firstDate.getMonth();

      let formattedString = `${moment(firstDate).format(monthFormat)}`;

      for (const timestamp of notice.publicationDates!) {
        const date = dateTimeLikeToDate(timestamp)!;

        if (date.getFullYear() !== currentYear) {
          formattedString += `${moment(firstDate).format(yearFormat)}, `;
        }

        if (date.getMonth() !== currentMonth) {
          formattedString += moment(date).format(monthFormat);
        }

        formattedString += moment(date).format(dayFormat);

        currentYear = date.getFullYear();
        currentMonth = date.getMonth();
      }
      const lastDate = dateTimeLikeToDate(
        notice.publicationDates![notice.publicationDates!.length - 1]
      )!;
      formattedString += moment(lastDate).format(yearFormat);

      return formattedString;
    }

    if (formatType === 'DATE') {
      let format: string;
      let separator: string;
      try {
        // ...(***)... -> ***
        format = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('date', footerTag);
      }
      try {
        // ...[***]... -> ***
        separator = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
      } catch (err) {
        throw new DateParseError('separator', footerTag);
      }
      return (notice.publicationDates || [])
        .map(t => `${moment(dateTimeLikeToDate(t)!).format(format)}`)
        .join(separator);
    }
    throw new UnknownDateFormat(match);
  };

  return footerFormatString.replace(/{{.*?}}/g, formatFooterString);
};

export const addFooterXML = (footer: string) => {
  return `<?xml version="1.0" encoding="UTF-8"?><dynamic-footer xmlns:aid="http://ns.adobe.com/AdobeInDesign/4.0/" xmlns:aid5="http://ns.adobe.com/AdobeInDesign/5.0/">${footer}</dynamic-footer>`;
};

export const createCustomFooter = (
  dynamicFooter: string,
  customId: string | null | undefined,
  dbPricing: DBPricingObj,
  oldCustomId?: string
) => {
  let customFooter = dynamicFooter || '';

  // reset footer
  if (oldCustomId) {
    customFooter = customFooter.replace(oldCustomId, '#'.repeat(6));
  }

  // replace in custom ID
  if (customId) {
    customFooter = customFooter.replace('#'.repeat(6), customId);
  }

  // replace in for total price
  if (dbPricing?.subtotal) {
    customFooter = customFooter.replace(
      '*'.repeat(6),
      `${(dbPricing.subtotal / 100).toFixed(2)}`
    );
  }

  return customFooter;
};

export const generateFormattedFooter = async (notice: Partial<ENotice>) => {
  if (!notice.newspaper) return '';
  if (!notice.publicationDates) return '';

  const newspaper = await notice.newspaper.get();
  if (!newspaper.data()) return '';

  const footerFormatString =
    notice.publicationDates.length === 1 && newspaper.data()?.oneRunFooter
      ? newspaper.data()?.oneRunFooter
      : newspaper.data()?.footerFormatString;

  if (!footerFormatString) return '';
  let footer;

  const isV1Footer = footerFormatString.slice(0, 3) === 'V1:';

  if (isV1Footer) {
    footer = generateV1Footer(
      footerFormatString.slice(3, footerFormatString.length),
      notice
    );
  } else {
    const compiled = EHandlebars.compile(footerFormatString);

    let filerName = '';
    let filerOrgName = '';

    if (notice.filer) {
      const filer = await notice.filer.get();
      filerName = filer.data()?.name || '';
    }

    if (notice.filedBy) {
      const filerOrg = await notice.filedBy.get();
      filerOrgName = filerOrg.data()?.name || '';
    }

    let price = '';
    if (notice.displayParams) {
      price = pricingHelpers
        .getUIPricingObject(
          await pricingHelpers.createDBPricingObjectFromData(
            notice,
            notice.displayParams
          )
        )
        .subtotal.toFixed(2);
    }

    const noticeType = getNoticeType({ data: () => notice }, newspaper);

    footer = compiled({
      dates: notice.publicationDates,
      price: price || '#.##',
      noticeType: noticeType || 'Public Notice',
      filerOrgName,
      filerName
    });
  }

  const fixedFooter = createCustomFooter(
    footer,
    notice.customId,
    notice.pricing
  );

  const preserveLongSequences = shouldPreserveLongSequencesForNewspaper(
    newspaper
  );

  return htmlToIndesignHtml(
    fixedFooter,
    (window as any).DOMParser,
    {
      isFirstPHeading: false,
      preserveLongSequences
    },
    {}
  );
};
