import { takeEvery, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { ENV, DEMO, PROD } from '../constants';
import { ErrorTypes } from '../redux/errors';

function* handleError({ error }: { error: string }) {
  if (ENV === DEMO || ENV === PROD) {
    Sentry.captureException(error);
  }
  yield true;
}

export default function* root() {
  return yield all([takeEvery(ErrorTypes.SET_ERROR, handleError)]);
}
