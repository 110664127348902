import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import JoyRide from 'react-joyride';
import { connect } from 'react-redux';
import JoyRideActions from 'redux/joyrides';

import JoyrideTooltip from './Tooltip';

const JoyRideContainer = ({
  joyrides,
  joyRideActions
}: {
  joyrides: any;
  joyRideActions: any;
}) => {
  const [run, setRun] = useState(false);
  const [init, setInit] = useState(false);

  let parsedSteps: any[] = [];
  try {
    parsedSteps = JSON.parse(
      joyrides?.activeJoyRide?.data().steps
    ).map((s: any) => ({ ...s, disableBeacon: true, spotlightPadding: 20 }));
  } catch (err) {}

  useEffect(() => {
    window.addEventListener(
      'replacestate',
      () => joyRideActions.setNewJoyRidePossible(),
      false
    );
  }, []);

  const observeElements = (selectors: string[]) => {
    const observer = new MutationObserver(async function(mutations, me) {
      const node = selectors.find(sel => {
        return document.querySelector(sel);
      });

      try {
        if (node) {
          setInit(true);
          setRun(true);
          me.disconnect(); // stop observing
        }
      } catch (err) {
        console.log(err);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true
    });
  };

  useEffect(() => {
    if (parsedSteps.length && !init) {
      observeElements((parsedSteps || []).map((step: any) => step.target));
    }
  }, [parsedSteps]);

  return (
    <JoyRide
      continuous
      styles={{
        options: {
          textColor: '#004a14',
          width: 900,
          zIndex: 2147483647
        }
      }}
      disableScrolling
      showProgress
      showSkipButton
      run={joyrides.activeJoyRide && run}
      tooltipComponent={JoyrideTooltip}
      steps={parsedSteps}
      callback={(e: any) => {
        try {
          joyrides?.callback(e);
        } catch (err) {}

        if (e?.step?.waitFor && !document.querySelector(e.step.waitFor)) {
          setRun(false);
          observeElements([e.step.waitFor]);
        }

        if (e.reset === 'reset') {
          setInit(false);
        }

        if (e.action === 'reset' || e.action === 'close') {
          joyRideActions.markJoyRideRead();
        }
      }}
      beaconComponent={() => <> </>}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  joyRideActions: bindActionCreators(JoyRideActions, dispatch)
});

const mapStateToProps = (state: any) => ({
  joyrides: state.joyrides
});

export default connect(mapStateToProps, mapDispatchToProps)(JoyRideContainer);
