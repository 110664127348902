import { ReactComponent as cancel } from './cancel.svg';
import { ReactComponent as deleteIcon } from './delete.svg';
import { ReactComponent as document } from './document.svg';
import { ReactComponent as dollar } from './dollar.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as invoice } from './invoice.svg';
import { ReactComponent as profile } from './profile.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as globe } from './globe.svg';
import { ReactComponent as billingStatus } from './billingStatus.svg';
import { ReactComponent as link } from './link.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as arrow } from './arrowIcon.svg';
import { ReactComponent as x } from './x.svg';
import { ReactComponent as externalLink } from './externalLink.svg';
// import { ReactComponent as questionMarkRounded } from './questionMarkRounded.svg';
import { ReactComponent as closeIcon } from './closeIcon.svg';
import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as flag } from './flag.svg';
import { ReactComponent as printer } from './printer.svg';
import { ReactComponent as cog } from './cog.svg';
import { ReactComponent as questionMarkRounded } from './questionMarkRounded.svg';
import { ReactComponent as bell } from './bell.svg';
import { ReactComponent as accountCircle } from './accountCircle.svg';
// import { ReactComponent as add } from './add.svg';
import { ReactComponent as arrowDown } from './arrowDown.svg';
import { ReactComponent as questionMarkRoundedFilled } from './questionMarkRoundedFilled.svg';
// import { ReactComponent as closeIcon } from './closeIcon.svg';
import { ReactComponent as invalid } from './invalid.svg';
import { ReactComponent as checkIcon } from './checkIcon.svg';
import { ReactComponent as clockIcon } from './clockIcon.svg';
// import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as arrowLeft } from './arrowLeft.svg';
import { ReactComponent as searchIcon } from './searchIcon.svg';
import { ReactComponent as addIcon } from './addIcon.svg';
import { ReactComponent as checkRounded } from './checkRounded.svg';
import { ReactComponent as checkOutline } from './checkOutline.svg';
import { ReactComponent as invalidRounded } from './invalidRounded.svg';
import { ReactComponent as arrowRightWithTail } from './arrowRightWithTail.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as updateAccount } from './updateAccount.svg';
import { ReactComponent as eye } from './eye.svg';
import { ReactComponent as eyeOff } from './eyeOff.svg';
import { ReactComponent as refresh } from './refresh.svg';
import { ReactComponent as mail } from './mail.svg';

export const RefreshIcon = refresh;
export const CancelIcon = cancel;
export const DeleteIcon = deleteIcon;
export const DocumentIcon = document;
export const DollarIcon = dollar;
export const EditIcon = edit;
export const LockIcon = lock;
export const DownloadIcon = download;
export const PencilIcon = pencil;
export const InvoiceIcon = invoice;
export const ProfileIcon = profile;
export const CalendarIcon = calendar;
export const GlobeIcon = globe;
export const BillingStatusIcon = billingStatus;
export const LinkIcon = link;
export const CopyIcon = copy;
export const UploadIcon = upload;
export const ArrowIcon = arrow;
export const XIcon = x;
export const ExternalLinkIcon = externalLink;
export const QuestionMarkRoundedIcon = questionMarkRounded;
export const CloseIcon = closeIcon;
export const ArrowRightIcon = arrowRight;
export const FlagIcon = flag;
export const PrinterIcon = printer;
export const CogIcon = cog;
// export const QuestionMarkRoundedIcon = questionMarkRounded;
export const BellIcon = bell;
export const AccountCircleIcon = accountCircle;
// export const AddIcon = add;
export const ArrowDownIcon = arrowDown;
export const QuestionMarkRoundedFilledIcon = questionMarkRoundedFilled;
// export const CloseIcon = closeIcon;
export const InvalidIcon = invalid;
export const CheckIcon = checkIcon;
export const ClockIcon = clockIcon;
export const ArrowRight = arrowRight;
export const ArrowLeft = arrowLeft;
export const SearchIcon = searchIcon;
export const AddIcon = addIcon;
export const CheckRoundedIcon = checkRounded;
export const CheckOutlinedIcon = checkOutline;
export const InvalidRoundedIcon = invalidRounded;
export const ArrowRightWithTailIcon = arrowRightWithTail;
export const Trash = trash;
export const UpdateAccount = updateAccount;
export const Eye = eye;
export const EyeOff = eyeOff;
export const Mail = mail;
