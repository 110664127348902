export default {
  invoice_not_submitted: {
    value: 1,
    label: 'Awaiting Invoice Creation',
    key: 'invoice_not_submitted'
  },
  invoice_submitted_to_advertiser: {
    value: 2,
    label: 'Awaiting Invoice Payment',
    key: 'invoice_submitted_to_advertiser'
  },
  invoice_paid_by_advertiser: {
    value: 3,
    label: 'Invoice Paid',
    key: 'invoice_paid_by_advertiser'
  },
  transfer_created: {
    value: 4,
    label: 'Payment Received',
    key: 'published'
  },
  payment_pending: {
    value: 5,
    label: 'Payment Pending',
    key: 'payment_pending'
  },
  payment_failed: {
    value: 6,
    label: 'Payment Failed',
    key: 'payment_failed'
  },
  payment_refunded: {
    value: 7,
    label: 'Payment Refunded',
    key: 'payment_refunded'
  },
  paid_outside_column: {
    value: 8,
    label: 'Paid outside of Column',
    key: 'paid_outside_column'
  }
};
