export default {
  bankruptcy_debt: {
    value: 1,
    label: 'Bankruptcy & Debt',
    key: 'bankruptcy_debt'
  },
  business_law: {
    value: 2,
    label: 'Business Law',
    key: 'business_law'
  },
  divorce_family: {
    value: 3,
    label: 'Divorce & Family Law',
    key: 'divorce_family'
  },
  estate_probate: {
    value: 4,
    label: 'Estate Planning & Probate',
    key: 'estate_probate'
  },
  real_estate: {
    value: 5,
    label: 'Real Estate',
    key: 'real_estate'
  },
  lawsuits_disputes: {
    value: 6,
    label: 'Lawsuits & Disputes',
    key: 'lawsuits_disputes'
  }
};
